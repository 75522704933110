import { IItem } from "../adapters/output/IMenu";

export function GetPriceSeparator(item: IItem, itemLayout: any) {
    if (!itemLayout?.displayPrice) {
        return <></>;
    }
    switch (item.priceType) {
        case "slash":
            return <span> / </span>;
        case "modifier":
            return <span> | </span>;
        default:
            return <br></br>;
    }
}
