import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
    "&.modal": {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 550,
        background: "white",
        borderRadius: 20,
        boxShadow: "0px 10px 30px rgba(31, 19, 0, 0.3)"
    },
    "& .iconBox": {
        width: 60,
        height: 60,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.colors.orange[100]
    },
    "& .icon": {
        width: 28,
        height: 28,
        "& path": {
            fill: theme.colors.orange[700]
        }
    },
    "& .closeButtonContainer": {
        marginTop: 20,
        marginRight: 20,
        height: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    "& .closeButton": {
        cursor: "pointer",
        "& path": {
            fill: theme.colors.grey[900]
        },
        "&:hover path": {
            fill: theme.colors.orange[700]
        },
        display: "flex",
        alignItems: "center"
    },
    "& .sectionClose": {
        width: "100%",
        display: "flex",
        flexDirection: "row-reverse"
    },
    "& .message": {
        width: "100%",
        marginTop: 24,
        marginBottom: 8,
        padding: "0 32px",
        fontSize: 20,
        fontWeight: 500,
        lineHeight: "28px",
        textAlign: "center"
    },
    "& .infoText": {
        padding: "0 50px",
        fontSize: 14,
        textAlign: "center",
        fontStyle: "italic",
        marginBottom: 15,
        lineHeight: 1.4
    },
    "& .note": {
        marginBottom: 16,
        width: 486,
        fontSize: 11,
        lineHeight: "13px",
        textAlign: "center",
        fontStyle: "italic"
    },
    "& .hr": {
        width: 486
    },
    "& .actions": {
        marginTop: 20,
        marginBottom: 32,
        "& button + button": {
            marginLeft: 22
        }
    },
    "& .dialogForm": {
        width: 486,
        paddingTop: 16,
        paddingBottom: 8,
        display: "flex",
        flexDirection: "column"
    },
    "& .textField": {
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& input": {
            paddingLeft: 20
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 10px"
            }
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3
        },
        "& label[data-shrink=false]": {
            paddingLeft: 10
        }
    },
    "& .conactInfo": {
        margin: "24px 0",
        "& p": {
            textAlign: "center",
            fontSize: 14,
            lineHeight: "20px",
            fontStyle: "italic",
            fontWeight: 700,
            color: "#000000",
            "& a": {
                textDecoration: "underline",
                fontWeight: "400 !important",
                color: "#000000"
            },
            "& + p": {
                marginTop: 5
            }
        }
    },
    "& .fieldDescription": {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "16px",
        textAlign: "center",
        fontStyle: "italic"
    }
}));

export { Root };
