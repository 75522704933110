import React from "react";
import { Root, classes } from "./styles";
import { CircularProgress } from "@mui/material";
import Icon from "../UI/Icon";

const Button = ({
    itemLoading = false,
    disableBtn = false,
    hasLoading = false,
    onClick = () => {},
    innerText = "",
    icon = "default",
    type = "secondary",
    color = "dark",
    className = "",
    htmlType = "button",
    ...props
}) => {
    const pickIcon = (innerText) => {
        if (icon !== "default") {
            return <Icon name={icon} />;
        }
        switch (innerText) {
            case "Save":
                return <Icon name="save" />;
            case "Exit":
                return <Icon name="exit" />;
            default:
                return <></>;
        }
    };

    const hasIcon = () => {
        return pickIcon(innerText).type !== React.Fragment;
    };
    return (
        <Root
            propsColor={color}
            className={`${className} ${classes.button} ${classes[type]} `}
            disabled={disableBtn || itemLoading}
            onClick={onClick}
            type={htmlType}
            {...props}
        >
            {hasIcon() && (
                <>
                    {itemLoading ? (
                        <CircularProgress
                            color="inherit"
                            size={12}
                            className={classes.circularProgress}
                        />
                    ) : (
                        <span className={classes.icon}>
                            {pickIcon(innerText)}
                        </span>
                    )}
                </>
            )}
            <span>
                {hasLoading ? (
                    <CircularProgress
                        color="inherit"
                        size={18}
                        className={classes.circularProgress}
                    />
                ) : (
                    innerText
                )}
            </span>
        </Root>
    );
};

export default Button;
