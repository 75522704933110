/**
 * This method allows the app to send HTTP request to the API.
 */
const loadImage = async (url: any, callback: any, errorCallback: any) => {
    try {
        const response = await fetch(
            `${
                process.env.REACT_APP_API_URL
            }/menu/fetch-image?url=${encodeURIComponent(url)}`
        );
        if (!response.ok) {
            // if HTTP-status is 200-299
            // throws an Error message
            throw new Error(
                `Could not fetch ${url}, status: ${response.status}`
            );
        }

        const extension = url.split("?")[0].split(".").pop().toLowerCase();
        let image;
        if (extension.toLowerCase() === "svg") {
            const svgText = await response.text();
            image = `data:image/svg+xml,${encodeURIComponent(svgText)}`;
        } else {
            const blob = await response.blob();
            image = URL.createObjectURL(blob);
        }
        callback(image);
    } catch (error) {
        console.log("Error:: ", error);
        errorCallback(error); // This will pass any errors to the errorCallback function.
    }
};

export { loadImage };
