import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
    padding: "20px 0",
    "& .dragItemsTitle": {
        display: "flex",
        alignItems: "center",
        padding: "0 20px",
        fontWeight: 500,
        fontSize: "16px",
        marginBottom: "25px",
        "& svg": {
            marginLeft: "12px",
            height: 14,
            width: 14
        }
    },
    "& .dragItemsWrap": {
        "& .dragItems": {
            display: "flex",
            flexWrap: "wrap",
            "& .dragItemsBox": {
                width: "50%",
                padding: "0 20px",
                marginBottom: "20px",
                "& .dragItemsBoxInner": {
                    width: "100%",
                    height: 90,
                    background: "#EAEAEA",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    border: "1px solid #d3d3d3",
                    cursor: "grab",
                    opacity: "0.4",
                    transition: "all 0.3s",
                    position: "relative",
                    "&:hover": {
                        borderColor: "#B4B3B3",
                        opacity: "0.95"
                    },
                    "&::active": {
                        cursor: "pointer"
                    },
                    "&.active": {
                        borderColor: "#1890FF",
                        borderStyle: "dashed",
                        boxShadow: "2px 2px 8px 0px #0000000D",
                        opacity: "0.95",
                        cursor: "grabbing",
                        "& .dragging-icon": {
                            opacity: 1
                        }
                    },
                    "& .dragging-icon": {
                        position: "absolute",
                        top: -5,
                        right: -5,
                        backgroundColor: "#E1F1F9",
                        borderRadius: "50%",
                        overflow: "hidden",
                        opacity: 0,
                        transition: "all 0.3s"
                    }
                },
                "& .dragItemsBoxTitle": {
                    fontWeight: 400,
                    fontSize: "12px",
                    textAlign: "center",
                    color: "#3A3A3A",
                    marginTop: "10px",
                    lineHeight: 1.6
                }
            }
        }
    }
}));
export { Root };
