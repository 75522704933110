import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
    "&.alertContainer": {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: 400,
        minHeight: 69,
        padding: "16px 32px 16px 16px",
        borderRadius: 20,
        boxShadow: "0px 10px 30px rgba(31, 19, 0, 0.3)",
        background: "white"
    },
    "& .success": {
        "& path": {
            fill: "#70C040"
        }
    },
    "& .error": {
        width: 20,
        height: 18,
        "& path": {
            fill: "#D8000C"
        }
    },
    "& .info": {
        width: 20,
        "& path": {
            fill: "#3C8CB5"
        }
    },
    "& .content": {
        marginLeft: 8,
        display: "flex",
        flexGrow: 1,
        flexDirection: "column"
    },
    "& .title": {
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "21px",
        color: "black",
        margin: 0,
        textTransform: "capitalize"
    },
    "& .message": {
        fontWeight: 300,
        marginBottom: 0,
        "& b": {
            fontWeight: 500
        },
        fontSize: 13,
        fontStyle: "italic"
    },
    "& .closeButtonContainer": {
        marginTop: -2,
        // width: '100%',
        marginRight: 10,
        // height: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    "& .closeButton": {
        cursor: "pointer",
        width: 10,
        height: 10,
        "& path": {
            fill: theme.colors.grey[900]
        },
        "&:hover path": {
            fill: theme.colors.orange[700]
        },
        display: "flex",
        transform: "translate(25px,-3px)",
        flexDirection: "row-reverse",
        alignItems: "center"
    }
}));

export { Root };
