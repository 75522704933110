import { Slide, Snackbar } from "@mui/material";
import { useCallback, useEffect } from "react";

import { Root } from "./styles";
import Icon from "../Icon";
import { useAlertContext } from "../../../context/alert";

const Alert = ({ type = "info", message }) => {
    const iconName =
        type === "success" ? "check" : type === "error" ? "error" : "info";
    const { alertState, setAlertState } = useAlertContext();

    useEffect(() => {
        let tiempo;

        if (alertState === true) {
            tiempo = setTimeout(() => {
                setAlertState(false);
            }, 3000);
        }

        return () => clearTimeout(tiempo);
    }, [alertState, setAlertState]);

    function onClose() {
        setAlertState(false);
    }

    return (
        <Snackbar
            style={{ zIndex: 2147483005 }}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={alertState}
            TransitionComponent={useCallback(
                (props) => (
                    <Slide {...props} direction="right" />
                ),
                []
            )}
        >
            <Root className="alertContainer">
                <Icon className={type} name={iconName} />
                <div className="content">
                    <p className="title">{type}</p>
                    <p
                        className="message"
                        dangerouslySetInnerHTML={{
                            __html: message
                        }}
                    />
                </div>
                <div className="closeButtonContainer">
                    <Icon
                        onClick={onClose}
                        name="clear"
                        className="closeButton"
                    />
                </div>
            </Root>
        </Snackbar>
    );
};

export default Alert;
