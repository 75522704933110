import { Slider } from "@mui/material";
import { Box, styled } from "@mui/system";

const PrettoSlider = styled(Slider)({
    color: "#E86100B2",
    height: 4,
    "& .MuiSlider-rail": {
        backgroundColor: "#f5f5f5",
        opacity: 1
    },
    "& .MuiSlider-track": {
        border: "none"
    },
    "& .MuiSlider-thumb": {
        height: 14,
        width: 14,
        backgroundColor: "#fff",
        border: "2px solid #E86100B2",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit"
        },
        "&:before, &:after": {
            display: "none"
        }
    },
    "& .MuiSlider-valueLabel": {
        lineHeight: 1.2,
        fontSize: 12,
        background: "unset",
        padding: 0,
        width: 24,
        height: 24,
        borderRadius: "50% 50% 50% 0",
        backgroundColor: "#E86100B2",
        transformOrigin: "bottom left",
        transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
        display: "none",
        "&:before, &:after": { display: "none" },
        "&.MuiSlider-valueLabelOpen": {
            transform: "translate(50%, -100%) rotate(-45deg) scale(1)"
        },
        "& > *": {
            transform: "rotate(45deg)"
        }
    }
});

const CollapseRoot = styled("div")(({ theme }) => ({
    "&.container": {
        width: "100%",
        borderBottom: `1px solid ${theme.colors.grey[200]}`,
        padding: "20px 0"
    },
    "& .collapseWrapper": {
        display: "flex",
        alignItems: "center",
        padding: "0px 16px"
    },
    "& .collapseContentWrapper": {
        padding: "0 22px",
        margin: "16px 0 0"
    },
    "& .layoutName": {
        fontSize: "16px",
        lineHeight: "1.2",
        fontWeight: 500,
        color: "#000000",
        marginLeft: "20px"
    },
    "& .expand_section": {
        lineHeight: 0,
        width: 26,
        height: 26,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        transition: "all 0.3s",
        "&:hover": {
            background: `${theme.colors.grey[100]}20`
        },
        "& > svg": {
            transition: "all 0.3s"
        },
        "&.active": {
            background: `${theme.colors.orange[50]}`,
            "& path": {
                fill: theme.colors.orange[700]
            },
            "&:hover": {
                background: `${theme.colors.orange[50]}`
            },
            "& > svg": {
                transform: "rotate(90deg)"
            }
        }
    },
    "& .paperMarginBox": {
        padding: "0 10px",
        "& .paperMarginLabel": {
            fontSize: 14,
            fontWeight: 500,
            color: "#4b4b4b",
            marginBottom: "10px"
        }
    },
    "& .layoutItems": {
        display: "flex",

        "& .layoutItemsBox": {
            width: "33.33%",
            padding: "0 12px",
            marginBottom: "20px",
            "& .layoutItemsBoxInner": {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "& > svg": {
                    cursor: "pointer",
                    "& rect": {
                        transition: "all 0.3s"
                    },
                    "&:hover": {
                        "& rect": {
                            "&:last-child": {
                                stroke: "#5a5a5a"
                            }
                        }
                    },
                    "&.active": {
                        boxShadow: "0px 2px 8px 0px #0000001F",
                        "& rect": {
                            stroke: "#CC5803",
                            fill: "#fff",
                            "&:last-child": {
                                fill: "none",
                                stroke: "#CC5803"
                            }
                        }
                    }
                }
            }
        }
    }
}));

const ImageCardStyles = styled(Box)(({ theme }) => ({
    "&.imageCardWrap": {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -5px",
        "&.hasFourCol": {
            margin: "0 -10px",
            "& .imageCardBox": {
                width: "25%"
            }
        },
        "&.gridContainerCard": {
            "& .imageCardBox": {
                padding: "0 15px",
                marginBottom: "0",
                "& .imageCardItem": {
                    "& > svg": {
                        cursor: "pointer",
                        "& rect": {
                            transition: "all 0.3s"
                        },
                        "&:hover": {
                            "& rect": {
                                fill: "#939393",
                                "&:first-of-type": {
                                    fill: "#5a5a5a"
                                },
                                "&:last-child": {
                                    fill: "none"
                                }
                            }
                        }
                    }
                }
            }
        },
        "&.hasThreeCol": {
            margin: "0 -10px",
            "& .imageCardBox": {
                width: "33.33%",
                padding: "0 3px",
                marginBottom: "20px"
            }
        },
        "&.hasTwoCol": {
            margin: "0 -10px",
            "& .imageCardBox": {
                width: "50%",
                padding: "0 3px",
                marginBottom: "20px"
            }
        },
        "& .imageCardBox": {
            width: "34%",
            padding: "0 10px",
            "& .imageCardItem": {
                width: "100%",
                textAlign: "center",
                minHeight: 50,
                "& > svg": {
                    cursor: "pointer",
                    "& rect": {
                        transition: "all 0.3s"
                    },
                    "&:hover": {
                        "& rect": {
                            "&:last-child": {
                                stroke: "#5a5a5a"
                            }
                        }
                    },
                    "&.active": {
                        boxShadow: "0px 2px 8px 0px #0000001F",
                        "& rect": {
                            stroke: "#CC5803",
                            fill: "#fff",
                            "&:last-child": {
                                fill: "none",
                                stroke: "#CC5803"
                            }
                        },
                        "& path": {
                            fill: "#CC5803"
                        }
                    }
                }
            }
        },
        "& .imageCardItemTitle": {
            fontWeight: 500,
            fontSize: "12px",
            textAlign: "center",
            color: "#3A3A3A",
            marginTop: "8px",
            lineHeight: 1.6
        }
    }
}));

export { PrettoSlider, CollapseRoot, ImageCardStyles };
