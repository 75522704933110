import { Root } from "./styles";
import Navbar from "../../Navbar";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import Sidebar from "../../Sidebar";
import PageEditor from "../PageEditor";
import Icon from "../../UI/Icon";
import { useEffect, useRef, useState } from "react";
import { usePrinterContext } from "../../../context/printer";
import PreviewNavbar from "../../PreviewNavbar";
import ConfirmAction from "../../Modals";
import { DragDropContext } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../context/alert";
import { updateSection } from "../../../utils/sectionHelper";
import LoadingScreen from "../LoadingScreen";
import CustomPopup from "../../Modals/CustomPopup";
import { ImageCard } from "../../UI/CommonComponents";
import { ImageCardStyles } from "../../UI/CommonStyles";
import {
    DEFAULT_PAGE_PROPS,
    RESET_ROW_COL,
    RESET_ROW_COL_STYLES,
    SIDEBAR_TAB_INDEX
} from "../../../const/general";
import {
    customEvents,
    customPageEvents,
    customRowEvents,
    customItemEvents
} from "../../../utils/customEvents";
import {
    calcNewSpace,
    inchesToPixels,
    isNormalNumber,
    remainingSpace
} from "../../../utils/common";
import { generateId } from "../../../utils/uuid";
import { landLayout } from "../../../const/layout";
import { cloneDeep } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import CustomTooltip from "../../UI/CustomTooltip/CustomTooltip";

export default function MainContainer() {
    const {
        pageBlocks,
        setPageBlocks,
        showPreview,
        printPageElement,
        zoomLevel,
        setZoomLevel,
        overflowContent,
        printLoading,
        layoutState,
        setTabIndex,
        setOverflowContent,
        setSectionList,
        handleDrawer,
        pageBlocksArray,
        pageBlocksIndex,
        setUndoRedoState
    } = usePrinterContext();
    const defaultPageProps = cloneDeep(DEFAULT_PAGE_PROPS);

    const { STYLES } = SIDEBAR_TAB_INDEX;
    const { pageLayout } = layoutState;

    const { setAlertState, setAlert } = useAlertContext();

    const { restaurant: restaurantId } = useParams();

    const [focusId, setFocusId] = useState("");

    const pages = useRef();
    const pagesWrappper = useRef();
    const [firstLoad, setFirstLoad] = useState(true);
    const [pIndex, setPIndex] = useState();
    const [handleRowRemove, setHandleRowRemove] = useState({
        value: {},
        state: false
    });

    const afterZoomReposition = () => {
        const pageWidth =
            printPageElement?.current?.children[0]?.offsetWidth *
            (zoomLevel / 10);
        const mxWidth = handleDrawer ? 1194 : 790;
        if (pageWidth >= mxWidth) {
            pages.current.style.paddingRight = !showPreview ? "180px" : "50px";
        } else {
            pages.current.style.paddingRight = "50px";
        }
    };

    useEffect(() => {
        afterZoomReposition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoomLevel, handleDrawer, showPreview]);

    useEffect(() => {
        setTimeout(() => {
            afterZoomReposition();
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageLayout.activeOrientation]);

    const handleZoomIn = () => {
        setZoomLevel((prevZoomLevel) => Math.min(15, prevZoomLevel + 1));
    };

    const handleZoomOut = () => {
        setZoomLevel((prevZoomLevel) => Math.max(2, prevZoomLevel - 1));
    };

    const [gridColumnPopup, setGridColumnPopup] = useState(false);

    const handleContainerColumn = (layout) => {
        if (isNormalNumber(layout)) {
            setPageBlocks((prevPageBlocks) => {
                return prevPageBlocks.map((page, index) => {
                    if (index !== pIndex) return page; // Return unchanged if not the target page

                    return {
                        ...page,
                        page_properties: {
                            ...page?.page_properties,
                            layout: {
                                ...page?.page_properties?.layout,
                                gridMode: {
                                    ...page?.page_properties?.layout?.gridMode,
                                    containerCount:
                                        page?.page_properties?.layout?.gridMode
                                            ?.containerCount + 1,
                                    column: layout
                                }
                            }
                        }
                    };
                });
            });
        }
        setGridColumnPopup(false);
    };

    // Remove Page
    const addPage = () => {
        const newPageRowReset = cloneDeep(RESET_ROW_COL);
        newPageRowReset[0].column[0].id = generateId();
        setPageBlocks((prevPages) => {
            const updatedPages = [
                ...prevPages,
                {
                    page_no: prevPages.length + 1,
                    page_name: "Page Name",
                    row: newPageRowReset,
                    page_properties: defaultPageProps
                }
            ];
            return updatedPages;
        });
    };

    // Remove Page
    const removePage = (pageIndex) => {
        if (pageBlocks.length > 1)
            setPageBlocks((prevPages) =>
                prevPages
                    .filter((_, index) => index !== pageIndex)
                    .map((page, index) => ({
                        ...page,
                        page_no: index + 1
                    }))
            );

        const pageIndexOfFocusId = parseInt(focusId.split(",")[0]);
        const columnIndexOfFocusId = parseInt(focusId.split(",")[1]);
        const blockIndexOfFocusId = parseInt(focusId.split(",")[2]);

        if (pageIndexOfFocusId === pageIndex) {
            handleBlockClick("");
            customEvents("");
            customItemEvents("");
            customRowEvents("");
            customPageEvents("");
        } else if (pageIndex < pageIndexOfFocusId) {
            handleBlockClick(
                [
                    pageIndexOfFocusId - 1,
                    columnIndexOfFocusId,
                    blockIndexOfFocusId
                ].toString()
            );
        }
    };

    // Remove Container
    const removeRowConfirm = ({ rowIndex, pageInd }) => {
        const updateBlocks = [...pageBlocks];

        const rRowHeight =
            updateBlocks[pageInd]?.row[rowIndex]?.styles?.rowHeight;

        updateBlocks[pageInd]?.row[rowIndex]?.column.forEach((column) => {
            // Check if the column.blocks array contains an object with type 'section'
            const sectionBlock = column?.blocks?.find(
                (block) => block?.type === "section"
            );

            // If a 'section' block is found, extract its items
            if (sectionBlock) {
                const removedItems = sectionBlock?.data?.items;
                setSectionList((prevSectionList) => [
                    ...prevSectionList,
                    removedItems
                ]);
            }
        });

        updateBlocks[pageInd]?.row?.splice(rowIndex, 1);

        // Change remaining row height
        const remainingHeightCalc = updateBlocks[pageInd]?.row?.map(
            (row, index) => {
                const updatedRowHeight = remainingSpace(
                    rRowHeight,
                    row?.styles?.rowHeight
                );

                return {
                    ...row,
                    styles: {
                        ...row.styles,
                        rowHeight: updatedRowHeight
                    }
                };
            }
        );

        if (updateBlocks[pageInd]?.row.length === 0) {
            const gridLastRowReset = cloneDeep(RESET_ROW_COL);
            gridLastRowReset[0].column[0].id = generateId();
            updateBlocks[pageInd].row = gridLastRowReset;
            updateBlocks[pageInd].page_properties.layout.gridMode = {
                ...updateBlocks[pageInd].page_properties.layout.gridMode,
                containerCount: 0,
                column: ""
            };
        } else {
            updateBlocks[pageInd].row = remainingHeightCalc;
        }

        setPageBlocks(updateBlocks);
        setHandleRowRemove({
            value: {},
            state: false
        });

        // Clear Styles Tab
        setTimeout(() => {
            handleBlockClick("");
            customEvents("");
            customRowEvents("");
            customItemEvents("");
        }, 0);
    };

    const movePage = (fromIndex, toIndex) => {
        // Scroll to the updated page
        scrollToPage(`page-${toIndex + 1}`);
    };

    const scrollToPage = (pageId) => {
        const element = document.getElementById(pageId);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
        }
    };

    const handleBlockClick = (id) => {
        setFocusId(id);
    };

    const singlePage = useRef({});
    const pageEdit = useRef({});

    const handleBlur = (event) => {
        const refs = singlePage.current;
        const pageEditRef = pageEdit.current;

        const isOutside = Object.values(refs).every((ref) => {
            return ref ? !ref.contains(event.target) : true;
        });

        const isEditPage = Object.values(pageEditRef).some((ref) => {
            return ref && ref.contains(event.target);
        });

        if (isOutside) {
            customEvents("");
            customRowEvents("");
            customItemEvents("");
            if (!isEditPage) {
                customPageEvents("");
                handleBlockClick("");
            }
        }
    };

    const [handleOverflowDrop, setHandleOverflowDrop] = useState(false);

    const [itemOrderChange, setItemOrderChange] = useState(false);

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);

        result.splice(endIndex, 0, removed);

        return result;
    };

    const switchBlocks = (sourceIndex, destIndex, type, draggableId) => {
        if (type === "rowItems") {
            const { sourcePageIndex, sourceBlockIndex } = sourceIndex;
            const { destPageIndex, destBlockIndex } = destIndex;
            setPageBlocks((pages) => {
                const updatedPageBlocks = [...pages];
                let srcPage;
                //let destPage;

                // function updateBlocks(samePage = false) {
                //     srcPage = updatedPageBlocks[sourcePageIndex];
                //     destPage =
                //         updatedPageBlocks[
                //             samePage ? sourcePageIndex : destPageIndex
                //         ];

                //     const destColBlocks = destPage.row;
                //     const sourceColBlocks = srcPage.row;

                //     let newSourceBlocks = [...sourceColBlocks];
                //     const [draggedItem] = newSourceBlocks.splice(
                //         sourceBlockIndex,
                //         1
                //     );

                //     let newDestBlocks = [...destColBlocks];

                //     newDestBlocks.splice(destBlockIndex, 0, draggedItem);

                //     destPage.row = newDestBlocks;
                //     srcPage.row = newSourceBlocks;
                // }

                if (sourcePageIndex === destPageIndex) {
                    srcPage = updatedPageBlocks[sourcePageIndex];

                    const reorderItems = reorder(
                        srcPage.row,
                        sourceBlockIndex,
                        destBlockIndex
                    );

                    srcPage.row = reorderItems;
                } else if (sourcePageIndex !== destPageIndex) {
                    // updateBlocks(false);
                    return updatedPageBlocks;
                }

                setFocusId([sourcePageIndex, destBlockIndex].toString());

                customRowEvents(
                    sourcePageIndex,
                    destBlockIndex,
                    "column-container"
                );

                return updatedPageBlocks;
            });

            return;
        }

        const {
            sourcePageIndex,
            sourceRowIndex,
            sourceColIndex,
            sourceBlockIndex
        } = sourceIndex;
        const { destPageIndex, destRowIndex, destColIndex, destBlockIndex } =
            destIndex;

        setPageBlocks((pages) => {
            const updatedPageBlocks = [...pages];
            let srcPage, destPage, blockType;

            function updateBlocks(samePage = false) {
                srcPage = updatedPageBlocks[sourcePageIndex];
                destPage =
                    updatedPageBlocks[
                        samePage ? sourcePageIndex : destPageIndex
                    ];
                blockType =
                    srcPage?.row[sourceRowIndex]?.column[sourceColIndex]
                        ?.blocks[sourceBlockIndex]?.blockType;

                const destColBlocks =
                    destPage.row[destRowIndex].column[destColIndex].blocks;
                const sourceColBlocks =
                    srcPage.row[sourceRowIndex].column[sourceColIndex].blocks;

                let newSourceBlocks = [...sourceColBlocks];
                const [draggedItem] = newSourceBlocks.splice(
                    sourceBlockIndex,
                    1
                );

                let newDestBlocks = [...destColBlocks];

                newDestBlocks.splice(destBlockIndex, 0, draggedItem);

                destPage.row[destRowIndex].column[destColIndex].blocks =
                    newDestBlocks;
                srcPage.row[sourceRowIndex].column[sourceColIndex].blocks =
                    newSourceBlocks;
            }

            if (
                sourceColIndex === destColIndex &&
                sourceRowIndex === destRowIndex &&
                sourcePageIndex === destPageIndex
            ) {
                srcPage = updatedPageBlocks[sourcePageIndex];
                blockType =
                    srcPage.row[sourceRowIndex].column[sourceColIndex]?.blocks[
                        sourceBlockIndex
                    ]?.blockType;

                if (type === "blockItems") {
                    const activeInnerBlockIndex = srcPage.row[
                        sourceRowIndex
                    ].column[sourceColIndex]?.blocks.findIndex(
                        (block) =>
                            block?.type === "section" &&
                            block?.data?.items?.items.findIndex(
                                (item) => item?.id === draggableId
                            ) !== -1
                    );

                    const reorderItems = reorder(
                        srcPage.row[sourceRowIndex].column[sourceColIndex]
                            .blocks[activeInnerBlockIndex].data.items.items,
                        sourceBlockIndex,
                        destBlockIndex
                    );

                    srcPage.row[sourceRowIndex].column[sourceColIndex].blocks[
                        activeInnerBlockIndex
                    ].data.items.items = reorderItems;

                    const sectionID =
                        srcPage.row[sourceRowIndex].column[sourceColIndex]
                            .blocks[activeInnerBlockIndex].data.items._id;

                    const sortData = {
                        item_links: srcPage.row[sourceRowIndex].column[
                            sourceColIndex
                        ].blocks[activeInnerBlockIndex].data.items.items.map(
                            (item, index) => ({
                                item_id: item._id,
                                sort_order: index + 1
                            })
                        )
                    };

                    setFocusId(
                        [
                            destPageIndex,
                            destRowIndex,
                            destColIndex,
                            activeInnerBlockIndex,
                            destBlockIndex
                        ].toString()
                    );

                    updateSection(sectionID, sortData, restaurantId)
                        .then(({ success, response }) => {
                            customItemEvents(
                                destPageIndex,
                                destRowIndex,
                                destColIndex,
                                activeInnerBlockIndex,
                                "item-styles",
                                destBlockIndex
                            );
                        })
                        .catch((error) => {
                            console.log("Error:", error);
                            setAlert({
                                type: "error",
                                message:
                                    "Couldn't sort the section items, please try again."
                            });
                            setAlertState(true);
                        });
                } else if (type === "columnBlocks") {
                    const reorderItems = reorder(
                        srcPage.row[sourceRowIndex].column[sourceColIndex]
                            .blocks,
                        sourceBlockIndex,
                        destBlockIndex
                    );

                    srcPage.row[sourceRowIndex].column[sourceColIndex].blocks =
                        reorderItems;
                }
            } else if (sourcePageIndex === destPageIndex) {
                if (type === "blockItems") {
                    return updatedPageBlocks;
                }

                updateBlocks(true);
            } else if (sourcePageIndex !== destPageIndex) {
                if (type === "blockItems") {
                    return updatedPageBlocks;
                }

                updateBlocks(false);
            }

            if (type === "columnBlocks") {
                setFocusId(
                    [
                        destPageIndex,
                        destRowIndex,
                        destColIndex,
                        destBlockIndex
                    ].toString()
                );

                if (blockType) {
                    customEvents(
                        destPageIndex,
                        destRowIndex,
                        destColIndex,
                        destBlockIndex,
                        blockType
                    );
                }
            }

            return updatedPageBlocks;
        });
    };

    const [itemOrderChangeData, setItemOrderChangeData] = useState({});

    const onDragEnd = async (result) => {
        const { destination, source, type, draggableId } = result;
        const dest = destination?.droppableId;
        const src = source?.droppableId;

        // Create an object to store index name and values
        let destData = {};
        let srcData = {};

        // Combine the regex matching and parsing into a single function
        const parseIndexData = (str) => {
            const data = {};
            str?.match(/\b(\w+)-(\d+)/g)?.forEach((match) => {
                const [indexName, indexValue] = match?.split("-");
                data[indexName] = parseInt(indexValue, 10); // Parse directly to integer
            });
            return data;
        };

        // Parse the data for both 'dest' and 'src'
        destData = parseIndexData(dest);
        srcData = parseIndexData(src);

        if (!destination || !dest || !src || !source || !draggableId) return;

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // Access the values directly from the parsed objects
        const sourceBlockIndex = source.index;
        const sourcePageIndex = srcData.pageIndex;
        const sourceRowIndex = srcData.rowIndex;
        const sourceColIndex = srcData.columnIndex;
        const destPageIndex = destData.pageIndex;
        const destRowIndex = destData.rowIndex;
        const destColIndex = destData.columnIndex;
        const destBlockIndex = destination.index;

        const sourceIndex = {
            sourcePageIndex,
            ...(type !== "rowItems" && {
                sourceRowIndex,
                sourceColIndex
            }),
            sourceBlockIndex
        };
        const destIndex = {
            destPageIndex,
            ...(type !== "rowItems" && {
                destRowIndex,
                destColIndex
            }),
            destBlockIndex
        };

        if (
            type === "blockItems" &&
            sourceColIndex === destColIndex &&
            sourcePageIndex === destPageIndex
        ) {
            setItemOrderChange(true);
            setItemOrderChangeData({
                sourceIndex,
                destIndex,
                type,
                draggableId
            });
            return;
        }

        if (sourceIndex && destIndex && type && draggableId) {
            switchBlocks(sourceIndex, destIndex, type, draggableId);
        }
    };

    // To update the Row/Column in the Page
    const pageProp = pageBlocks[pIndex]?.page_properties?.layout;
    const gridData = pageProp?.gridMode;
    const gridMode = gridData?.active;
    const gridColumn = parseFloat(gridData?.column);
    const totalColumn = parseFloat(pageProp?.columnCount);
    const gridContainerCount = gridData?.containerCount;
    const firstRow = gridContainerCount === 1;
    const isEmptyRow = gridContainerCount === 0;
    const [firstRowAdd, setFirstRowAdd] = useState(false);

    // Update pageBlocks on column change
    useEffect(() => {
        if (!firstLoad) {
            if (gridMode) {
                if (isEmptyRow) {
                    setPageBlocks((prevPageBlocks) => {
                        const gridFirstRowReset = cloneDeep(RESET_ROW_COL);
                        gridFirstRowReset[0].column[0].id = generateId();
                        const updatedPageBlocks = [...prevPageBlocks];
                        const updatedPage = { ...updatedPageBlocks[pIndex] };
                        updatedPage.row = gridFirstRowReset;
                        updatedPageBlocks[pIndex] = updatedPage;
                        return updatedPageBlocks;
                    });
                    return;
                }

                let totalRow;
                if (firstRowAdd) {
                    setPageBlocks((prevPageBlocks) => {
                        const colWidth = 100 / gridColumn;
                        const newRow = {
                            styles: {
                                ...cloneDeep(RESET_ROW_COL_STYLES),
                                columnCount: gridColumn,
                                rowHeight: firstRow ? 100 : 10
                            },
                            column: Array.from({ length: gridColumn }, () => ({
                                ...{
                                    styles: { columnWidth: colWidth },
                                    blocks: [],
                                    id: generateId()
                                }
                            }))
                        };

                        const updatedPageBlocks = [...prevPageBlocks];
                        const updatedPage = { ...updatedPageBlocks[pIndex] };

                        updatedPage.row = firstRow
                            ? [newRow]
                            : [...updatedPage?.row, newRow];

                        totalRow = updatedPage?.row?.length;
                        // Change already exist row height
                        const updatedRowBlocks = updatedPage?.row?.map(
                            (row, index) => {
                                if (index < totalRow - 1) {
                                    const updatedRowHeight = calcNewSpace(
                                        row?.styles?.rowHeight
                                    );

                                    return {
                                        ...row,
                                        styles: {
                                            ...row.styles,
                                            rowHeight: updatedRowHeight
                                        }
                                    };
                                } else {
                                    // For the last row, no modification needed
                                    return row;
                                }
                            }
                        );

                        updatedPage.row = updatedRowBlocks;

                        updatedPageBlocks[pIndex] = updatedPage;
                        return updatedPageBlocks;
                    });

                    // Switch To Styles Tab
                    setTabIndex(STYLES);

                    setTimeout(() => {
                        // Change The Focus On Newly Added Row Container
                        setFocusId([pIndex, totalRow - 1].toString());

                        // Set Row Container Indexes For Display Correct Styles
                        customRowEvents(
                            pIndex,
                            totalRow - 1,
                            "column-container"
                        );
                    }, 500);
                }
            } else {
                customRowEvents("");

                setPageBlocks((prevPageBlocks) => {
                    const updatedPageBlocks = prevPageBlocks.map(
                        (page, index) => {
                            if (index !== pIndex) return page; // Return unchanged if not the target page

                            return {
                                ...page,
                                row: Array.from(
                                    { length: 1 },
                                    (_, rowIndex) => {
                                        const colBlock =
                                            page.row[rowIndex]?.column;
                                        return {
                                            ...page.row[rowIndex],
                                            column: Array.from(
                                                {
                                                    length:
                                                        totalColumn ||
                                                        colBlock?.length
                                                },
                                                (_, columnIndex) => {
                                                    const ID = generateId();
                                                    return columnIndex <
                                                        colBlock?.length
                                                        ? {
                                                              ...colBlock[
                                                                  columnIndex
                                                              ], // Preserve existing data
                                                              id: colBlock[
                                                                  columnIndex
                                                              ].id
                                                                  ? colBlock[
                                                                        columnIndex
                                                                    ].id
                                                                  : ID // Add ID as a sibling
                                                          }
                                                        : {
                                                              styles: {
                                                                  columnWidth: 100
                                                              },
                                                              blocks: [],
                                                              id: ID // Add ID as a sibling for new data
                                                          };
                                                }
                                            )
                                        };
                                    }
                                )
                            };
                        }
                    );
                    return updatedPageBlocks;
                });
            }
        }
        setFirstLoad(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalColumn, gridContainerCount]);

    // Check for overflow content
    const [countImageLoaded, setCountImageLoaded] = useState(0);
    const [overflowColumn, setOverflowColumn] = useState([]);

    useEffect(() => {
        const newOverflowContent = pageBlocks.map((page) => {
            return page.row.map((row) => {
                return row.column.map(() => false);
            });
        });

        setTimeout(() => {
            if (!gridMode) {
                pageBlocks.forEach((page, pageIndex) => {
                    let pageHeight = 9999;
                    page?.row?.forEach((row, rowIndex) => {
                        row?.column?.forEach((column, columnIndex) => {
                            let totalHeight = 0,
                                overflowActive = false,
                                parentCol = document.getElementById(column?.id);
                            pageHeight = parentCol?.offsetHeight;
                            if (column.blocks.length === 0) {
                                if (parentCol) {
                                    parentCol.style.backgroundColor = "";
                                    if (
                                        parentCol.classList.contains(
                                            "hasOverflow"
                                        )
                                    ) {
                                        parentCol.classList.remove(
                                            "hasOverflow"
                                        );
                                    }
                                    overflowActive = false;
                                }
                            }
                            column?.blocks?.forEach((block, blockIndex) => {
                                const blockElem = document.getElementById(
                                    block.id
                                );
                                if (blockElem) {
                                    blockElem.style.maxHeight = "";
                                }
                                totalHeight += blockElem?.offsetHeight;
                                if (pageHeight <= totalHeight) {
                                    const tempVal =
                                        pageHeight -
                                        (totalHeight - blockElem?.offsetHeight);
                                    if (blockElem) {
                                        blockElem.style.display =
                                            tempVal <= 0 ? "none" : "";
                                        blockElem.style.maxHeight =
                                            tempVal + "px";
                                    }
                                    if (parentCol) {
                                        parentCol.style.backgroundColor =
                                            "rgba(239, 151, 159, 0.25)";
                                        if (
                                            !parentCol.classList.contains(
                                                "hasOverflow"
                                            )
                                        ) {
                                            parentCol.classList.add(
                                                "hasOverflow"
                                            );
                                            if (
                                                !blockElem.classList.contains(
                                                    "hasOverflowBlock"
                                                )
                                            ) {
                                                blockElem.classList.add(
                                                    "hasOverflowBlock"
                                                );
                                            }
                                        }
                                        overflowActive = true;
                                    }
                                } else {
                                    if (blockElem) {
                                        blockElem.style.display = "";
                                        blockElem.style.maxHeight = "";
                                    }
                                    if (parentCol) {
                                        parentCol.style.backgroundColor = "";
                                        if (
                                            parentCol.classList.contains(
                                                "hasOverflow"
                                            )
                                        ) {
                                            parentCol.classList.remove(
                                                "hasOverflow"
                                            );
                                            if (
                                                blockElem.classList.contains(
                                                    "hasOverflowBlock"
                                                )
                                            ) {
                                                blockElem.classList.remove(
                                                    "hasOverflowBlock"
                                                );
                                            }
                                        }
                                        overflowActive = false;
                                    }
                                }
                            });
                            newOverflowContent[pageIndex][rowIndex][
                                columnIndex
                            ] = overflowActive;
                        });
                    });
                });
            } else {
                pageBlocks.forEach((page, pageIndex) => {
                    page?.row?.forEach((row, rowIndex) => {
                        row?.column?.forEach((column, columnIndex) => {
                            let totalHeight = 0,
                                overflowActive = false,
                                parentCol = document.getElementById(column?.id);
                            const pHeight = parentCol?.offsetHeight;
                            if (column.blocks.length === 0) {
                                if (parentCol) {
                                    parentCol.style.backgroundColor = "";
                                    if (
                                        parentCol.classList.contains(
                                            "hasOverflow"
                                        )
                                    ) {
                                        parentCol.classList.remove(
                                            "hasOverflow"
                                        );
                                    }
                                    overflowActive = false;
                                }
                            }
                            column?.blocks?.forEach((block, blockIndex) => {
                                const blockElem = document.getElementById(
                                    block.id
                                );
                                if (blockElem) {
                                    blockElem.style.maxHeight = "";
                                }
                                totalHeight += blockElem?.offsetHeight;

                                if (pHeight <= totalHeight) {
                                    const tempVal =
                                        pHeight -
                                        (totalHeight - blockElem?.offsetHeight);
                                    if (blockElem) {
                                        blockElem.style.display =
                                            tempVal <= 0 ? "none" : "";
                                        blockElem.style.maxHeight =
                                            pHeight + "px";
                                    }
                                    if (parentCol) {
                                        parentCol.style.backgroundColor =
                                            "rgba(239, 151, 159, 0.25)";
                                        if (
                                            !parentCol.classList.contains(
                                                "hasOverflow"
                                            )
                                        ) {
                                            parentCol.classList.add(
                                                "hasOverflow"
                                            );
                                            if (
                                                !blockElem.classList.contains(
                                                    "hasOverflowBlock"
                                                )
                                            ) {
                                                blockElem.classList.add(
                                                    "hasOverflowBlock"
                                                );
                                            }
                                        }
                                        overflowActive = true;
                                    }
                                } else {
                                    if (blockElem) {
                                        blockElem.style.display = "";
                                        blockElem.style.maxHeight = "";
                                    }
                                    if (parentCol) {
                                        parentCol.style.backgroundColor = "";
                                        if (
                                            parentCol.classList.contains(
                                                "hasOverflow"
                                            )
                                        ) {
                                            parentCol.classList.remove(
                                                "hasOverflow"
                                            );
                                            if (
                                                blockElem.classList.contains(
                                                    "hasOverflowBlock"
                                                )
                                            ) {
                                                blockElem.classList.remove(
                                                    "hasOverflowBlock"
                                                );
                                            }
                                        }
                                        overflowActive = false;
                                    }
                                }
                            });
                            newOverflowContent[pageIndex][rowIndex][
                                columnIndex
                            ] = overflowActive;
                        });
                    });
                });
            }
            setOverflowColumn(newOverflowContent);
            // Check if any value is true
            const hasOverflow = newOverflowContent.some((page) =>
                page.some((row) => row.some((value) => value))
            );

            setOverflowContent(hasOverflow);
        }, 1500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageBlocks, layoutState, countImageLoaded, showPreview]);

    const overRef = useRef();

    // Undo Function
    const undoFunction = () => {
        if (pageBlocksIndex.current > 0) {
            pageBlocksIndex.current = pageBlocksIndex.current - 1;
            const clonedData = cloneDeep(
                pageBlocksArray[pageBlocksIndex.current]
            );
            clonedData && setPageBlocks(clonedData);
            setUndoRedoState({ state: false, value: "undo" });
        }
    };

    // Redo Function
    const redoFunction = () => {
        if (pageBlocksIndex.current < pageBlocksArray.length - 1) {
            pageBlocksIndex.current = pageBlocksIndex.current + 1;
            const clonedData = cloneDeep(
                pageBlocksArray[pageBlocksIndex.current]
            );
            clonedData && setPageBlocks(clonedData);
            setUndoRedoState({ state: false, value: "redo" });
        }
    };

    // A function to handle keyboard events:
    useHotkeys(
        "ctrl + z, meta + z, cmd + z",
        (e) => {
            e.preventDefault();
            undoFunction();
        },
        { enableOnFormTags: ["INPUT", "TEXTAREA", "SELECT"] }
    );
    useHotkeys(
        "ctrl + shift + z, meta + shift + z, cmd + shift + z,",
        (e) => {
            e.preventDefault();
            redoFunction();
        },
        { enableOnFormTags: ["INPUT", "TEXTAREA", "SELECT"] }
    );

    const topBarRef = useRef();
    const pageEditorWrapRef = useRef();
    const paperSize = pageLayout.paperSize;
    const orientation = pageLayout.activeOrientation;
    const pixelWidth = inchesToPixels(
        (orientation === "portrait"
            ? paperSize.width
            : orientation === "landscape"
            ? paperSize.height
            : ""
        ).replace("in", ""),
        96
    );
    const pixelHeight = inchesToPixels(
        (orientation === "portrait"
            ? paperSize.height
            : orientation === "landscape"
            ? paperSize.width
            : ""
        ).replace("in", ""),
        96
    );

    const pageLength = pageBlocks.length;
    const topBarHeight = !showPreview ? 28 * pageLength : 0;
    const extraHeight = 50 * (pageLength - 1);
    const zoomAdjustWidth = pixelWidth * (zoomLevel / 10);
    const marginSpace = !showPreview ? 12 : 0;
    const zoomAdjustHeight =
        ((pixelHeight + marginSpace) * pageLength +
            topBarHeight +
            extraHeight) *
        (zoomLevel / 10);

    return (
        <>
            <Root container>
                <Grid item xs={12}>
                    {!showPreview ? (
                        <Navbar handleBlur={handleBlur}></Navbar>
                    ) : (
                        <PreviewNavbar></PreviewNavbar>
                    )}
                    {overflowContent ? (
                        <Box
                            ref={overRef}
                            className={`flexBox alignCenter overflowWarning ${
                                showPreview ? "preview" : ""
                            }`}
                        >
                            <Icon name="warning" />
                            <p>
                                <strong>Column Overflow -</strong> Menu content
                                has overflown a column. Rearrange menu content
                                to fit or add new page
                            </p>
                        </Box>
                    ) : (
                        ""
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            height: "calc(100vh - 60px)"
                        }}
                    >
                        {!showPreview ? (
                            <Box
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    position: "relative"
                                }}
                            >
                                <Sidebar overRef={overRef} />
                            </Box>
                        ) : (
                            ""
                        )}
                        <Box
                            sx={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: showPreview
                                    ? "#292929"
                                    : "#FAFAFA",
                                overflow: "auto",
                                position: "relative",
                                display: "flex",
                                flex: "1 1 0%"
                            }}
                            onClick={(event) => handleBlur(event)}
                            ref={pagesWrappper}
                            id="pagesWrapper"
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                    margin: "auto"
                                }}
                            >
                                <Box
                                    ref={pages}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: overflowContent
                                            ? `70px 50px 30px 50px`
                                            : `30px 50px 30px 50px`,
                                        position: "relative"
                                    }}
                                >
                                    <Box
                                        id="pages"
                                        style={{
                                            width: zoomAdjustWidth,
                                            height: zoomAdjustHeight
                                        }}
                                    >
                                        <Box
                                            className="printPageElement"
                                            ref={printPageElement}
                                            style={{
                                                transform: `scale(${
                                                    zoomLevel / 10
                                                }) `,
                                                width: pixelWidth,
                                                transformOrigin: "0px 0px"
                                            }}
                                        >
                                            <DragDropContext
                                                onDragEnd={onDragEnd}
                                            >
                                                {pageBlocks.map(
                                                    (pages, index) => (
                                                        <PageEditor
                                                            singlePage={
                                                                singlePage
                                                            }
                                                            pageEdit={pageEdit}
                                                            pageId={`page-${
                                                                index + 1
                                                            }`}
                                                            key={pages.page_no}
                                                            onAddPage={addPage}
                                                            isFirst={
                                                                index === 0
                                                            }
                                                            isLast={
                                                                index ===
                                                                pageBlocks.length -
                                                                    1
                                                            }
                                                            totalPage={
                                                                pageBlocks.length
                                                            }
                                                            onDeletePage={() =>
                                                                removePage(
                                                                    index
                                                                )
                                                            }
                                                            onMoveUp={() =>
                                                                movePage(
                                                                    index,
                                                                    index - 1
                                                                )
                                                            }
                                                            onMoveDown={() =>
                                                                movePage(
                                                                    index,
                                                                    index + 1
                                                                )
                                                            }
                                                            pageContent={
                                                                pages.row
                                                            }
                                                            pageIndex={index}
                                                            handleBlockClick={
                                                                handleBlockClick
                                                            }
                                                            setFocusId={
                                                                setFocusId
                                                            }
                                                            focusId={focusId}
                                                            setHandleOverflowDrop={
                                                                setHandleOverflowDrop
                                                            }
                                                            setGridColumnPopup={
                                                                setGridColumnPopup
                                                            }
                                                            setFirstLoad={
                                                                setFirstLoad
                                                            }
                                                            pIndex={pIndex}
                                                            setPIndex={
                                                                setPIndex
                                                            }
                                                            setCountImageLoaded={
                                                                setCountImageLoaded
                                                            }
                                                            overflowColumn={
                                                                overflowColumn
                                                            }
                                                            removeRowConfirm={
                                                                removeRowConfirm
                                                            }
                                                            setHandleRowRemove={
                                                                setHandleRowRemove
                                                            }
                                                            setFirstRowAdd={
                                                                setFirstRowAdd
                                                            }
                                                            topBarRef={
                                                                topBarRef
                                                            }
                                                            pageEditorWrapRef={
                                                                pageEditorWrapRef
                                                            }
                                                        />
                                                    )
                                                )}
                                            </DragDropContext>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="fixedSideBlock">
                            <Box className="roundedBorderBlock">
                                <CustomTooltip title="Zoom In" placement="left">
                                    <span>
                                        <Icon
                                            onClick={handleZoomIn}
                                            name="zoomInIcon"
                                        />
                                    </span>
                                </CustomTooltip>
                                <span className="zoomPercentage">
                                    {zoomLevel * 10}%
                                </span>
                                <CustomTooltip
                                    title="Zoom Out"
                                    placement="left"
                                >
                                    <span>
                                        <Icon
                                            onClick={handleZoomOut}
                                            name="zoomOutIcon"
                                        />
                                    </span>
                                </CustomTooltip>
                            </Box>
                            {!showPreview ? (
                                <Box
                                    className="roundedBorderBlock"
                                    sx={{ mt: 2 }}
                                >
                                    <CustomTooltip
                                        title="Undo"
                                        placement="left"
                                    >
                                        <span
                                            style={{
                                                marginBottom: "10px",
                                                opacity:
                                                    pageBlocksIndex.current ===
                                                    0
                                                        ? 0.4
                                                        : 1
                                            }}
                                            className="flexBox alignCenter justifyCenter"
                                        >
                                            <Icon
                                                disabled={
                                                    pageBlocksIndex.current ===
                                                    0
                                                }
                                                onClick={undoFunction}
                                                name="undo"
                                            />
                                        </span>
                                    </CustomTooltip>
                                    <CustomTooltip
                                        title="Redo"
                                        placement="left"
                                    >
                                        <span
                                            className="flexBox alignCenter justifyCenter"
                                            style={{
                                                opacity:
                                                    pageBlocksIndex.current ===
                                                    pageBlocksArray.length - 1
                                                        ? 0.4
                                                        : 1
                                            }}
                                        >
                                            <Icon
                                                disabled={
                                                    pageBlocksIndex.current ===
                                                    pageBlocksArray.length - 1
                                                }
                                                onClick={redoFunction}
                                                name="redo"
                                            />
                                        </span>
                                    </CustomTooltip>
                                </Box>
                            ) : (
                                ""
                            )}
                        </Box>
                    </Box>
                </Grid>
                <ConfirmAction
                    open={handleOverflowDrop}
                    button1Text=""
                    button2Text="OK"
                    actionCancel={() => setHandleOverflowDrop(false)}
                    icon="warning"
                    dialogText={`Content is overflow`}
                    note="Please adjust your existing content to add more content here!"
                />
                <ConfirmAction
                    open={itemOrderChange}
                    button1Text="Apply Changes"
                    button2Text="Cancel Changes"
                    actionCancel={() => setItemOrderChange(false)}
                    actionConfirm={() => {
                        if (itemOrderChangeData) {
                            const {
                                sourceIndex,
                                destIndex,
                                type,
                                draggableId
                            } = itemOrderChangeData;
                            switchBlocks(
                                sourceIndex,
                                destIndex,
                                type,
                                draggableId
                            );
                            setItemOrderChange(false);
                        }
                    }}
                    icon="warning"
                    dialogText={`This change could affect other menus.`}
                    note="Reordering the items in the Cocktails section will affect the item order for any menu that is using this section. "
                />
                <CustomPopup
                    open={gridColumnPopup}
                    actionCancel={() => setGridColumnPopup(false)}
                    dialogText={`How many columns?`}
                    note={`Please select the number of columns this container will have.`}
                >
                    <ImageCardStyles className="imageCardWrap layoutBox hasFourCol gridContainerCard">
                        {landLayout.map((layout, index) => {
                            return index <= 3 ? (
                                <ImageCard
                                    key={index}
                                    icon={layout.icon}
                                    label={layout.label}
                                    onClick={() =>
                                        handleContainerColumn(layout.onClick)
                                    }
                                    labelPos="top"
                                />
                            ) : (
                                ""
                            );
                        })}
                    </ImageCardStyles>
                </CustomPopup>
                <ConfirmAction
                    open={handleRowRemove?.state}
                    button1Text="Confirm"
                    button2Text="Cancel"
                    actionCancel={() =>
                        setHandleRowRemove({
                            value: {},
                            state: false
                        })
                    }
                    actionConfirm={() =>
                        removeRowConfirm(handleRowRemove?.value)
                    }
                    icon="warning"
                    dialogText={`Are you sure you want to delete this container?`}
                    note="Please be aware that any data within this container will be removed from your menu layout. Any custom blocks (titles, paragraphs, and images) will be lost."
                />
            </Root>
            {printLoading ? <LoadingScreen printLoading={true} /> : ""}
        </>
    );
}
