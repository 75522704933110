import { styled } from "@mui/system";
import { Tabs } from "@mui/material";

const MyTabs = styled(Tabs)({
    borderBottom: "1px solid #dadada",
    padding: "0 16px",
    minHeight: "38px",
    "& .MuiTabs-flexContainer": {
        width: "100%",
        "& > button": {
            width: "50%"
        }
    },
    "& .labelText": {
        color: "#9e9e9e",
        textTransform: "capitalize",
        fontSize: 14,
        fontWeight: 500,
        transition: "all 300ms linear,opacity 0ms"
    },
    "& .MuiTab-root": {
        transition: "all 300ms linear,opacity 0ms",
        padding: "11px 8px 9px 8px",
        minHeight: "38px",
        borderRadius: "20px 20px 0 0",
        border: "1px solid transparent",
        borderBottomWidth: 0,
        "&.Mui-selected": {
            backgroundColor: "#fff",
            boxShadow: "0px 0px 4px 0px #00000014",
            borderColor: "#dadada",
            color: "#dadada",
            "& .labelText": {
                color: "#222222"
            }
        }
    },
    ".MuiTabs-indicator": {
        display: "none" // Hide the indicator
    }
});

export { MyTabs };
