import { Box } from "@mui/system";
import Icon from "../../../../UI/Icon";
import { Root } from "./style";
import { usePrinterContext } from "../../../../../context/printer";
import { useState } from "react";
import BlockData from "../../../../../const/BlockData";
import { Tooltip } from "../../../../UI/CommonComponents";

function MenuItems({ menuItemName, count, items, index }) {
    const [activeId, setActiveId] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const { setDraggedItem, setSectionItems } = usePrinterContext();

    const { BLOCKTYPE } = BlockData();

    // Handle Active Items
    const handleMouseDown = () => {
        setIsClicked(true);
    };
    const handleMouseUp = () => {
        setIsClicked(false);
    };

    // Handle Dragging
    function handleDragStart(e) {
        if (!activeId) {
            setDraggedItem(BLOCKTYPE.SECTION_ITEMS);
            setSectionItems(items);
            setActiveId(true);
        }
    }
    function handleDragEnd(e) {
        setDraggedItem("");
        setSectionItems({});
        setActiveId(false);
        setIsClicked(false);
    }

    return (
        <Box
            className={`menuItems ${isClicked ? "active" : ""}`}
            draggable="true"
            onDragStart={(event) => handleDragStart(event)}
            onDragEnd={(event) => handleDragEnd(event)}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            <Box className="menuItemName">{menuItemName}</Box>
            <Box className="rightBlock">
                <Box className="numberAndSpoonContainer">
                    <Icon name="spoonKnifeIcon" />
                    <span>{count}</span>
                </Box>
                <Icon className="dragging-icon" name="plusRounded" />
                <Icon className="sortDotIcon" name="sortDotIcon"></Icon>
            </Box>
        </Box>
    );
}
export default function Layout() {
    const { sectionList } = usePrinterContext();
    return (
        <Root>
            <Box className="dragItemsTitle">
                Drag and Drop Sections
                <Tooltip />
            </Box>
            <Box className="menuItemsContainer">
                {sectionList.map((item, index) => {
                    const activeItems = item?.items?.filter(
                        (item) =>
                            item.status !== "trash" &&
                            item.status !== "delete" &&
                            item.status !== "draft"
                    );
                    return (
                        <MenuItems
                            key={index}
                            menuItemName={item.displayName}
                            count={activeItems.length}
                            items={item}
                            index={index}
                        />
                    );
                })}
            </Box>
        </Root>
    );
}
