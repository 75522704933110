import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

const Root = styled(Grid)(() => ({
    "&.container": {
        height: "95vh"
    },
    "& .grid": {
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important"
    },
    "& .image": { height: "70px" },
    "& .title": {
        fontFamily: "Poppins",
        color: "#323D3F",
        fontSize: "6rem",
        lineHeight: "1.5"
    },
    "& .titleSpan": {
        color: "#087E8B"
    },
    "& .subtitle": {
        fontFamily: "Poppins",
        color: "#323D3F",
        lineHeight: "2rem",
        textAlign: "center",
        margin: "20px 0"
    },
    "& .button": {
        fontFamily: "Poppins"
    }
}));

export { Root };
