import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import { Fonts } from "../../const/font";
import { useMemo, useState } from "react";
import Icon from "./Icon";
import { Box } from "@mui/system";
import { PrettoSlider } from "./CommonStyles";
import CustomTooltip from "./CustomTooltip/CustomTooltip";

const fontWeightList = [
    { fontWeight: 100, label: "Thin" },
    { fontWeight: 200, label: "ExtraLight" },
    { fontWeight: 300, label: "Light" },
    { fontWeight: 400, label: "Regular" },
    { fontWeight: 500, label: "Medium" },
    { fontWeight: 600, label: "Semibold" },
    { fontWeight: 700, label: "Bold" },
    { fontWeight: 800, label: "ExtraBold" },
    { fontWeight: 900, label: "Black" }
];

export const getFontWeights = (font) => {
    const fontWeightMatches = font.linkHtml.match(/wght@(\d+(?:;\d+)*)/);
    return fontWeightMatches
        ? fontWeightMatches[1].split(";").map((weight) => parseInt(weight, 10))
        : [400]; // Default to regular (400) if no numeric font weight found
};

//Function to Extract Font Weights from
const extractFontWeights = (linkHtml) => {
    const weightsMatch = linkHtml?.match(/@([0-9;]+)/);
    if (weightsMatch) {
        const weights = weightsMatch[1]?.split(";");
        return weights?.length;
    }
    return 1;
};

export const FontSelect = ({
    selectedFont,
    setSelectedFont,
    selectedFontWeight,
    setSelectedFontWeight,
    setDependencyChanged = false
}) => {
    const filteredFontWeights = useMemo(() => {
        if (selectedFont) {
            const availableFontWeight = fontWeightList.filter((option) =>
                getFontWeights(selectedFont).includes(option.fontWeight)
            );

            // Set the selected font weight to the first option of the availableFontWeight list
            if (availableFontWeight.length > 0) {
                if (
                    !availableFontWeight.some(
                        (item) => item.fontWeight === selectedFontWeight
                    )
                ) {
                    if (
                        availableFontWeight.some(
                            (item) => item.fontWeight === 400
                        )
                    ) {
                        setSelectedFontWeight(400);
                    } else {
                        setSelectedFontWeight(
                            availableFontWeight[0].fontWeight
                        );
                    }
                }
            }
            return availableFontWeight;
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFont]);

    // Sort the fonts array alphabetically
    const sortedFonts = Fonts.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
            <Autocomplete
                fullWidth
                disablePortal
                disableClearable
                id="select-font"
                className="autocompleteBox"
                options={sortedFonts.map((font) => font.name)}
                value={selectedFont?.name || ""}
                onChange={(event, newValue) => {
                    const selectedFont = sortedFonts.find(
                        (font) => font.name === newValue
                    );
                    setSelectedFont(selectedFont);
                    setDependencyChanged && setDependencyChanged(true);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Font"
                        className={`autoCompleteSelect autoCompleteTextField`}
                    />
                )}
                componentsProps={{
                    popper: {
                        className: "autoCompletePopper"
                    },
                    paper: {
                        className: "autoCompleteSelectRow"
                    }
                }}
                renderOption={(props, option) => {
                    const font = sortedFonts?.find(
                        (font) => font?.name === option
                    );
                    const fontWeightCount = font
                        ? ` (${extractFontWeights(font?.linkHtml)})`
                        : "";

                    return (
                        <li
                            {...props}
                            style={{
                                fontFamily: option,
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            {option}{" "}
                            <span
                                style={{
                                    fontSize: 14,
                                    fontWeight: 300,
                                    fontFamily: "Roboto"
                                }}
                            >
                                {fontWeightCount}
                            </span>
                        </li>
                    );
                }}
            />
            <Autocomplete
                fullWidth
                disablePortal
                disableClearable
                id="select-font-weight"
                className="autocompleteBox"
                options={filteredFontWeights.map((weight) => weight.label)}
                value={
                    selectedFontWeight === 100
                        ? "Thin"
                        : selectedFontWeight === 200
                        ? "ExtraLight"
                        : selectedFontWeight === 300
                        ? "Light"
                        : selectedFontWeight === 400
                        ? "Regular"
                        : selectedFontWeight === 500
                        ? "Medium"
                        : selectedFontWeight === 600
                        ? "Semibold"
                        : selectedFontWeight === 700
                        ? "Bold"
                        : selectedFontWeight === 800
                        ? "ExtraBold"
                        : selectedFontWeight === 900
                        ? "Black"
                        : "" || ""
                }
                onChange={(event, newValue) => {
                    const optionVal =
                        newValue === "Thin"
                            ? 100
                            : newValue === "ExtraLight"
                            ? 200
                            : newValue === "Light"
                            ? 300
                            : newValue === "Regular"
                            ? 400
                            : newValue === "Medium"
                            ? 500
                            : newValue === "Semibold"
                            ? 600
                            : newValue === "Bold"
                            ? 700
                            : newValue === "ExtraBold"
                            ? 800
                            : newValue === "Black"
                            ? 900
                            : "";
                    setSelectedFontWeight(optionVal);
                    setDependencyChanged && setDependencyChanged(true);
                }}
                getOptionLabel={(option) => option.toString()}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Weight"
                        className={`autoCompleteSelect autoCompleteTextField`}
                    />
                )}
                componentsProps={{
                    popper: {
                        className: "autoCompletePopper"
                    },
                    paper: {
                        className: "autoCompleteSelectRow"
                    }
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderOption={(props, option) => (
                    <li
                        {...props}
                        style={{
                            fontFamily: selectedFont.name,
                            fontWeight:
                                option === "Thin"
                                    ? 100
                                    : option === "ExtraLight"
                                    ? 200
                                    : option === "Light"
                                    ? 300
                                    : option === "Regular"
                                    ? 400
                                    : option === "Medium"
                                    ? 500
                                    : option === "Semibold"
                                    ? 600
                                    : option === "Bold"
                                    ? 700
                                    : option === "ExtraBold"
                                    ? 800
                                    : option === "Black"
                                    ? 900
                                    : ""
                        }}
                    >
                        {option}
                    </li>
                )}
            />
        </>
    );
};

export const ColorInput = ({
    value,
    setValue,
    setDependencyChanged = false
}) => {
    let colorChangeTimeout;

    return (
        <div
            className="styleOption"
            style={{
                width: "30px",
                height: "30px",
                borderRadius: "5px",
                background: value,
                border: "1px solid #B4B3B3"
            }}
        >
            <input
                className="invisibleColorPicker"
                style={{
                    padding: "0 !important"
                }}
                type="color"
                value={value}
                onChange={(e) => {
                    clearTimeout(colorChangeTimeout);
                    const newValue = e.target.value;
                    colorChangeTimeout = setTimeout(() => {
                        setValue(newValue);
                        setDependencyChanged && setDependencyChanged(true);
                    }, 100);
                }}
            />
        </div>
    );
};

export const ImageCard = (props) => {
    const { label, icon, onClick, isActive, labelPos = "" } = props;
    return (
        <Box className="imageCardBox">
            {label !== "" && labelPos === "top" ? (
                <Box className="imageCardItemTitle">{label}</Box>
            ) : (
                ""
            )}
            <Box className="imageCardItem flexBox alignCenter justifyCenter">
                <Icon
                    className={`${isActive ? "active" : ""}`}
                    onClick={onClick}
                    name={icon}
                />
            </Box>
            {label !== "" && labelPos !== "top" ? (
                <Box className="imageCardItemTitle">{label}</Box>
            ) : (
                ""
            )}
        </Box>
    );
};

export const Tooltip = ({ ...style }) => {
    return (
        <CustomTooltip title="Learn More">
            <span
                style={{ ...style.style, display: "flex", cursor: "pointer" }}
                onClick={() => {
                    window.open(
                        "https://help.stellarmenus.com/en/collections/8251024-print-menus",
                        "_blank"
                    );
                }}
            >
                <Icon name="infoIcon" />
            </span>
        </CustomTooltip>
    );
};

export const RangeSlider = ({
    handleSliderChange,
    sliderVal,
    sliderLabel,
    min,
    max,
    step
}) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <Box className="sliderBox">
            {sliderLabel ? (
                <Typography className="sliderLabel fontLabel">
                    {sliderLabel}
                </Typography>
            ) : (
                ""
            )}
            <Box className="flexBox alignCenter justifyBetween">
                <PrettoSlider
                    value={sliderVal}
                    min={min}
                    step={step}
                    max={max}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                />
                <Box className={`numberCount ${isFocused ? "isFocused" : ""}`}>
                    <TextField
                        type={"number"}
                        className="fontTextField"
                        value={sliderVal}
                        onChange={handleSliderChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        inputProps={{
                            min: min,
                            max: max
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export const FormSwitch = ({
    label,
    value,
    setValue,
    setDependencyChanged = false
}) => {
    return (
        <div style={{ margin: "20px 0 0" }}>
            <p className="fontLabel" style={{ fontSize: "14px" }}>
                {label}
            </p>
            <FormControlLabel
                control={
                    <Switch
                        checked={value}
                        classes={{
                            switchBase: "switchDisabled"
                        }}
                        className={value ? "switch" : ""}
                    />
                }
                // name={name}
                onChange={() => {
                    setValue(!value);
                    setDependencyChanged && setDependencyChanged(true);
                }}
                label={value ? "Active" : "Inactive"}
                className="switchLabel"
                sx={{
                    "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#9E9E9E"
                    },
                    "& .switch + .MuiFormControlLabel-label": {
                        color: "#000000"
                    }
                }}
            />
        </div>
    );
};

export const LineHeight = ({
    setLineHeight,
    lineHeight,
    setDependencyChanged = false
}) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <CustomTooltip title="Line Height">
            <Box className={`numberCount ${isFocused ? "isFocused" : ""}`}>
                <Icon name="lineHeightIcon" />
                <TextField
                    type={"number"}
                    className="fontTextField"
                    value={lineHeight}
                    onChange={(event) => {
                        // Prevent negative numbers
                        setLineHeight(`${Math.abs(+event.target.value)}`);
                        setDependencyChanged && setDependencyChanged(true);
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
            </Box>
        </CustomTooltip>
    );
};

export const FontSize = ({
    setFontSize,
    fontSize,
    setDependencyChanged = false
}) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <CustomTooltip title="Font Size" placement="top-end">
            <Box className={`numberCount ${isFocused ? "isFocused" : ""}`}>
                <Icon name="fontSizeIcon" />
                <TextField
                    type={"number"}
                    className="fontTextField"
                    value={fontSize}
                    onChange={(event) => {
                        // Prevent negative numbers
                        setFontSize(`${Math.abs(+event.target.value)}`);
                        setDependencyChanged && setDependencyChanged(true);
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
            </Box>
        </CustomTooltip>
    );
};

export const LetterSpacing = ({
    setLetterSpacing,
    letterSpacing,
    setDependencyChanged = false
}) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <CustomTooltip title="Letter Spacing" placement="bottom-end">
            <Box
                className={`numberCount justifyEnd ${
                    isFocused ? "isFocused" : ""
                }`}
                sx={{
                    mt: 2
                }}
            >
                <Icon name="letterSpacing" />
                <TextField
                    type={"number"}
                    className="fontTextField"
                    value={letterSpacing}
                    onChange={(event) => {
                        // Prevent negative numbers
                        setLetterSpacing(event.target.value);
                        setDependencyChanged && setDependencyChanged(true);
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
            </Box>
        </CustomTooltip>
    );
};

export const NumberCount = ({
    setNumberCount,
    numberCount,
    setDependencyChanged = false
}) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <Box className={`numberCount ${isFocused ? "isFocused" : ""}`}>
            <TextField
                type={"number"}
                className="fontTextField"
                value={numberCount}
                onChange={(event) => {
                    // Prevent negative numbers
                    setNumberCount(Math.abs(+event.target.value));
                    setDependencyChanged && setDependencyChanged(true);
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
        </Box>
    );
};

export const FormRadio = ({
    label,
    name,
    radioOption,
    radioVal,
    setRadioVal,
    setDependencyChanged = false
}) => {
    return (
        <FormControl>
            {label ? (
                <FormLabel className="layoutName" id="radio-button">
                    {label}
                </FormLabel>
            ) : (
                ""
            )}
            <RadioGroup
                row
                value={
                    radioVal === 1
                        ? "one"
                        : radioVal === 2
                        ? "two"
                        : radioVal === 3
                        ? "three"
                        : radioVal === 4
                        ? "four"
                        : "" || ""
                }
                aria-labelledby="radio-button"
                name={name}
                onChange={(e) => {
                    setRadioVal(
                        e.target.value === "one"
                            ? 1
                            : e.target.value === "two"
                            ? 2
                            : e.target.value === "three"
                            ? 3
                            : e.target.value === "four"
                            ? 4
                            : "" || ""
                    );
                    setDependencyChanged && setDependencyChanged(true);
                }}
            >
                {radioOption?.map((option, index) => {
                    return (
                        <FormControlLabel
                            value={option}
                            key={index}
                            control={
                                <Radio
                                    sx={{
                                        color: "rgba(0, 0, 0, 0.6)",
                                        "&.Mui-checked": {
                                            color: "#CC5803"
                                        },
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(204, 88, 3, 0.04)"
                                        }
                                    }}
                                />
                            }
                            label={option}
                            sx={{
                                "& .MuiFormControlLabel-label": {
                                    fontSize: 14,
                                    lineHeight: 1.2,
                                    color: "#000000",
                                    textTransform: "capitalize"
                                },
                                "& .MuiButtonBase-root.MuiRadio-root": {
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }
                            }}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};

export const FormRadio1 = ({
    label,
    name,
    radioOption,
    radioVal,
    setRadioVal,
    setDependencyChanged = false
}) => {
    return (
        <FormControl>
            <RadioGroup
                row
                value={radioVal}
                aria-labelledby="radio-button"
                name={name}
                onChange={(e) => {
                    setRadioVal(e.target.value);
                    setDependencyChanged && setDependencyChanged(true);
                }}
            >
                {radioOption?.map((option, index) => {
                    return (
                        <FormControlLabel
                            value={option}
                            key={index}
                            control={
                                <Radio
                                    sx={{
                                        color: "rgba(0, 0, 0, 0.6)",
                                        "&.Mui-checked": {
                                            color: "#CC5803"
                                        },
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(204, 88, 3, 0.04)"
                                        }
                                    }}
                                    icon={<Icon name="radioEmpty" />}
                                    checkedIcon={<Icon name="radioActive" />}
                                />
                            }
                            sx={{
                                "& .MuiFormControlLabel-label": {
                                    fontSize: 14,
                                    lineHeight: 1.2,
                                    color: "#000000",
                                    textTransform: "capitalize"
                                },
                                "& .MuiButtonBase-root.MuiRadio-root": {
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }
                            }}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};

export const CheckBox = ({ label, checked, setChecked }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={setChecked}
                    inputProps={{ "aria-label": "controlled" }}
                    icon={<Icon name="checkboxEmpty" />}
                    checkedIcon={<Icon name="checkboxActive" />}
                />
            }
            sx={{
                "&.MuiCheckbox-root": {
                    paddingLeft: 0
                },
                "&.MuiFormControlLabel-root, & .MuiFormControlLabel-label": {
                    margin: 0,
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#727272"
                }
            }}
            label={label}
        />
    );
};
