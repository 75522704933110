export function showPrice(
    priceObj: {
        price?: string | undefined;
        priceValue?: number | undefined;
        leadingText?: string | undefined;
        trailingText?: string | undefined;
    },
    configs: {
        currency_symbol?: string;
        currency_placement?: "before" | "after";
        show_item_price?: boolean;
        show_empty_decimals?: boolean;
        show_currency_symbol?: boolean;
    }
) {
    const {
        currency_symbol = "$",
        currency_placement = "before",
        show_item_price = true,
        show_empty_decimals = false,
        show_currency_symbol = true
    } = configs;
    if (!show_item_price) return "";

    const {
        priceValue: price_value,
        leadingText: leading_text,
        trailingText: trailing_text
    } = priceObj;
    const currencySymbol = show_currency_symbol ? currency_symbol : "";
    const price = show_empty_decimals
        ? formatPriceWithDecimals(price_value)
        : price_value;

    if (currency_placement === "before") {
        return `${Boolean(leading_text) ? leading_text + " " : ""}${
            Boolean(price) ? currencySymbol : ""
        }${Boolean(price) ? price : ""}${
            Boolean(trailing_text) ? " " + trailing_text : ""
        }`;
    }
    return `${Boolean(leading_text) ? leading_text + " " : ""}${
        Boolean(price) ? price : ""
    } ${Boolean(price) ? currencySymbol + " " : ""}${
        Boolean(trailing_text) ? trailing_text : ""
    }`;
}

// If the price is 10, it should return 10.00
function formatPriceWithDecimals(price?: number) {
    if (!price) return "";
    return price.toFixed(2);
}
