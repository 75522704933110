import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
    "&.newVersionBanner": {
        background: "white",
        padding: "24px 24px 20px",
        display: "flex",
        alignItems: "center",
        gap: 16,
        borderRadius: 20,
        border: `1px solid ${theme.colors.grey[100]}`,
        boxShadow: "0px 10px 30px rgba(31, 19, 0, 0.3)",
        "& button": {
            fontWeight: 500,
            color: theme.colors.orange[700],
            "&:hover": {
                fontWeight: 600
            }
        }
    },
    "& .bannerTitle": {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        margin: 0
    },
    "& .bannerDescription": {
        fontSize: 13,
        fontWeight: 300,
        fontStyle: "italic",
        lineHeight: "20px",
        margin: 0
    },
    "& .icon": {
        "& path": {
            fill: theme.colors.orange[700]
        }
    }
}));

export { Root };
