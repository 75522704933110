import { Root } from "../style";

const TextBlock = ({ text, styles, focusableBlock: FocusableBlock }) => {
    // Dynamic styles
    const {
        fontFamily,
        fontWeight,
        fontSize,
        lineHeight,
        textAlign,
        fontStyle,
        letterSpacing,
        upperCase,
        // boldFont,
        color
    } = { ...styles };

    const customStyles = {
        fontFamily,
        fontWeight:
            // boldFont === "bold" ? "bold" :
            fontWeight,
        fontSize: fontSize ? `${fontSize}px` : "",
        lineHeight: lineHeight ? `${lineHeight}px` : "",
        textAlign,
        fontStyle,
        letterSpacing: `${letterSpacing === 0 ? 0 : letterSpacing / 10}px`,
        color,
        textTransform: upperCase ? "uppercase" : "none"
    };

    const updatedText = text?.replace(/\n/g, "<br>");

    return (
        <Root className="blockWrapper">
            <p
                className="textBlock"
                style={customStyles}
                dangerouslySetInnerHTML={{ __html: updatedText }}
            />
            {FocusableBlock && <FocusableBlock></FocusableBlock>}
        </Root>
    );
};
export default TextBlock;
