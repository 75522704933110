import { styled } from "@mui/material";

const Root = styled("div")(() => ({
    "&.container": {
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        "&.printLoading": {
            position: "fixed",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 9,
            backgroundColor: "rgba(0,0,0,0.6)",
            "& .loadingText, & .circularProgress": {
                color: "#fff"
            }
        }
    },
    "& .loadingText": {
        width: "100%",
        textAlign: "center",
        marginTop: "30px",
        fontSize: "18px",
        lineHeight: 1.33,
        fontWeight: 500,
        color: "#939393"
    },
    "& .circularProgress": {
        width: "75px !important",
        height: "75px !important",
        display: "block",
        margin: "0 auto"
    }
}));

export { Root };
