import { Fonts } from "../../../const/font";
import WebFont from "webfontloader";
import { useEffect } from "react";
import { getFontWeights } from "../../UI/CommonComponents";

export default function FontImporter() {
    useEffect(() => {
        WebFont.load({
            google: {
                families: Fonts.map(
                    (font) => `${font.name}:${getFontWeights(font)}`
                )
            },
            timeout: 2000 // Wait 2 seconds before starting to load fonts
        });
    }, []);

    return null;
}
