import Icon from "../../../../UI/Icon";
import { Root } from "../style";
import Image from "../../../../UI/Image";
import { usePrinterContext } from "../../../../../context/printer";
import { CircularProgress } from "@mui/material";
import { loadImage } from "../../../../../utils/loadImage";
import { useEffect, useState } from "react";
import { isBlobUrl } from "../../../../../utils/common";

const ImageBlock = ({
    url,
    styles,
    alt,
    focusId,
    id,
    focusableBlock: FocusableBlock
}) => {
    const { alignment, width } = { ...styles };
    const { imageLoading } = usePrinterContext();

    const [imageSrc, setImageSrc] = useState("");

    useEffect(() => {
        if (url) {
            if (!isBlobUrl(url)) {
                loadImage(
                    url,
                    (image) => {
                        setImageSrc(image);
                    },
                    (error) => {
                        console.log(error);
                        setImageSrc(url);
                    }
                );
            } else {
                setImageSrc(url);
            }
        } else {
            setImageSrc(url);
        }
    }, [url]);

    return imageLoading && focusId === id ? (
        <CircularProgress
            className="circularProgress customImage"
            color="primary"
            style={{
                margin: "10px auto",
                display: "flex"
            }}
        ></CircularProgress>
    ) : (
        <Root
            className="blockWrapper imageBlockWrapper"
            style={{
                justifyContent: alignment
            }}
        >
            {url?.length === 0 ? (
                <Icon
                    style={{ width: width, height: width }}
                    name="placeHolderImage"
                />
            ) : (
                <Image
                    ImgSrc={imageSrc}
                    ImgAlt={alt}
                    ImgStyle={{ width: `${width}%` }}
                />
            )}
            {FocusableBlock && <FocusableBlock></FocusableBlock>}
        </Root>
    );
};

export default ImageBlock;
