import { Root } from "../style";
import { Box } from "@mui/system";
import {
    FormRadio,
    NumberCount,
    RangeSlider
} from "../../../../../UI/CommonComponents";
import { useEffect, useState } from "react";
import { usePrinterContext } from "../../../../../../context/printer";
import { cloneDeep, isEqual } from "lodash";
import { generateId } from "../../../../../../utils/uuid";
import ConfirmAction from "../../../../../Modals";
import { DEFAULT_ROW_COL_SPACE } from "../../../../../../const/general";

const ContainerStyle = ({ data }) => {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray
    } = usePrinterContext();

    const updatedPageBlocks = [...pageBlocks];
    const rowToUpdate = updatedPageBlocks[data.pageIndex]?.row[data.rowIndex];

    const rowStyles = rowToUpdate?.styles;

    const [colChange, setColChange] = useState(false);
    const [oldVal, setOldVal] = useState(rowToUpdate?.column?.length);
    const [colVal, setColVal] = useState(rowStyles?.columnCount);
    const [dependencyChanged, setDependencyChanged] = useState(false);
    const [colSpace, setColSpace] = useState(rowStyles?.columnSpace);
    const [paddingTop, setPaddingTop] = useState(rowStyles?.rowPadding?.top);
    const [paddingRight, setPaddingRight] = useState(
        rowStyles?.rowPadding?.right
    );
    const [paddingBottom, setPaddingBottom] = useState(
        rowStyles?.rowPadding?.bottom
    );
    const [paddingLeft, setPaddingLeft] = useState(rowStyles?.rowPadding?.left);

    useEffect(() => {
        if (undoRedoState?.state && isEqual(data, sectionBlock.data)) {
            return;
        }

        setOldVal(rowToUpdate?.column?.length);
        setColVal(rowStyles?.columnCount);
        setPaddingTop(rowStyles?.rowPadding?.top);
        setPaddingRight(rowStyles?.rowPadding?.right);
        setPaddingBottom(rowStyles?.rowPadding?.bottom);
        setPaddingLeft(rowStyles?.rowPadding?.left);
        setColSpace(rowStyles?.columnSpace);
        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.pageIndex, data.rowIndex, undoRedoState]);

    const handleColChange = () => {
        setPageBlocks((prevPageBlocks) => {
            const updatedPageBlocks = [...prevPageBlocks];
            const updatedPage = { ...updatedPageBlocks[data?.pageIndex] };
            const targetRow = updatedPage?.row[data?.rowIndex];
            const oldColVal = targetRow?.column?.length;

            if (targetRow) {
                // Clone the existing column array and fill it with new columns
                const newColumn = Array.from(
                    { length: colVal },
                    (_, columnIndex) => {
                        const ID = generateId();
                        const colWidth = 100 / colVal;

                        return columnIndex < oldColVal
                            ? {
                                  ...targetRow.column[columnIndex],
                                  ...(oldColVal !== colVal
                                      ? { styles: { columnWidth: colWidth } } // Set updated width
                                      : "") // Empty object if newWidth is false
                              }
                            : // Preserve existing data
                              {
                                  blocks: [],
                                  id: ID, // Add ID as a sibling for new data
                                  styles: {
                                      columnWidth: colWidth // Set width
                                  }
                              };
                    }
                );
                // Update the column array in the target row
                updatedPage.row[data.rowIndex].column = newColumn;
            }
            updatedPageBlocks[data.pageIndex] = updatedPage;
            return updatedPageBlocks;
        });

        if (colVal === 1) {
            setColSpace(DEFAULT_ROW_COL_SPACE);
        }
    };

    // To update the column in the Page
    useEffect(() => {
        const hasBlocks = pageBlocks[data?.pageIndex]?.row[
            data?.rowIndex
        ]?.column?.some((column) => column?.blocks?.length > 0);

        const oldVal =
            pageBlocks[data?.pageIndex]?.row[data.rowIndex]?.column?.length;

        if (hasBlocks && oldVal !== colVal) {
            setColChange(true);
            return;
        }

        handleColChange();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colVal]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            columnCount: colVal,
            columnSpace: colVal === 1 ? DEFAULT_ROW_COL_SPACE : colSpace,
            rowPadding: {
                top: paddingTop,
                right: paddingRight,
                bottom: paddingBottom,
                left: paddingLeft
            }
        };

        const updatedPageBlocks = [...pageBlocks];
        const rowToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data.rowIndex];

        if (rowToUpdate) {
            rowToUpdate.styles = { ...rowToUpdate?.styles, ...newStyles };

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }
        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        colVal,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        colSpace
    ]);

    const handleSpaceChange = (event, max) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = parsedValue < max ? parsedValue : max;
        if (typeof newValue === "number") {
            setDependencyChanged(true);
            setColSpace(newValue);
        }
    };

    const handleColConfirm = () => {
        handleColChange();
        setColChange(false);
    };

    return (
        <Root className="stylesBlockWrapper stylingChanges">
            <Box className="columnContainerBlock columnCount">
                <Box className="blockTitle">
                    <span className="mediumFont">Number of Columns</span>
                </Box>
                <FormRadio
                    name="number-of-column"
                    radioOption={["one", "two", "three", "four"]}
                    setRadioVal={setColVal}
                    radioVal={colVal}
                    setDependencyChanged={setDependencyChanged}
                />
            </Box>
            {colVal !== 1 ? (
                <>
                    <Box className="columnContainerBlock">
                        <Box className="blockTitle">
                            <span className="mediumFont">
                                Space Between Columns
                            </span>
                        </Box>
                        <Box className="columnSpaceWrap">
                            <RangeSlider
                                sliderLabel=""
                                sliderVal={colSpace}
                                handleSliderChange={(event) =>
                                    handleSpaceChange(event, 50)
                                }
                                min={0}
                                max={50}
                                step={1}
                            />
                        </Box>
                    </Box>
                </>
            ) : (
                ""
            )}
            <Box className="columnContainerBlock">
                <Box className="blockTitle">
                    <span className="mediumFont">Container Padding</span>
                </Box>
                <Box className="containerPadding flexBox alignCenter justifyCenter">
                    <NumberCount
                        setNumberCount={setPaddingTop}
                        numberCount={paddingTop}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setPaddingRight}
                        numberCount={paddingRight}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setPaddingBottom}
                        numberCount={paddingBottom}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setPaddingLeft}
                        numberCount={paddingLeft}
                        setDependencyChanged={setDependencyChanged}
                    />
                </Box>
                <Box className="flexBox justifyAround alignCenter alignmentsText">
                    <Box className="mediumFont">Top</Box>
                    <Box className="mediumFont">Right</Box>
                    <Box className="mediumFont">Bottom</Box>
                    <Box className="mediumFont">Left</Box>
                </Box>
            </Box>
            <ConfirmAction
                open={colChange}
                button1Text="Confirm"
                button2Text="Cancel"
                actionCancel={() => {
                    setColChange(false);
                    setColVal(oldVal);
                }}
                actionConfirm={handleColConfirm}
                icon="warning"
                dialogText={`Column Layout Change`}
                note="Please be aware that any data within a column that is being removed will be removed from your menu layout. Any custom blocks (titles, paragraphs, and images) will be lost."
            />
        </Root>
    );
};
export default ContainerStyle;
