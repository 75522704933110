import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrinterContextProvider, usePrinterContext } from "../context/printer";
import MainContainer from "../components/Printer/MainContainer";
import NotFound from "../pages/NotFound";
import Unauthorized from "../pages/Unauthorized";
import { AlertContextProvider } from "../context/alert";
import FontImporter from "../components/Printer/FontImporter";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import { SetMenu } from "../components/Printer/SetMenu";

const RedirectToHome = () => {
    useEffect(() => {
        window.location.replace(`${process.env.REACT_APP_MENU_MANAGER}`);
    }, []);

    return <></>;
};

function SetHead() {
    const { menu } = usePrinterContext();
    const [dynamicTitle, setDynamicTitle] = useState("");

    useEffect(() => {
        const dynamicTitle = menu?.displayName || "Printer Menu";
        setDynamicTitle(dynamicTitle);
    }, [menu?.displayName]);

    return (
        <>
            <Helmet>
                <title>{dynamicTitle} - Print</title>
            </Helmet>
        </>
    );
}

export default function Routing() {
    return (
        <Router>
            <HelmetProvider>
                <FontImporter />
                <PrinterContextProvider>
                    <AlertContextProvider>
                        <Routes>
                            <Route
                                path="/menu/:restaurant/:menu/edit"
                                element={
                                    <SetMenu>
                                        <SetHead />
                                        <MainContainer></MainContainer>
                                    </SetMenu>
                                }
                            ></Route>
                            <Route
                                path="/"
                                element={
                                    <>
                                        <SetHead />
                                        <RedirectToHome />
                                    </>
                                }
                            ></Route>
                            <Route
                                path="/unauthorized"
                                element={
                                    <>
                                        <SetHead />
                                        <Unauthorized></Unauthorized>
                                    </>
                                }
                            ></Route>
                            <Route
                                path="*"
                                element={
                                    <>
                                        <SetHead />
                                        <NotFound></NotFound>
                                    </>
                                }
                            ></Route>
                        </Routes>
                    </AlertContextProvider>
                </PrinterContextProvider>
            </HelmetProvider>
        </Router>
    );
}
