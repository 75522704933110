import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Icon from "../../../../../../UI/Icon";
import {
    ColorInput,
    FontSelect,
    FontSize,
    LetterSpacing,
    LineHeight,
    RangeSlider
} from "../../../../../../UI/CommonComponents";
import { usePrinterContext } from "../../../../../../../context/printer";
import { Fonts } from "../../../../../../../const/font";
import { cloneDeep, isEqual } from "lodash";
import CustomTooltip from "../../../../../../UI/CustomTooltip/CustomTooltip";

export default function SubSectionTitle({ data }) {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray
    } = usePrinterContext();
    const [dependencyChanged, setDependencyChanged] = useState(false);

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex];

    const selectedFontName = Fonts.find(
        (font) =>
            font.name ===
            blockToUpdate?.data?.styles?.subSectionTitle?.fontFamily
    );

    const [lineHeight, setLineHeight] = useState(
        blockToUpdate?.data?.styles?.subSectionTitle?.lineHeight
    );
    const [fontSize, setFontSize] = useState(
        blockToUpdate?.data?.styles?.subSectionTitle?.fontSize
    );
    const [letterSpacing, setLetterSpacing] = useState(
        blockToUpdate?.data?.styles?.subSectionTitle?.letterSpacing
    );
    const [upperCaseText, setUpperCaseText] = useState(
        blockToUpdate?.data?.styles?.subSectionTitle?.upperCase
    );
    const [textColor, setTextColor] = useState(
        blockToUpdate?.data?.styles?.subSectionTitle?.color
    );
    const [boldFont, setBoldFont] = useState(
        blockToUpdate?.data?.styles?.subSectionTitle?.boldFont === "bold"
            ? true
            : false
    );
    const [italicFont, setItalicFont] = useState(
        blockToUpdate?.data?.styles?.subSectionTitle?.fontStyle === "normal"
            ? false
            : true
    );
    const [selectedFont, setSelectedFont] = useState(selectedFontName);
    const [selectedFontWeight, setSelectedFontWeight] = useState(
        blockToUpdate?.data?.styles?.subSectionTitle?.fontWeight
    );
    const [sectionSpace, setSectionSpace] = useState(
        blockToUpdate?.data?.styles?.subSectionTitle?.sectionSpace
    );
    const [activeAlignment, setActiveAlignment] = useState(
        blockToUpdate?.data?.styles?.subSectionTitle?.textAlign
    );

    useEffect(() => {
        if (undoRedoState?.state && isEqual(data, sectionBlock.data)) {
            return;
        }

        setLineHeight(blockToUpdate?.data?.styles?.subSectionTitle?.lineHeight);
        setFontSize(blockToUpdate?.data?.styles?.subSectionTitle?.fontSize);
        setLetterSpacing(
            blockToUpdate?.data?.styles?.subSectionTitle?.letterSpacing
        );
        setUpperCaseText(
            blockToUpdate?.data?.styles?.subSectionTitle?.upperCase
        );
        setTextColor(blockToUpdate?.data?.styles?.subSectionTitle?.color);
        setSelectedFont(selectedFontName);
        setActiveAlignment(
            blockToUpdate?.data?.styles?.subSectionTitle?.textAlign
        );
        setSectionSpace(
            blockToUpdate?.data?.styles?.subSectionTitle?.sectionSpace
        );
        setSelectedFontWeight(
            blockToUpdate?.data?.styles?.subSectionTitle?.fontWeight
        );
        setBoldFont(
            blockToUpdate?.data?.styles?.subSectionTitle?.boldFont === "bold"
                ? true
                : false
        );
        setItalicFont(
            blockToUpdate?.data?.styles?.subSectionTitle?.fontStyle === "normal"
                ? false
                : true
        );

        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.pageIndex, data.columnIndex, data.blockIndex, undoRedoState]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            lineHeight,
            fontSize,
            color: textColor,
            textAlign: activeAlignment,
            fontWeight: selectedFontWeight,
            boldFont: boldFont ? "bold" : "normal",
            fontStyle: italicFont ? "italic" : "normal",
            fontFamily: selectedFont?.name,
            sectionSpace,
            letterSpacing,
            upperCase: upperCaseText
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex];

        if (blockToUpdate) {
            blockToUpdate.data.styles.subSectionTitle = {
                ...blockToUpdate.data.styles.subSectionTitle,
                ...newStyles
            };

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }

        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        upperCaseText,
        letterSpacing,
        activeAlignment,
        sectionSpace,
        lineHeight,
        fontSize,
        textColor,
        boldFont,
        italicFont,
        selectedFont,
        selectedFontWeight
    ]);

    const handleSpaceChange = (event, max) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = parsedValue < max ? parsedValue : max;
        if (typeof newValue === "number") {
            setDependencyChanged(true);
            setSectionSpace(newValue);
        }
    };

    const handleAlignment = (alignment) => {
        setDependencyChanged(true);
        setActiveAlignment(alignment);
    };

    return (
        <div className="collapseContentWrapper subSectionTitle">
            <Box className="fontFamilyOption">
                <Box className="fontLabel">Font</Box>
                <Box className="fontsSelect">
                    <FontSelect
                        selectedFont={selectedFont}
                        setSelectedFont={setSelectedFont}
                        selectedFontWeight={selectedFontWeight}
                        setSelectedFontWeight={setSelectedFontWeight}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <Box className="fontSizeOption">
                        <LineHeight
                            setLineHeight={setLineHeight}
                            lineHeight={lineHeight}
                            setDependencyChanged={setDependencyChanged}
                        />
                        <FontSize
                            setFontSize={setFontSize}
                            fontSize={fontSize}
                            setDependencyChanged={setDependencyChanged}
                        />
                    </Box>
                    <LetterSpacing
                        setLetterSpacing={setLetterSpacing}
                        letterSpacing={letterSpacing}
                        setDependencyChanged={setDependencyChanged}
                    />
                </Box>
            </Box>
            <Box className="alignmentStyles flexBox alignCenter justifyBetween">
                <Box className="fontLabel">Text Alignment</Box>
                <Box className="flexBox alignCenter">
                    <Icon
                        id="left-align"
                        name="leftAlignIcon"
                        className={`styleOption ${
                            activeAlignment === "left" ? "active" : ""
                        }`}
                        onClick={() => {
                            handleAlignment("left");
                        }}
                    />
                    <Icon
                        id="center-align"
                        name="centerAlignIcon"
                        className={`styleOption ${
                            activeAlignment === "center" ? "active" : ""
                        }`}
                        onClick={() => handleAlignment("center")}
                    />
                    <Icon
                        id="right-align"
                        name="rightAlignIcon"
                        className={`styleOption ${
                            activeAlignment === "right" ? "active" : ""
                        }`}
                        onClick={() => handleAlignment("right")}
                    />
                </Box>
            </Box>
            <Box className="fontStylesOption flexBox alignCenter justifyBetween">
                <Box className="fontLabel">Text Styles</Box>
                <Box className="flexBox alignCenter">
                    {/* <Icon
                        className={`styleOption ${boldFont ? "active" : ""}`}
                        name="boldIcon"
                        onClick={() => {
                            setBoldFont(!boldFont);
                            setDependencyChanged(true);
                        }}
                    /> */}
                    <CustomTooltip title="Uppercase">
                        <Box
                            className={`styleOption ${
                                upperCaseText ? "active" : ""
                            }`}
                        >
                            <Icon
                                name="allCapsIcon"
                                onClick={() => {
                                    setUpperCaseText(!upperCaseText);
                                    setDependencyChanged(true);
                                }}
                            />
                        </Box>
                    </CustomTooltip>
                    <CustomTooltip title="Italic">
                        <Box
                            className={`styleOption ${
                                italicFont ? "active" : ""
                            }`}
                        >
                            <Icon
                                name="italicIcon"
                                onClick={() => {
                                    setItalicFont(!italicFont);
                                    setDependencyChanged(true);
                                }}
                            />
                        </Box>
                    </CustomTooltip>
                    <CustomTooltip title="Color">
                        <Box sx={{ ml: 1 }}>
                            <ColorInput
                                value={textColor}
                                setValue={setTextColor}
                                setDependencyChanged={setDependencyChanged}
                            />
                        </Box>
                    </CustomTooltip>
                </Box>
            </Box>
            <RangeSlider
                sliderLabel="Space Between Sub-Sections"
                sliderVal={sectionSpace}
                handleSliderChange={(event) => handleSpaceChange(event, 50)}
                min={0}
                max={50}
                step={1}
            />
        </div>
    );
}
