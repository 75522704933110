import { styled } from "@mui/system";

const Root = styled("div")({
    padding: "20px 0",
    "& .dragItemsTitle": {
        display: "flex",
        alignItems: "center",
        padding: "0 20px",
        fontWeight: 500,
        fontSize: "16px",
        marginBottom: "25px",
        "& svg": {
            marginLeft: "12px",
            height: 14,
            width: 14
        }
    },
    "& .menuItemsContainer": {
        padding: "0px 16px"
    },
    "& .menuItems": {
        marginBottom: "16px",
        background: "#FAFAFA",
        border: "1px solid #EAEAEA",
        display: "flex",
        borderRadius: "6px",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "14px 20px",
        fontWeight: "400",
        color: "#222222",
        fontSize: "14px",
        transition: "borderColor 0.3s",
        position: "relative",
        opacity: "0.95",
        "& .dragging-icon": {
            position: "absolute",
            top: -5,
            right: -5,
            backgroundColor: "#E1F1F9",
            borderRadius: "50%",
            overflow: "hidden",
            opacity: 0,
            transition: "all 0.3s"
        },
        "& .menuItemName": {
            flex: "1"
        },
        "&:hover": {
            borderColor: "#B4B3B3",
            "& .numberAndSpoonContainer": {
                borderColor: "#D3D3D3",
                "& svg": {
                    "& path": {
                        fill: "#727272"
                    }
                }
            },
            "& .sortDotIcon": {
                "& path": {
                    fill: "#939393"
                }
            }
        },
        "&.active": {
            borderColor: "#1890FF",
            borderStyle: "dashed",
            boxShadow: "2px 2px 8px 0px #0000000D",
            cursor: "grabbing",
            "& .sortDotIcon": {
                cursor: "grabbing"
            },
            "& .dragging-icon": {
                opacity: 1
            }
        }
    },
    "& .rightBlock": {
        display: "flex",
        alignItems: "center",
        "& .sortDotIcon": {
            cursor: "pointer",
            "& path": {
                transition: "0.3s"
            }
        }
    },
    "& .numberAndSpoonContainer": {
        marginRight: "16px",
        display: "flex",
        alignItems: "center",
        minWidth: "50px",
        minHeight: "24px",
        border: "1px solid #D3D3D3",
        borderRadius: "16px",
        fontSize: "12px",
        fontWeight: "500",
        padding: "5px",
        color: "#727272",
        transition: "0.3s",
        "& svg ": {
            margin: "0px 5px",
            "& path": {
                transition: "0.3s"
            }
        }
    }
});

export { Root };
