const DEFAULT_PAPER_SIZE = "letter";

const PAPER_SIZES = {
    letter: {
        width: "8.5in",
        height: "11in",
        display: "Letter - 8.5in x 11in",
        name: "letter"
    },
    legal: {
        width: "8.5in",
        height: "14in",
        display: "Legal - 8.5in x 14in",
        name: "legal"
    },
    ledger: {
        width: "11in",
        height: "17in",
        display: "Ledger - 11in x 17in",
        name: "ledger"
    },
    a4: {
        width: "8.27in",
        height: "11.69in",
        display: "A4 - 8.27in x 11.69in",
        name: "a4"
    },
    a3: {
        width: "11.69in",
        height: "16.54in",
        display: "A3 - 11.69in x 16.54in",
        name: "a3"
    }
};

export { PAPER_SIZES, DEFAULT_PAPER_SIZE };
