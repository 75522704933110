import { Root, classes } from "./styles";
import Icon from "../UI/Icon";
import CustomTooltip from "../UI/CustomTooltip/CustomTooltip";
import { usePrinterContext } from "../../context/printer";
import HandlePrint from "../../utils/printMenu";
import { useState } from "react";
import ConfirmAction from "../Modals";
import Button from "../Button";
import { SIDEBAR_TAB_INDEX } from "../../const/general";

export default function PreviewNavbar() {
    const {
        menu,
        setShowPreview,
        overflowContent,
        setTabIndex,
        setHandleDrawer
    } = usePrinterContext();
    const [overflowPopup, setOverflowPopup] = useState(false);

    const menuName = menu?.displayName || "Menu Name";

    const { LAYOUT } = SIDEBAR_TAB_INDEX;

    const InfoMessage = () => {
        return (
            <p className="infoText" style={{ lineHeight: "22px" }}>
                Your menu currently has overflow content. If proceeding with
                printing some of your menu content may we be cut off.
            </p>
        );
    };

    return (
        <Root item xs={12} className={classes.navbar}>
            <div className={`${classes.leftBlock} ${classes.flexBox}`}>
                <CustomTooltip title="Back">
                    <span
                        className={classes.iconCircle}
                        onClick={() => {
                            setShowPreview((s) => !s);
                            setTabIndex(LAYOUT);
                            setHandleDrawer(false);
                        }}
                    >
                        <Icon name="back" />
                    </span>
                </CustomTooltip>
                <span className={classes.title}>{menuName}</span>
            </div>
            <div className={`${classes.rightBlock} ${classes.flexBox}`}>
                {overflowContent ? (
                    <Button
                        type="primary"
                        color="orange"
                        innerText="Print"
                        className="print_btn"
                        icon="print"
                        onClick={() => setOverflowPopup(true)}
                    ></Button>
                ) : (
                    <HandlePrint />
                )}
            </div>
            <ConfirmAction
                open={overflowPopup}
                button1Text="Cancel Print"
                button2Text={
                    <HandlePrint
                        buttonText="Print Menu"
                        type="secondary"
                        setOverflowPopup={setOverflowPopup}
                    />
                }
                actionConfirm={() => setOverflowPopup(false)}
                actionCancel={() => setOverflowPopup(false)}
                icon="warning"
                dialogText={`Menu content is overflow`}
                note={<InfoMessage />}
            />
        </Root>
    );
}
