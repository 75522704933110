import { Root } from "../style";
import { Box } from "@mui/system";
import Icon from "../../../../../UI/Icon";
import {
    ColorInput,
    FontSelect,
    FontSize,
    LetterSpacing,
    LineHeight
} from "../../../../../UI/CommonComponents";
import { Chip, Collapse } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { usePrinterContext } from "../../../../../../context/printer";
import { Fonts } from "../../../../../../const/font";
import { CollapseRoot } from "../../../../../UI/CommonStyles";
import ConfirmAction from "../../../../../Modals";
import { cloneDeep, isEqual } from "lodash";
import CustomTooltip from "../../../../../UI/CustomTooltip/CustomTooltip";

const textStylesData = [{ name: "Text Styles", dataId: "text-styles" }];

const TextStyles = ({ data }) => {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray
    } = usePrinterContext();

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex];
    const selectedFontName = Fonts.find(
        (font) => font.name === blockToUpdate?.data?.styles?.fontFamily
    );

    const [expanded, setExpanded] = useState(false);
    const [lineHeight, setLineHeight] = useState(
        blockToUpdate?.data?.styles?.lineHeight
    );
    const [fontSize, setFontSize] = useState(
        blockToUpdate?.data?.styles?.fontSize
    );
    const [letterSpacing, setLetterSpacing] = useState(
        blockToUpdate?.data?.styles?.letterSpacing
    );
    const [textColor, setTextColor] = useState(
        blockToUpdate?.data?.styles?.color
    );
    const [activeAlignment, setActiveAlignment] = useState(
        blockToUpdate?.data?.styles?.textAlign
    );
    const [boldFont, setBoldFont] = useState(
        blockToUpdate?.data?.styles?.boldFont === "bold" ? true : false
    );
    const [upperCaseText, setUpperCaseText] = useState(
        blockToUpdate?.data?.styles?.upperCase
    );
    const [italicFont, setItalicFont] = useState(
        blockToUpdate?.data?.styles?.fontStyle === "normal" ? false : true
    );
    const [selectedFont, setSelectedFont] = useState(selectedFontName);
    const [selectedFontWeight, setSelectedFontWeight] = useState(
        blockToUpdate?.data?.styles?.fontWeight
    );
    const [applyAllModal, setApplyAllModal] = useState(false);

    const dataBlock = data?.blockType
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    useEffect(() => {
        if (undoRedoState?.state && isEqual(data, sectionBlock.data)) {
            return;
        }

        setLineHeight(blockToUpdate?.data?.styles?.lineHeight);
        setFontSize(blockToUpdate?.data?.styles?.fontSize);
        setLetterSpacing(blockToUpdate?.data?.styles?.letterSpacing);
        setTextColor(blockToUpdate?.data?.styles?.color);
        setActiveAlignment(blockToUpdate?.data?.styles?.textAlign);
        setSelectedFont(selectedFontName);
        setSelectedFontWeight(blockToUpdate?.data?.styles?.fontWeight);
        setBoldFont(
            blockToUpdate?.data?.styles?.boldFont === "bold" ? true : false
        );
        setUpperCaseText(blockToUpdate?.data?.styles?.upperCase);
        setItalicFont(
            blockToUpdate?.data?.styles?.fontStyle === "normal" ? false : true
        );
        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data.pageIndex,
        data.rowIndex,
        data.columnIndex,
        data.blockIndex,
        undoRedoState
    ]);

    const [dependencyChanged, setDependencyChanged] = useState(false);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            lineHeight,
            fontSize,
            color: textColor,
            textAlign: activeAlignment,
            fontWeight: selectedFontWeight,
            boldFont: boldFont ? "bold" : "normal",
            fontStyle: italicFont ? "italic" : "normal",
            fontFamily: selectedFont?.name,
            letterSpacing,
            upperCase: upperCaseText
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex];

        if (blockToUpdate) {
            blockToUpdate.data.styles = {
                ...blockToUpdate.data.styles,
                ...newStyles
            };

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }
        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        upperCaseText,
        letterSpacing,
        lineHeight,
        fontSize,
        textColor,
        activeAlignment,
        boldFont,
        italicFont,
        selectedFont,
        selectedFontWeight
    ]);

    const toggleRow = useCallback((index) => {
        setExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }, []);

    const handleAlignment = (alignment) => {
        setActiveAlignment(alignment);
    };

    const handleApplyAll = () => {
        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.styles;

        if (blockToUpdate) {
            updatedPageBlocks.forEach((pageBlock) => {
                pageBlock.row.forEach((rowBlock) => {
                    rowBlock.column.forEach((colBlock) => {
                        colBlock?.blocks.forEach((block) => {
                            if (block.blockType === data.blockType) {
                                block.data.styles = { ...blockToUpdate };
                            }
                        });
                    });
                });
            });

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
            setApplyAllModal(false);
        }
    };

    const InfoMessage = () => {
        return (
            <p className="infoText">
                This will apply the current <strong>{dataBlock}</strong> to the
                entire menu.
            </p>
        );
    };

    return (
        <Root className="textStylesWrapper stylingChanges">
            {textStylesData?.map((block, index) => {
                const isExpanded = expanded[index];
                return (
                    <CollapseRoot
                        key={index}
                        className={`container ${isExpanded ? "active" : ""}`}
                    >
                        <div className="collapseWrapper">
                            <span
                                onClick={() => toggleRow(index)}
                                className={`expand_section ${
                                    isExpanded ? "active" : ""
                                }`}
                            >
                                <Icon name={"arrowRight"} />
                            </span>
                            <Box className="flexBox justifyBetween flex1 alignCenter">
                                <span className="layoutName">{block.name}</span>
                                {isExpanded ? (
                                    <Chip
                                        className="applyAllLabel"
                                        label="Apply to all"
                                        onClick={() => {
                                            setApplyAllModal(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                            </Box>
                        </div>
                        <Collapse
                            in={isExpanded}
                            style={{ padding: 0 }}
                            className="collapseData"
                        >
                            <Box className="collapseContentWrapper">
                                <Box className="fontFamilyOption">
                                    <Box className="fontLabel">Font</Box>
                                    <Box className="fontsSelect">
                                        <FontSelect
                                            selectedFont={selectedFont}
                                            setSelectedFont={setSelectedFont}
                                            selectedFontWeight={
                                                selectedFontWeight
                                            }
                                            setSelectedFontWeight={
                                                setSelectedFontWeight
                                            }
                                            setDependencyChanged={
                                                setDependencyChanged
                                            }
                                        />
                                        <Box className="fontSizeOption">
                                            <LineHeight
                                                setLineHeight={setLineHeight}
                                                lineHeight={lineHeight}
                                                setDependencyChanged={
                                                    setDependencyChanged
                                                }
                                            />
                                            <FontSize
                                                setFontSize={setFontSize}
                                                fontSize={fontSize}
                                                setDependencyChanged={
                                                    setDependencyChanged
                                                }
                                            />
                                        </Box>
                                        <LetterSpacing
                                            setLetterSpacing={setLetterSpacing}
                                            letterSpacing={letterSpacing}
                                            setDependencyChanged={
                                                setDependencyChanged
                                            }
                                        />
                                    </Box>
                                </Box>
                                <Box className="alignmentStyles flexBox alignCenter justifyBetween">
                                    <Box className="fontLabel">
                                        Text Alignment
                                    </Box>
                                    <Box className="flexBox alignCenter">
                                        <Icon
                                            id="left-align"
                                            name="leftAlignIcon"
                                            className={`styleOption ${
                                                activeAlignment === "left"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                handleAlignment("left");
                                                setDependencyChanged(true);
                                            }}
                                        />
                                        <Icon
                                            id="center-align"
                                            name="centerAlignIcon"
                                            className={`styleOption ${
                                                activeAlignment === "center"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                handleAlignment("center");
                                                setDependencyChanged(true);
                                            }}
                                        />
                                        <Icon
                                            id="right-align"
                                            name="rightAlignIcon"
                                            className={`styleOption ${
                                                activeAlignment === "right"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                handleAlignment("right");
                                                setDependencyChanged(true);
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box className="fontStylesOption flexBox alignCenter justifyBetween">
                                    <Box className="fontLabel">Text Styles</Box>
                                    <Box className="flexBox alignCenter">
                                        {/* <Icon
                                            className={`styleOption ${
                                                boldFont ? "active" : ""
                                            }`}
                                            name="boldIcon"
                                            onClick={() => {
                                                setBoldFont(!boldFont);
                                                setDependencyChanged(true);
                                            }}
                                        /> */}
                                        <CustomTooltip title="Uppercase">
                                            <Box
                                                className={`styleOption ${
                                                    upperCaseText
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <Icon
                                                    name="allCapsIcon"
                                                    onClick={() => {
                                                        setUpperCaseText(
                                                            !upperCaseText
                                                        );
                                                        setDependencyChanged(
                                                            true
                                                        );
                                                    }}
                                                />
                                            </Box>
                                        </CustomTooltip>
                                        <CustomTooltip title="Italic">
                                            <Box
                                                className={`styleOption ${
                                                    italicFont ? "active" : ""
                                                }`}
                                            >
                                                <Icon
                                                    name="italicIcon"
                                                    onClick={() => {
                                                        setItalicFont(
                                                            !italicFont
                                                        );
                                                        setDependencyChanged(
                                                            true
                                                        );
                                                    }}
                                                />
                                            </Box>
                                        </CustomTooltip>
                                        <CustomTooltip title="Color">
                                            <Box sx={{ ml: 1 }}>
                                                <ColorInput
                                                    value={textColor}
                                                    setValue={setTextColor}
                                                    setDependencyChanged={
                                                        setDependencyChanged
                                                    }
                                                />
                                            </Box>
                                        </CustomTooltip>
                                    </Box>
                                </Box>
                            </Box>
                        </Collapse>
                    </CollapseRoot>
                );
            })}
            <ConfirmAction
                open={applyAllModal}
                button1Text="Apply to All"
                actionConfirm={handleApplyAll}
                actionCancel={() => setApplyAllModal(false)}
                icon="warning"
                dialogText={`Apply changes to all ${dataBlock}`}
                note={<InfoMessage />}
            />
        </Root>
    );
};
export default TextStyles;
