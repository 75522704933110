import { useEffect, useState } from "react";
import Icon from "../Icon";
import { Root } from "./style";
import { Box } from "@mui/system";
import { saveImage } from "../../../utils/mediaHelper";
import { usePrinterContext } from "../../../context/printer";
import { useAlertContext } from "../../../context/alert";
import { CircularProgress } from "@mui/material";

export const ImageUpload = ({
    logo,
    setLogo,
    setDependencyChanged,
    setUrl
}) => {
    const { menu } = usePrinterContext();

    const { setAlertState, setAlert } = useAlertContext();

    const [file, setFile] = useState(null);
    const [deleteImage, setDeleteImage] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [imageError, setImageError] = useState("");

    useEffect(() => {
        let successUpload = true,
            logo_url = "";

        const updateData = async () => {
            if (file) {
                setImageLoading(true);
                [successUpload, logo_url] = await saveImage(
                    file,
                    menu?.identifier,
                    "logos"
                );
            } else if (!deleteImage) {
                logo_url = logo || "";
            }

            if (deleteImage) {
                logo_url = "";
            }

            if (!successUpload) {
                logo_url = "";
                setAlert({
                    type: "error",
                    message:
                        "There was a problem while trying to save your image, please try again in a few seconds"
                });
                setAlertState(true);
                setImageLoading(false);
                return;
            }
            setFile(null);
            setUrl(logo_url);
            setImageLoading(false);
        };

        const startsWithHttps = /^https:\/\//.test(logo);
        if (!startsWithHttps) {
            updateData();
        } else {
            setUrl(logo);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logo]);

    const handleImageChange = (e) => {
        if (e.target.files[0]?.size) {
            if (e.target.files[0].size > 5000000) {
                setImageError(
                    "*Your file was too large to upload. The maximum file size is 5 MB."
                );
                setLogo("");
                setFile(null);
                e.target.value = null;
                return;
            }
            if (
                !["image/png", "image/jpg", "image/jpeg"].includes(
                    e.target.files[0].type
                )
            ) {
                setImageError(
                    "*Unsupported file type. Please upload only a JPG or PNG file."
                );
                setLogo("");
                setFile(null);
                e.target.value = null;
                return;
            } else {
                setDependencyChanged(true);
                setImageError("");
                setFile(e.target.files[0]);
                setDeleteImage(false);
                const url = URL.createObjectURL(e.target.files[0]);
                setLogo(url);
                e.target.value = null;
            }
        }
    };

    return (
        <Root sx={{ mb: 3 }} className="imageUpload">
            <Box className="imageContainer flexBox justifyCenter alignCenter columnDirection">
                {imageLoading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <input
                            type="file"
                            title=""
                            className="fileInput"
                            onChange={handleImageChange}
                        />

                        <Icon
                            name="remove"
                            className="deleteImage"
                            style={
                                logo
                                    ? {}
                                    : {
                                          display: "none"
                                      }
                            }
                            onClick={() => {
                                setLogo("");
                                setDeleteImage(true);
                                setFile(null);
                                setDependencyChanged(true);
                            }}
                        />
                        {logo && (
                            <img
                                className="image"
                                src={logo}
                                alt="Custom File"
                            />
                        )}
                        {!logo && (
                            <>
                                <Icon name="noImage" />
                                <p className="noImageText">
                                    Upload or Drag Image. JPG, PNG 5 MB max.
                                </p>
                            </>
                        )}
                    </>
                )}
            </Box>
            <span className="imageError">{imageError}</span>
        </Root>
    );
};
