import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Icon from "../../../../../../UI/Icon";
import { ImageCardStyles } from "../../../../../../UI/CommonStyles";
import {
    ColorInput,
    FontSelect,
    FontSize,
    FormSwitch,
    ImageCard,
    LetterSpacing,
    LineHeight,
    NumberCount
} from "../../../../../../UI/CommonComponents";
import { usePrinterContext } from "../../../../../../../context/printer";
import { Fonts } from "../../../../../../../const/font";
import { cloneDeep, isEqual } from "lodash";
import CustomTooltip from "../../../../../../UI/CustomTooltip/CustomTooltip";

const sectionTitle = [
    {
        label: "No Divider",
        divider: "",
        iconName: "noDivider"
    },
    {
        label: "Bottom Divider",
        divider: "bottom",
        iconName: "bottomDivider"
    },
    {
        label: "Side Divider",
        divider: "side",
        iconName: "sideDivider"
    }
];

export default function SectionTitle({ data }) {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray
    } = usePrinterContext();
    const [dependencyChanged, setDependencyChanged] = useState(false);

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex];

    const selectedFontName = Fonts.find(
        (font) =>
            font.name === blockToUpdate?.data?.styles?.sectionTitle?.fontFamily
    );

    const [divider, setDivider] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.divider
    );
    const [displayTitle, setDisplayTitle] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.displayTitle
    );
    const [lineHeight, setLineHeight] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.lineHeight
    );
    const [fontSize, setFontSize] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.fontSize
    );
    const [letterSpacing, setLetterSpacing] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.letterSpacing
    );
    const [upperCaseText, setUpperCaseText] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.upperCase
    );
    const [textColor, setTextColor] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.color
    );
    const [bgColor, setBGColor] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.bgColor
    );
    const [dividerColor, setDividerColor] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.dividerColor
    );
    const [dividerThickness, setDividerThickness] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.dividerThickness
    );
    const [activeAlignment, setActiveAlignment] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.textAlign
    );
    const [boldFont, setBoldFont] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.boldFont === "bold"
            ? true
            : false
    );
    const [italicFont, setItalicFont] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.fontStyle === "normal"
            ? false
            : true
    );
    const [selectedFont, setSelectedFont] = useState(selectedFontName);
    const [selectedFontWeight, setSelectedFontWeight] = useState(
        blockToUpdate?.data?.styles?.sectionTitle?.fontWeight
    );

    useEffect(() => {
        if (undoRedoState?.state && isEqual(data, sectionBlock.data)) {
            return;
        }

        setDivider(blockToUpdate?.data?.styles?.sectionTitle?.divider);
        setLineHeight(blockToUpdate?.data?.styles?.sectionTitle?.lineHeight);
        setFontSize(blockToUpdate?.data?.styles?.sectionTitle?.fontSize);
        setLetterSpacing(
            blockToUpdate?.data?.styles?.sectionTitle?.letterSpacing
        );
        setUpperCaseText(blockToUpdate?.data?.styles?.sectionTitle?.upperCase);
        setTextColor(blockToUpdate?.data?.styles?.sectionTitle?.color);
        setDividerColor(
            blockToUpdate?.data?.styles?.sectionTitle?.dividerColor
        );
        setDividerThickness(
            blockToUpdate?.data?.styles?.sectionTitle?.dividerThickness
        );
        setBGColor(blockToUpdate?.data?.styles?.sectionTitle?.bgColor);
        setDisplayTitle(
            blockToUpdate?.data?.styles?.sectionTitle?.displayTitle
        );
        setActiveAlignment(
            blockToUpdate?.data?.styles?.sectionTitle?.textAlign
        );
        setSelectedFont(selectedFontName);
        setSelectedFontWeight(
            blockToUpdate?.data?.styles?.sectionTitle?.fontWeight
        );
        setBoldFont(
            blockToUpdate?.data?.styles?.sectionTitle?.boldFont === "bold"
                ? true
                : false
        );
        setItalicFont(
            blockToUpdate?.data?.styles?.sectionTitle?.fontStyle === "normal"
                ? false
                : true
        );

        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.pageIndex, data.columnIndex, data.blockIndex, undoRedoState]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            divider,
            lineHeight,
            fontSize,
            color: textColor,
            textAlign: activeAlignment,
            fontWeight: selectedFontWeight,
            boldFont: boldFont ? "bold" : "normal",
            fontStyle: italicFont ? "italic" : "normal",
            fontFamily: selectedFont?.name,
            displayTitle,
            letterSpacing,
            upperCase: upperCaseText,
            dividerColor,
            dividerThickness,
            bgColor
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex];

        if (blockToUpdate) {
            blockToUpdate.data.styles.sectionTitle = {
                ...blockToUpdate.data.styles.sectionTitle,
                ...newStyles
            };

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }

        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dividerThickness,
        dividerColor,
        bgColor,
        upperCaseText,
        divider,
        lineHeight,
        fontSize,
        textColor,
        activeAlignment,
        boldFont,
        italicFont,
        selectedFont,
        selectedFontWeight,
        displayTitle,
        letterSpacing
    ]);

    const handleAlignment = (alignment) => {
        setDependencyChanged(true);
        setActiveAlignment(alignment);
    };

    const handleOnClick = (label) => {
        setDependencyChanged(true);
        setDivider(label);
    };

    return (
        <div className="collapseContentWrapper sectionTitle">
            <Box className="displayTitle">
                <FormSwitch
                    value={displayTitle}
                    setValue={setDisplayTitle}
                    label="Display Section Title"
                    setDependencyChanged={setDependencyChanged}
                />
            </Box>
            <Box className="fontLabel">Title Styles</Box>
            <ImageCardStyles className="imageCardWrap hasThreeCol mb-0">
                {sectionTitle.map((item, index) => (
                    <ImageCard
                        key={index}
                        icon={item.iconName}
                        label={item.label}
                        onClick={() => handleOnClick(item.divider)}
                        isActive={divider === item.divider}
                    />
                ))}
            </ImageCardStyles>
            <Box sx={{ mb: 3 }}>
                <Box className="flexBox alignCenter justifyEnd" sx={{ mb: 1 }}>
                    <Box className="fontM mb-0">Divider Thickness / Color</Box>
                    <CustomTooltip title="Divider Thickness">
                        <Box className="dividerThickness">
                            <NumberCount
                                setNumberCount={setDividerThickness}
                                numberCount={dividerThickness}
                                setDependencyChanged={setDependencyChanged}
                            />
                        </Box>
                    </CustomTooltip>
                    <CustomTooltip title="Divider Color">
                        <Box sx={{ ml: 1 }}>
                            <ColorInput
                                value={dividerColor}
                                setValue={setDividerColor}
                                setDependencyChanged={setDependencyChanged}
                            />
                        </Box>
                    </CustomTooltip>
                </Box>
                <Box className="flexBox alignCenter justifyEnd">
                    <Box className="fontM mb-0" sx={{ mr: 1 }}>
                        Background Color
                    </Box>
                    <Box className="flexBox alignCenter colorPickerContainer">
                        {bgColor !== "#ffffff" ? (
                            <CustomTooltip title="No Background Color">
                                <Box className="flexBox">
                                    <Icon
                                        onClick={(e) => {
                                            setBGColor("#ffffff");
                                            setDependencyChanged(true);
                                        }}
                                        className="cancelIcon"
                                        name="cancelIcon"
                                    />
                                </Box>
                            </CustomTooltip>
                        ) : (
                            ""
                        )}
                        <ColorInput
                            value={bgColor}
                            setValue={setBGColor}
                            setDependencyChanged={setDependencyChanged}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="fontFamilyOption">
                <Box className="fontLabel">Font</Box>
                <Box className="fontsSelect">
                    <FontSelect
                        selectedFont={selectedFont}
                        setSelectedFont={setSelectedFont}
                        selectedFontWeight={selectedFontWeight}
                        setSelectedFontWeight={setSelectedFontWeight}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <Box className="fontSizeOption">
                        <LineHeight
                            setLineHeight={setLineHeight}
                            lineHeight={lineHeight}
                            setDependencyChanged={setDependencyChanged}
                        />
                        <FontSize
                            setFontSize={setFontSize}
                            fontSize={fontSize}
                            setDependencyChanged={setDependencyChanged}
                        />
                    </Box>
                    <LetterSpacing
                        setLetterSpacing={setLetterSpacing}
                        letterSpacing={letterSpacing}
                        setDependencyChanged={setDependencyChanged}
                    />
                </Box>
            </Box>
            <Box className="alignmentStyles flexBox alignCenter justifyBetween">
                <Box className="fontLabel">Text Alignment</Box>
                <Box className="flexBox alignCenter">
                    <Icon
                        id="left-align"
                        name="leftAlignIcon"
                        className={`styleOption ${
                            activeAlignment === "left" ? "active" : ""
                        }`}
                        onClick={() => handleAlignment("left")}
                    />
                    <Icon
                        id="center-align"
                        name="centerAlignIcon"
                        className={`styleOption ${
                            activeAlignment === "center" ? "active" : ""
                        }`}
                        onClick={() => handleAlignment("center")}
                    />
                    <Icon
                        id="right-align"
                        name="rightAlignIcon"
                        className={`styleOption ${
                            activeAlignment === "right" ? "active" : ""
                        }`}
                        onClick={() => handleAlignment("right")}
                    />
                </Box>
            </Box>
            <Box className="fontStylesOption flexBox alignCenter justifyBetween">
                <Box className="fontLabel">Text Styles</Box>
                <Box className="flexBox alignCenter">
                    {/* <Icon
                        className={`styleOption ${boldFont ? "active" : ""}`}
                        name="boldIcon"
                        onClick={() => {
                            setBoldFont(!boldFont);
                            setDependencyChanged(true);
                        }}
                    /> */}
                    <CustomTooltip title="Uppercase">
                        <Box
                            className={`styleOption ${
                                upperCaseText ? "active" : ""
                            }`}
                        >
                            <Icon
                                name="allCapsIcon"
                                onClick={() => {
                                    setUpperCaseText(!upperCaseText);
                                    setDependencyChanged(true);
                                }}
                            />
                        </Box>
                    </CustomTooltip>
                    <CustomTooltip title="Italic">
                        <Box
                            className={`styleOption ${
                                italicFont ? "active" : ""
                            }`}
                        >
                            <Icon
                                name="italicIcon"
                                onClick={() => {
                                    setItalicFont(!italicFont);
                                    setDependencyChanged(true);
                                }}
                            />
                        </Box>
                    </CustomTooltip>
                    <CustomTooltip title="Color">
                        <Box sx={{ ml: 1 }}>
                            <ColorInput
                                value={textColor}
                                setValue={setTextColor}
                                setDependencyChanged={setDependencyChanged}
                            />
                        </Box>
                    </CustomTooltip>
                </Box>
            </Box>
        </div>
    );
}
