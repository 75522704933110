import _, { cloneDeep, isEmpty } from "lodash";
import IMenu from "./output/IMenu";
import { generateId } from "../utils/uuid";

class MenuAdapter {
    incomingData: any;
    defaultSectionStyles: any;

    constructor(incomingData: any) {
        this.incomingData = incomingData;
    }

    public setIncomingData(incomingData: any) {
        this.incomingData = incomingData;
        this.defaultSectionStyles = {};
    }

    private getItemStyles(sectionId: string, itemId: string) {
        const pagesContent = _.get(
            this.incomingData,
            "printer_template.pages_content",
            []
        );
        for (const page of pagesContent) {
            for (const row of page.row) {
                for (const column of row.column) {
                    for (const block of column.blocks) {
                        if (block.type === "section") {
                            const items = block?.data?.items?.items;
                            const blockId = block?.data?.items?._id;
                            const blockStyle = block?.data?.styles?.item;
                            if (items && !_.isEmpty(items)) {
                                for (const item of items) {
                                    if (blockId === sectionId) {
                                        if (item?._id === itemId) {
                                            return (
                                                item?.styles || blockStyle || {}
                                            );
                                        } else {
                                            this.defaultSectionStyles =
                                                blockStyle || {};
                                        }
                                    }
                                }
                            } else {
                                this.defaultSectionStyles = blockStyle || {};
                            }
                        }
                    }
                }
            }
        }
        return {};
    }

    public getFormatedData(): IMenu {
        const response: IMenu = {
            _id: _.get(this.incomingData, "_id", ""),
            identifier: _.get(this.incomingData, "identifier", "undefined"),
            name: _.get(this.incomingData, "internal_name", "No definido"),
            displayName: _.get(
                this.incomingData,
                "display_name",
                "No definido"
            ),
            internalName: _.get(
                this.incomingData,
                "internal_name",
                "No definido"
            ),
            headerText: _.get(this.incomingData, "header_text", ""),
            footerText: _.get(this.incomingData, "footer_text", ""),
            image: _.get(this.incomingData, "header_image", null),
            showNotations: _.get(this.incomingData, "show_notations", false),
            restaurant: _.get(this.incomingData, "identifier", "yolqueria"),
            templateConfigs: {
                template_id: _.get(
                    this.incomingData,
                    "printer_template._id",
                    1
                ),
                pages_content: _.get(
                    this.incomingData,
                    "printer_template.pages_content",
                    []
                ).map(
                    (content: {
                        page_no: number;
                        page_name: string;
                        row: object;
                        page_properties: object;
                    }) => ({
                        page_no: content.page_no,
                        page_name: content.page_name,
                        row: content.row,
                        page_properties: content.page_properties
                    })
                ),
                showItemPrice: _.get(
                    this.incomingData,
                    "printer_template.showItemPrice",
                    true
                ),
                showEmptyDecimals: _.get(
                    this.incomingData,
                    "printer_template.showEmptyDecimals",
                    false
                ),
                showCurrencySymbol: _.get(
                    this.incomingData,
                    "printer_template.showCurrencySymbol",
                    true
                )
            },
            sections: _.get(this.incomingData, "section_links", []).map(
                (section: any) => ({
                    _id: _.get(section, "section_id._id", ""),
                    printer_settings: {
                        item_spacing: (() =>
                            _.get(
                                section,
                                "section_id.printer_settings.item_spacing",
                                15
                            ))()
                    },
                    displayName: _.get(
                        section,
                        "section_id.display_name",
                        "Not Defined"
                    ),
                    description: _.get(section, "section_id.header_text", null),
                    items: _.get(section, "section_id.item_links", []).map(
                        (item: any) => {
                            const ID = generateId();
                            const sectionId = _.get(
                                section,
                                "section_id._id",
                                ""
                            );
                            const itemId = _.get(item, "item_id._id", "");
                            const styles = this.getItemStyles(
                                sectionId,
                                itemId
                            );
                            return {
                                styles: isEmpty(styles)
                                    ? cloneDeep(this.defaultSectionStyles) || {}
                                    : cloneDeep(styles),
                                status: _.get(item, "item_id.status", ""),
                                id: ID,
                                _id: _.get(item, "item_id._id", ""),
                                name: _.get(
                                    item,
                                    "item_id.display_name",
                                    "Not Defined"
                                ),
                                description: _.get(
                                    item,
                                    "item_id.description",
                                    null
                                ),
                                priceType: _.get(
                                    item,
                                    "item_id.price_type",
                                    null
                                ),
                                type: _.get(item, "item_id.type", "item"),
                                price: (() => {
                                    const prices = _.get(
                                        item,
                                        "item_id.price_variations",
                                        []
                                    );
                                    if (prices && prices.length) {
                                        return prices[0].price;
                                    }
                                    return null;
                                })(),
                                prices: _.get(
                                    item,
                                    "item_id.price_variations",
                                    []
                                ).map(
                                    (price: {
                                        price_value: number;
                                        leading_text: string;
                                        trailing_text: string;
                                    }) => ({
                                        priceValue: price.price_value,
                                        leadingText: price.leading_text,
                                        trailingText: price.trailing_text
                                    })
                                ),
                                notations: _.get(
                                    item,
                                    "item_id.notation_links",
                                    []
                                ).map(
                                    (notation: {
                                        notation_id: {
                                            _id: string;
                                            display_name: string;
                                            type: string;
                                        };
                                    }) => ({
                                        _id: _.get(
                                            notation,
                                            "notation_id._id",
                                            "undefined"
                                        ),
                                        display_name: _.get(
                                            notation,
                                            "notation_id.display_name",
                                            "undefined"
                                        ),
                                        type: _.get(
                                            notation,
                                            "notation_id.type",
                                            "undefined"
                                        )
                                    })
                                )
                            };
                        }
                    )
                })
            ),
            created_at: _.get(this.incomingData, "created_at", null),
            updated_at: _.get(this.incomingData, "updated_at", null)
        };
        return response;
    }
}

export default MenuAdapter;
