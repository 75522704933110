import { useEffect, useState } from "react";
import { Root } from "../style";
import { usePrinterContext } from "../../../../../../context/printer";
import { RangeSlider } from "../../../../../UI/CommonComponents";
import { cloneDeep, isEqual } from "lodash";
import { inchesToPixels } from "../../../../../../utils/common";

const DividerStyles = ({ data }) => {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        layoutState,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray
    } = usePrinterContext();

    const { pageLayout } = layoutState;
    const paperSize = pageLayout.paperSize;
    const orientation = pageLayout.activeOrientation;

    const maxInPixels = inchesToPixels(
        (orientation === "portrait"
            ? paperSize.height
            : orientation === "landscape"
            ? paperSize.width
            : ""
        ).replace("in", ""),
        96
    );

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex];

    const [dividerHeight, setDividerHeight] = useState(
        blockToUpdate?.data?.height
    );
    const [dependencyChanged, setDependencyChanged] = useState(false);

    useEffect(() => {
        if (undoRedoState?.state && isEqual(data, sectionBlock.data)) {
            return;
        }

        setDividerHeight(blockToUpdate?.data?.height);
        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.pageIndex, data.columnIndex, data.blockIndex, undoRedoState]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            height: dividerHeight
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex];

        if (blockToUpdate) {
            blockToUpdate.data = newStyles;

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }
        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dividerHeight]);

    const handleHeightChange = (event, max) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = parsedValue < max ? parsedValue : max;
        if (typeof newValue === "number") {
            setDependencyChanged(true);
            setDividerHeight(newValue);
        }
    };

    return (
        <Root className="dividerBlockWrapper">
            <RangeSlider
                sliderLabel="Height"
                sliderVal={dividerHeight}
                handleSliderChange={(event) =>
                    handleHeightChange(event, maxInPixels)
                }
                min={0}
                max={maxInPixels}
                step={1}
            />
        </Root>
    );
};
export default DividerStyles;
