import { User } from "../../interfaces/user";
import { AuthState } from "./AuthProvider";

type AuthActionType =
    | { type: "[Auth] - Login"; payload: User }
    | { type: "[Auth] - Sing Up"; payload: User }
    | { type: "[Auth] - Logout" };

export const authReducer = (state: AuthState, action: AuthActionType) => {
    switch (action.type) {
        case "[Auth] - Login":
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload
            };
        default:
            return state;
    }
};
