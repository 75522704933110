export const customEvents = (
    pageIndex,
    rowIndex,
    columnIndex,
    blockIndex,
    blockType
) => {
    const sendStylesEvent = new CustomEvent("sendStylesEvent", {
        detail: {
            pageIndex,
            rowIndex,
            columnIndex,
            blockIndex,
            blockType
        },
        bubbles: true,
        cancelable: true,
        composed: false
    });
    window.dispatchEvent(sendStylesEvent);
};

export const customRowEvents = (pageIndex, rowIndex, blockType) => {
    const sendRowStylesEvent = new CustomEvent("sendRowStylesEvent", {
        detail: {
            pageIndex,
            rowIndex,
            blockType
        },
        bubbles: true,
        cancelable: true,
        composed: false
    });
    window.dispatchEvent(sendRowStylesEvent);
};

export const customPageEvents = (pageIndex) => {
    const sendPageStylesEvent = new CustomEvent("sendPageStylesEvent", {
        detail: {
            pageIndex
        },
        bubbles: true,
        cancelable: true,
        composed: false
    });
    window.dispatchEvent(sendPageStylesEvent);
};

export const customItemEvents = (
    pageIndex,
    rowIndex,
    columnIndex,
    blockIndex,
    blockType,
    itemIndex
) => {
    const sendPageStylesEvent = new CustomEvent("sendItemStylesEvent", {
        detail: {
            pageIndex,
            rowIndex,
            columnIndex,
            blockIndex,
            blockType,
            itemIndex
        },
        bubbles: true,
        cancelable: true,
        composed: false
    });
    window.dispatchEvent(sendPageStylesEvent);
};
