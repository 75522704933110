import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
const Root = styled(Box)(() => ({
    "&.itemsStylesBlock": {
        "& .borderCorners": {
            marginBottom: 10
        },
        "& .paperBorder": {
            "& .input-select .MuiSelect-select": {
                minHeight: 30,
                paddingTop: 4,
                paddingBottom: 4
            },
            "& .fontTextField": {
                marginLeft: "16px"
            },
            "& .styleOption": {
                marginLeft: "16px"
            }
        },
        "& .collapseContentWrapper": {
            marginTop: 20,
            "& .fontLabel": {
                marginBottom: 10
            }
        },
        "& .itemLayout": {
            "& .fontLabel, & .MuiFormControlLabel-label": {
                fontSize: 14
            }
        },
        "& .imageCardWrap": {
            marginBottom: 25,
            "& + .switchContainer": {
                marginTop: "0 !important"
            }
        }
    },
    "& .itemLayout": {
        "& .imageCardWrap": {
            "& .imageCardBox": {
                "& .imageCardItem": {
                    "& > svg.active": {
                        "& rect": {
                            "&:nth-of-type(2)": {
                                fill: "#FAD3B8"
                            }
                        }
                    }
                }
            }
        }
    },
    "& .notationBlock": {
        "& .numberCount": {
            margin: 0
        }
    },
    "& .dividerThickness": {
        "& .fontTextField": {
            width: "56px"
        }
    },
    "& .fixed-width": {
        "& .mediumFont": {
            width: 50,
            textAlign: "center"
        }
    }
}));

export { Root };
