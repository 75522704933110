import { Box } from "@mui/system";
import TextBlock from "./EditorBlock/TextBlock";
import ImageBlock from "./EditorBlock/ImageBlock";
import DividerBlock from "./EditorBlock/DividerBlock";
import SectionBlock from "./EditorBlock/SectionBlock";
import BlockData from "../../../const/BlockData";
import EditableTextBlock from "./EditorBlock/EditableTextBlock";
import { Draggable } from "react-beautiful-dnd";
import Icon from "../../UI/Icon";
import { draggedStyle, useDraggableInPortal } from "../../../utils/draggingFun";
import { usePrinterContext } from "../../../context/printer";
import { SIDEBAR_TAB_INDEX } from "../../../const/general";

export default function SortableItem({
    pageIndex,
    rowIndex,
    columnIndex,
    blockIndex,
    block,
    id,
    handleBlockClick,
    customEvents,
    focusId,
    setFocusId,
    removeBlock,
    zoomLevel,
    setCountImageLoaded
}) {
    const handleLoadImage = () => {
        setCountImageLoaded && setCountImageLoaded((prev) => prev + 1);
    };

    const { setTabIndex } = usePrinterContext();

    const { BLOCK_SUB_TYPE } = BlockData();
    const { STYLES } = SIDEBAR_TAB_INDEX;

    const getItemStyle = (snapshot, provided) => {
        const {
            draggableStyle,
            isDragging,
            translateString,
            widthPos,
            heightPos,
            leftPos,
            topPos
        } = draggedStyle(snapshot, provided, zoomLevel);

        const scale = zoomLevel / 10;

        return {
            ...draggableStyle,
            top: isDragging ? topPos : draggableStyle.top,
            left: isDragging ? leftPos : draggableStyle.left,
            width: isDragging ? widthPos : draggableStyle.width,
            height: isDragging ? heightPos : draggableStyle.height,
            backgroundColor: isDragging ? "#fff" : "",
            transform: isDragging
                ? `${translateString} scale(${scale})`
                : translateString
        };
    };

    const renderDraggable = useDraggableInPortal();

    return (
        <Draggable draggableId={block.id} index={blockIndex} key={block.id}>
            {renderDraggable((provided, snapshot) => (
                <Box
                    className={`contentBlock ${
                        block?.type === BLOCK_SUB_TYPE.SECTION
                            ? "sectionWrapper"
                            : ""
                    }`}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleBlockClick(id);
                        customEvents(
                            pageIndex,
                            rowIndex,
                            columnIndex,
                            blockIndex,
                            block.blockType
                        );
                        // Switch To Styles Tab
                        setTabIndex(STYLES);
                    }}
                    onLoad={handleLoadImage}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    id={block.id}
                    style={getItemStyle(snapshot, provided)}
                >
                    {(() => {
                        const FocusableBlock = (props) => {
                            const itemArray = focusId.split(",");
                            const itemLength = itemArray.length;
                            return (
                                <Box
                                    className={`focusableBlock hide_on_print ${
                                        focusId === id ? "active" : ""
                                    }`}
                                    style={{
                                        zIndex:
                                            itemLength === 5
                                                ? -1
                                                : focusId !== id
                                                ? 0
                                                : ""
                                    }}
                                >
                                    <Box className={`editBlock`}>
                                        {block?.data?.editable
                                            ? props.children
                                            : ""}

                                        <span {...provided.dragHandleProps}>
                                            <Icon
                                                className="sortIcon"
                                                name="sort"
                                            />
                                        </span>
                                        <Icon
                                            className="deleteIcon"
                                            name="deleteIcon"
                                            onClick={() =>
                                                removeBlock(
                                                    rowIndex,
                                                    columnIndex,
                                                    blockIndex,
                                                    pageIndex
                                                )
                                            }
                                        />
                                    </Box>
                                </Box>
                            );
                        };

                        switch (block.type) {
                            case BLOCK_SUB_TYPE.EDITABLE_TEXT:
                                return (
                                    <EditableTextBlock
                                        {...block.data}
                                        id={id}
                                        focusId={focusId}
                                        onRemove={() =>
                                            removeBlock(
                                                rowIndex,
                                                columnIndex,
                                                blockIndex,
                                                pageIndex
                                            )
                                        }
                                        focusableBlock={FocusableBlock}
                                    />
                                );
                            case BLOCK_SUB_TYPE.TEXT:
                                return (
                                    <TextBlock
                                        {...block.data}
                                        id={id}
                                        focusId={focusId}
                                        onRemove={() =>
                                            removeBlock(
                                                rowIndex,
                                                columnIndex,
                                                blockIndex,
                                                pageIndex
                                            )
                                        }
                                        focusableBlock={FocusableBlock}
                                    />
                                );
                            case BLOCK_SUB_TYPE.IMAGE:
                                return (
                                    <ImageBlock
                                        {...block.data}
                                        id={id}
                                        focusId={focusId}
                                        onRemove={() =>
                                            removeBlock(
                                                rowIndex,
                                                columnIndex,
                                                blockIndex,
                                                pageIndex
                                            )
                                        }
                                        focusableBlock={FocusableBlock}
                                    />
                                );
                            case BLOCK_SUB_TYPE.DIVIDER:
                                return (
                                    <DividerBlock
                                        {...block.data}
                                        id={id}
                                        focusId={focusId}
                                        onRemove={() =>
                                            removeBlock(
                                                rowIndex,
                                                columnIndex,
                                                blockIndex,
                                                pageIndex
                                            )
                                        }
                                        focusableBlock={FocusableBlock}
                                    />
                                );
                            case BLOCK_SUB_TYPE.SECTION:
                                return (
                                    <SectionBlock
                                        {...block.data}
                                        id={id}
                                        focusId={focusId}
                                        setFocusId={setFocusId}
                                        onRemove={() =>
                                            removeBlock(
                                                rowIndex,
                                                columnIndex,
                                                blockIndex,
                                                pageIndex
                                            )
                                        }
                                        type={block.id}
                                        focusableBlock={FocusableBlock}
                                        pageIndex={pageIndex}
                                        rowIndex={rowIndex}
                                        columnIndex={columnIndex}
                                        blockIndex={blockIndex}
                                    />
                                );

                            default:
                                return null;
                        }
                    })()}
                    {provided.placeholder}
                </Box>
            ))}
        </Draggable>
    );
}
