import { Modal } from "@mui/material";

import { Root } from "./styles";
import Icon from "../UI/Icon";

const CustomPopup = ({
    open = true,
    dialogText,
    note = "",
    actionCancel,
    children = null
}) => {
    return (
        <Modal open={open} onClose={actionCancel} disablePortal>
            <Root className="modal">
                <span className="sectionClose">
                    <span
                        className="closeButtonContainer"
                        onClick={actionCancel}
                    >
                        <Icon name="clear" className="closeButton" />
                    </span>
                </span>
                {children && <div className="dialogBlock">{children}</div>}
                <p className="message">{dialogText}</p>
                {typeof note === "string" && <p className="infoText">{note}</p>}
            </Root>
        </Modal>
    );
};

export default CustomPopup;
