import { Root } from "../style";
import { Root as SectionRoot } from "./style";
import { Box } from "@mui/system";
import { usePrinterContext } from "../../../../../context/printer";
import SortableItem from "./SortableItem";
import { Droppable } from "react-beautiful-dnd";
import { isBlobUrl } from "../../../../../utils/common";
import { loadImage } from "../../../../../utils/loadImage";
import { useEffect, useState } from "react";

const SectionBlock = ({
    items,
    styles,
    focusId,
    setFocusId,
    id,
    type,
    focusableBlock: FocusableBlock,
    pageIndex,
    rowIndex,
    columnIndex,
    blockIndex,
    mirrorMode = false
}) => {
    const { menu, restaurant, zoomLevel } = usePrinterContext();

    const {
        sectionLayout,
        sectionTitle,
        sectionHeader,
        item,
        subSectionTitle,
        background,
        border
    } = { ...styles };

    const subSectionTitleTopSpace = subSectionTitle?.sectionSpace;
    const columnCount = sectionLayout?.column;

    const itemName = item?.itemName;
    const itemDescription = item?.itemDescription;
    const itemPrice = item?.itemPrice;
    const itemLayout = item?.itemLayout;

    const sectionTitleStyle = {
        fontFamily: sectionTitle?.fontFamily,
        fontWeight: sectionTitle?.fontWeight,
        fontSize: sectionTitle?.fontSize ? `${sectionTitle?.fontSize}px` : "",
        lineHeight: sectionTitle?.lineHeight
            ? `${sectionTitle?.lineHeight}px`
            : "",
        textAlign: sectionTitle?.textAlign,
        fontStyle: sectionTitle?.fontStyle,
        color: sectionTitle?.color,
        letterSpacing: `${
            sectionTitle?.letterSpacing === 0
                ? 0
                : sectionTitle?.letterSpacing / 10
        }px`,
        textTransform: sectionTitle?.upperCase ? "uppercase" : "none"
    };

    const sectionHeaderStyle = {
        fontFamily: sectionHeader?.fontFamily,
        fontWeight: sectionHeader?.fontWeight,
        fontSize: sectionHeader?.fontSize ? `${sectionHeader?.fontSize}px` : "",
        lineHeight: sectionHeader?.lineHeight
            ? `${sectionHeader?.lineHeight}px`
            : "",
        textAlign: sectionHeader?.textAlign,
        fontStyle: sectionHeader?.fontStyle,
        color: sectionHeader?.color,
        letterSpacing: `${
            sectionHeader?.letterSpacing === 0
                ? 0
                : sectionHeader?.letterSpacing / 10
        }px`,
        textTransform: sectionHeader?.upperCase ? "uppercase" : "none",
        paddingTop: sectionHeader?.headerSpace
    };

    const subSectionTitleStyle = {
        fontFamily: subSectionTitle?.fontFamily,
        fontWeight: subSectionTitle?.fontWeight,
        fontSize: subSectionTitle?.fontSize
            ? `${subSectionTitle?.fontSize}px`
            : "",
        lineHeight: subSectionTitle?.lineHeight
            ? `${subSectionTitle?.lineHeight}px`
            : "",
        textAlign: subSectionTitle?.textAlign,
        fontStyle: subSectionTitle?.fontStyle,
        color: subSectionTitle?.color,
        letterSpacing: `${
            subSectionTitle?.letterSpacing === 0
                ? 0
                : subSectionTitle?.letterSpacing / 10
        }px`,
        textTransform: subSectionTitle?.upperCase ? "uppercase" : "none"
    };

    const itemNameStyle = {
        fontFamily: itemName?.fontFamily,
        fontWeight: itemName?.fontWeight,
        fontSize: itemName?.fontSize ? `${itemName?.fontSize}px` : "",
        lineHeight: itemName?.lineHeight ? `${itemName?.lineHeight}px` : "",
        fontStyle: itemName?.fontStyle,
        color: itemName?.color,
        letterSpacing: `${
            itemName?.letterSpacing === 0 ? 0 : itemName?.letterSpacing / 10
        }px`,
        textTransform: itemName?.upperCase ? "uppercase" : "none",
        displayIndent: itemName?.displayIndent
    };

    const itemDescriptionStyle = {
        fontFamily: itemDescription?.fontFamily,
        fontWeight: itemDescription?.fontWeight,
        fontSize: itemDescription?.fontSize
            ? `${itemDescription?.fontSize}px`
            : "",
        lineHeight: itemDescription?.lineHeight
            ? `${itemDescription?.lineHeight}px`
            : "",
        fontStyle: itemDescription?.fontStyle,
        color: itemDescription?.color,
        letterSpacing: `${
            itemDescription?.letterSpacing === 0
                ? 0
                : itemDescription?.letterSpacing / 10
        }px`,
        textTransform: itemDescription?.upperCase ? "uppercase" : "none"
    };

    const itemPriceStyle = {
        fontFamily: itemPrice?.fontFamily,
        fontWeight: itemPrice?.fontWeight,
        fontSize: itemPrice?.fontSize ? `${itemPrice?.fontSize}px` : "",
        lineHeight: itemPrice?.lineHeight ? `${itemPrice?.lineHeight}px` : "",
        fontStyle: itemPrice?.fontStyle,
        color: itemPrice?.color,
        letterSpacing: `${
            itemPrice?.letterSpacing === 0 ? 0 : itemPrice?.letterSpacing / 10
        }px`,
        textTransform: itemPrice?.upperCase ? "uppercase" : "none"
    };

    const sectionHeaderText = items?.description?.replace(/\n/g, "<br>");
    const displayTitle = sectionTitle?.displayTitle;
    const dividerColor = sectionTitle?.dividerColor;
    const dividerThickness = sectionTitle?.dividerThickness;
    const bgColor =
        sectionTitle?.bgColor === "#ffffff"
            ? "transparent"
            : sectionTitle?.bgColor;

    const backgroundUrl = background?.url;

    const [bgImageSrc, setBgImageSrc] = useState(backgroundUrl);

    useEffect(() => {
        if (backgroundUrl) {
            if (!isBlobUrl(backgroundUrl)) {
                loadImage(
                    backgroundUrl,
                    (image) => {
                        setBgImageSrc(image);
                    },
                    (error) => {
                        console.log(error);
                        setBgImageSrc(backgroundUrl);
                    }
                );
            } else {
                setBgImageSrc(backgroundUrl);
            }
        } else {
            setBgImageSrc(backgroundUrl);
        }
    }, [backgroundUrl]);

    const backgroundSize = backgroundUrl ? background?.size : "";
    const backgroundCover = background?.cover;
    const backgroundPos = background?.location;
    const backgroundColor =
        background?.color !== "#ffffff" ? background?.color : "transparent";
    const backgroundTrans = background?.transparency / 100;

    const borderStyle = border?.style;
    const borderTop = border?.position?.top;
    const borderRight = border?.position?.right;
    const borderBottom = border?.position?.bottom;
    const borderLeft = border?.position?.left;
    const borderWidth = border?.width;
    const borderColor = border?.color || "#000000";
    const borderTopRadius = border?.radius?.top;
    const borderLeftRadius = border?.radius?.left;
    const borderRightRadius = border?.radius?.right;
    const borderBottomRadius = border?.radius?.bottom;

    const topSpace = displayTitle ? sectionLayout.titleItemSpace : 0;

    const [itemFocusId, setItemFocusId] = useState();

    return (
        <>
            <Root
                className="blockWrapper sectionBlock"
                style={{
                    paddingTop: `${sectionLayout.topPadding}px`,
                    paddingBottom: `${sectionLayout.bottomPadding}px`,
                    paddingLeft: `${sectionLayout.leftPadding}px`,
                    paddingRight: `${sectionLayout.rightPadding}px`,
                    backgroundColor: backgroundColor,
                    borderStyle: borderStyle,
                    transition: "border 0.3s",
                    borderColor: borderColor,
                    borderTopWidth: borderTop ? borderWidth : 0,
                    borderRightWidth: borderRight ? borderWidth : 0,
                    borderBottomWidth: borderBottom ? borderWidth : 0,
                    borderLeftWidth: borderLeft ? borderWidth : 0,
                    borderTopLeftRadius: borderTopRadius,
                    borderTopRightRadius: borderRightRadius,
                    borderBottomRightRadius: borderBottomRadius,
                    borderBottomLeftRadius: borderLeftRadius
                }}
            >
                <Box
                    className="backgroundImage absoluteBlock"
                    style={{
                        backgroundImage: bgImageSrc ? `url(${bgImageSrc})` : "",
                        backgroundSize: bgImageSrc
                            ? backgroundCover
                                ? "cover"
                                : backgroundSize + "%"
                            : "",
                        backgroundPosition: bgImageSrc ? backgroundPos : "",
                        backgroundRepeat: bgImageSrc ? "no-repeat" : "",
                        opacity: backgroundTrans
                    }}
                ></Box>
                <SectionRoot
                    style={{
                        position: "relative",
                        zIndex: "2"
                    }}
                    className={focusId === id ? "hasFocus" : ""}
                >
                    {displayTitle && (
                        <Box
                            className={
                                sectionTitle.divider === "side"
                                    ? "flexBox alignCenter"
                                    : ""
                            }
                            style={{ backgroundColor: bgColor }}
                        >
                            {(sectionTitle.textAlign === "center" ||
                                sectionTitle.textAlign === "right") &&
                            sectionTitle.divider === "side" ? (
                                <hr
                                    style={{
                                        margin:
                                            sectionTitle.divider === "side"
                                                ? "0 12px 0 0"
                                                : "",
                                        borderColor: dividerColor,
                                        borderWidth: dividerThickness
                                    }}
                                />
                            ) : (
                                ""
                            )}

                            <Box
                                className="sectionName"
                                style={{
                                    ...sectionTitleStyle
                                }}
                            >
                                {items?.displayName}
                            </Box>
                            {sectionTitle.divider !== "" &&
                                !(
                                    sectionTitle.textAlign === "right" &&
                                    sectionTitle.divider === "side"
                                ) && (
                                    <hr
                                        style={{
                                            margin:
                                                sectionTitle.divider === "side"
                                                    ? "0 0 0 12px"
                                                    : "",
                                            borderColor: dividerColor,
                                            borderWidth: dividerThickness
                                        }}
                                    />
                                )}
                        </Box>
                    )}
                    {sectionHeaderText ? (
                        <Box
                            className="sectionHeader"
                            style={sectionHeaderStyle}
                            dangerouslySetInnerHTML={{
                                __html: sectionHeaderText
                            }}
                        />
                    ) : (
                        ""
                    )}
                    {!mirrorMode ? (
                        <Droppable
                            droppableId={`
                                ${type} + pageIndex-${pageIndex} + rowIndex-${rowIndex} + columnIndex-${columnIndex}
                                `}
                            type="blockItems"
                        >
                            {(provided, snapshot) => (
                                <Box
                                    ref={provided.innerRef}
                                    className="ItemsList"
                                    style={{
                                        gridTemplateColumns: `repeat(${sectionLayout.column}, minmax(0, 1fr))`,
                                        columnGap: `${sectionLayout.columnSpace}px`,
                                        rowGap: `${sectionLayout.itemSpace}px`,
                                        paddingTop: `${topSpace}px`
                                    }}
                                >
                                    {items?.items?.map((item, index) => {
                                        return (
                                            <SortableItem
                                                key={
                                                    item.name +
                                                    "item-list" +
                                                    index
                                                }
                                                item={item}
                                                menu={menu}
                                                restaurant={restaurant}
                                                zoomLevel={zoomLevel}
                                                allItemNameStyle={itemNameStyle}
                                                allItemDescriptionStyle={
                                                    itemDescriptionStyle
                                                }
                                                allItemPriceStyle={
                                                    itemPriceStyle
                                                }
                                                subSectionTitleStyle={
                                                    subSectionTitleStyle
                                                }
                                                columnCount={columnCount}
                                                allItemLayout={itemLayout}
                                                subSectionTitleTopSpace={
                                                    subSectionTitleTopSpace
                                                }
                                                index={index}
                                                mirrorMode={mirrorMode}
                                                pageIndex={pageIndex}
                                                rowIndex={rowIndex}
                                                blockIndex={blockIndex}
                                                columnIndex={columnIndex}
                                                itemFocusId={itemFocusId}
                                                setItemFocusId={setItemFocusId}
                                                setFocusId={setFocusId}
                                                focusId={focusId}
                                                itemId={[
                                                    pageIndex,
                                                    rowIndex,
                                                    columnIndex,
                                                    blockIndex,
                                                    index
                                                ].toString()}
                                            />
                                        );
                                    })}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                    ) : (
                        <Box
                            className="ItemsList"
                            style={{
                                gridTemplateColumns: `repeat(${sectionLayout.column}, minmax(0, 1fr))`,
                                columnGap: `${sectionLayout.columnSpace}px`,
                                rowGap: `${sectionLayout.itemSpace}px`,
                                paddingTop: `${topSpace}px`
                            }}
                        >
                            {items?.items?.map((item, index) => {
                                return (
                                    <SortableItem
                                        key={item.name + "item-list" + index}
                                        item={item}
                                        menu={menu}
                                        restaurant={restaurant}
                                        zoomLevel={zoomLevel}
                                        allItemNameStyle={itemNameStyle}
                                        allItemDescriptionStyle={
                                            itemDescriptionStyle
                                        }
                                        allItemPriceStyle={itemPriceStyle}
                                        subSectionTitleStyle={
                                            subSectionTitleStyle
                                        }
                                        columnCount={columnCount}
                                        allItemLayout={itemLayout}
                                        subSectionTitleTopSpace={
                                            subSectionTitleTopSpace
                                        }
                                        index={index}
                                        mirrorMode={mirrorMode}
                                    />
                                );
                            })}
                        </Box>
                    )}
                </SectionRoot>
            </Root>
            {FocusableBlock && <FocusableBlock></FocusableBlock>}
        </>
    );
};
export default SectionBlock;
