import { useCallback, useState } from "react";
import { Root } from "../style";
import { Root as SectionRoot } from "./style";
import Icon from "../../../../../UI/Icon";
import { Box } from "@mui/system";
import { Chip, Collapse } from "@mui/material";
import { CollapseRoot } from "../../../../../UI/CommonStyles";
import ItemLayout from "./ItemBlocks/ItemLayout";
import ItemNames from "./ItemBlocks/ItemNames";
import ItemsDescription from "./ItemBlocks/ItemsDescription";
import ItemsPrices from "./ItemBlocks/ItemsPrices";
import { usePrinterContext } from "../../../../../../context/printer";
import ConfirmAction from "../../../../../Modals";
import PaperBorder from "./ItemBlocks/PaperBorder";
import PaperBackground from "./ItemBlocks/PaperBackground";
import ItemPadding from "./ItemBlocks/ItemPadding";
import BlockData from "../../../../../../const/BlockData";

const itemsStylesData = [
    { name: "Item Layout", dataId: "itemLayout" },
    { name: "Padding", dataId: "padding" },
    { name: "Border", dataId: "border" },
    { name: "Background", dataId: "background" },
    { name: "Item Name", dataId: "itemName" },
    { name: "Item Description", dataId: "itemDescription" },
    { name: "Item Price", dataId: "itemPrice" }
];

const ItemsStyles = ({ data }) => {
    const { pageBlocks, setPageBlocks } = usePrinterContext();

    const [expanded, setExpanded] = useState(false);
    const [applyAllModal, setApplyAllModal] = useState(false);
    const [applyToggle, setApplyToggle] = useState(false);
    const [applyTo, setApplyTo] = useState("");
    const [styleBlock, setStyleBlock] = useState({});

    const { BLOCKTYPE } = BlockData();

    const toggleApplyTo = useCallback((index) => {
        setApplyToggle((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }, []);

    const toggleRow = useCallback((index) => {
        setExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }, []);

    const handleApplyAll = () => {
        if (applyTo) {
            const blockId = styleBlock.dataId;
            const updatedPageBlocks = [...pageBlocks];
            const blockToUpdate =
                updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                    data.columnIndex
                ]?.blocks[data.blockIndex]?.data?.items?.items[data?.itemIndex]
                    ?.styles[blockId];
            if (blockToUpdate) {
                if (applyTo === "section") {
                    updatedPageBlocks[data.pageIndex]?.row[
                        data?.rowIndex
                    ]?.column[data.columnIndex]?.blocks[
                        data.blockIndex
                    ].data?.items?.items.forEach((item) => {
                        item.styles[blockId] = {
                            ...blockToUpdate
                        };
                    });

                    // Update Section items default style to section
                    if (
                        updatedPageBlocks[data.pageIndex]?.row?.[data.rowIndex]
                            ?.column?.[data.columnIndex]?.blocks?.[
                            data.blockIndex
                        ]?.data?.styles?.item
                    ) {
                        updatedPageBlocks[data.pageIndex].row[
                            data.rowIndex
                        ].column[data.columnIndex].blocks[
                            data.blockIndex
                        ].data.styles.item[blockId] = {
                            ...blockToUpdate
                        };
                    }
                } else if (applyTo === "menu") {
                    updatedPageBlocks.forEach((pageBlock) => {
                        pageBlock.row.forEach((rowBlock) => {
                            rowBlock.column.forEach((colBlock) => {
                                colBlock?.blocks.forEach((block) => {
                                    if (
                                        block.blockType ===
                                        BLOCKTYPE.SECTION_ITEMS
                                    ) {
                                        block?.data?.items?.items?.forEach(
                                            (item) => {
                                                item.styles[blockId] = {
                                                    ...blockToUpdate
                                                };
                                            }
                                        );
                                    }
                                });
                            });
                        });
                    });

                    // Update Section items default style to menu
                    updatedPageBlocks.forEach((pageBlock) => {
                        pageBlock.row.forEach((rowBlock) => {
                            rowBlock.column.forEach((colBlock) => {
                                colBlock?.blocks.forEach((block) => {
                                    if (
                                        block.blockType ===
                                        BLOCKTYPE.SECTION_ITEMS
                                    ) {
                                        block.data.styles.item[blockId] = {
                                            ...blockToUpdate
                                        };
                                    }
                                });
                            });
                        });
                    });
                }
            }
            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
            setApplyAllModal(false);
            setApplyToggle(false);
        }
    };

    const InfoMessage = () => {
        const blockName = styleBlock.name;
        return (
            <p className="infoText">
                This will apply the current <strong>{blockName}</strong> to all
                items in the {applyTo}.
            </p>
        );
    };

    return (
        <Root className="stylesBlockWrapper stylingChanges">
            <SectionRoot className="itemsStylesBlock">
                {itemsStylesData?.map((sectionBlock, index) => {
                    const isExpanded = expanded[index];
                    const isApplyToggle = applyToggle[index];
                    return (
                        <CollapseRoot
                            key={index}
                            className={`container ${
                                isExpanded ? "active" : ""
                            }`}
                        >
                            <div className="collapseWrapper">
                                <span
                                    onClick={() => toggleRow(index)}
                                    className={`expand_section ${
                                        isExpanded ? "active" : ""
                                    }`}
                                >
                                    <Icon name={"arrowRight"} />
                                </span>
                                <Box className="flexBox justifyBetween flex1 alignCenter">
                                    <span className="layoutName">
                                        {sectionBlock.name}
                                    </span>
                                    {/* {isExpanded ? (
                                        <Box
                                            className={`applyAllWrapper ${
                                                isApplyToggle ? "active" : ""
                                            }`}
                                        >
                                            <Chip
                                                className="applyAllLabel"
                                                label={
                                                    <div className="chipLabel flexBox alignCenter">
                                                        Apply to all
                                                    </div>
                                                }
                                                onClick={() =>
                                                    toggleApplyTo(index)
                                                }
                                            />
                                            <Box
                                                className={`applyOptionWrapper`}
                                            >
                                                <Box
                                                    className="applyOption"
                                                    onClick={() => {
                                                        setApplyAllModal(true);
                                                        setStyleBlock(
                                                            sectionBlock
                                                        );
                                                        setApplyTo("section");
                                                    }}
                                                >
                                                    Section
                                                    <Icon name="arrowRight" />
                                                </Box>
                                                <Box
                                                    className="applyOption"
                                                    onClick={() => {
                                                        setApplyAllModal(true);
                                                        setStyleBlock(
                                                            sectionBlock
                                                        );
                                                        setApplyTo("menu");
                                                    }}
                                                >
                                                    Menu
                                                    <Icon name="arrowRight" />
                                                </Box>
                                            </Box>
                                        </Box>
                                    ) : (
                                        ""
                                    )} */}
                                </Box>
                            </div>
                            <Collapse
                                in={isExpanded}
                                style={{ padding: 0 }}
                                className="collapseData"
                            >
                                {(() => {
                                    switch (sectionBlock?.dataId) {
                                        case "itemLayout":
                                            return <ItemLayout data={data} />;
                                        case "itemName":
                                            return <ItemNames data={data} />;
                                        case "itemDescription":
                                            return (
                                                <ItemsDescription data={data} />
                                            );
                                        case "itemPrice":
                                            return <ItemsPrices data={data} />;
                                        case "padding":
                                            return <ItemPadding data={data} />;
                                        case "border":
                                            return <PaperBorder data={data} />;
                                        case "background":
                                            return (
                                                <PaperBackground data={data} />
                                            );

                                        default:
                                            return null;
                                    }
                                })()}
                            </Collapse>
                        </CollapseRoot>
                    );
                })}
            </SectionRoot>
            <ConfirmAction
                open={applyAllModal}
                button1Text="Apply to All"
                actionConfirm={handleApplyAll}
                actionCancel={() => setApplyAllModal(false)}
                icon="warning"
                dialogText={`Apply changes to ${applyTo}`}
                note={<InfoMessage />}
            />
        </Root>
    );
};
export default ItemsStyles;
