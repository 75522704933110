import { Button, Grid } from "@mui/material";
import React from "react";
import { Root } from "./styles";
import LoadingScreen from "../../components/Printer/LoadingScreen";

export default function Unauthorized() {
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    return loading ? (
        <LoadingScreen></LoadingScreen>
    ) : (
        <Root container className="container">
            <Grid
                item
                xs={12}
                className="grid"
                style={{ flexDirection: "column" }}
            >
                <img
                    src="/images/stellar-menus-for-white-bg.webp"
                    alt="Stellar Menus Logo"
                    className="image"
                ></img>
                <h1 className="title">Oops!</h1>
                <h2 className="subtitle">
                    The page you are trying to access is restricted. <br></br>{" "}
                    You may need to login again.
                </h2>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    className="button"
                    onClick={() =>
                        (window.location.href =
                            process.env.REACT_APP_MENU_MANAGER || "#")
                    }
                >
                    Login
                </Button>
            </Grid>
        </Root>
    );
}
