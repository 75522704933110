import { Root, classes } from "./styles";
import Button from "../Button";
import Icon from "../UI/Icon";
import CustomTooltip from "../UI/CustomTooltip/CustomTooltip";
// import { useState } from "react";
import { usePrinterContext } from "../../context/printer";
import { client } from "../../utils/apiClient";
import { client as authClient } from "../../utils/authClient";
import { useAlertContext } from "../../context/alert";
import Alert from "../UI/Alert";
import Cookie from "js-cookie";
import { useParams } from "react-router-dom";
import HandlePrint from "../../utils/printMenu";
import { useEffect, useState } from "react";
import ConfirmAction from "../Modals";
import { cloneDeep, isEqual } from "lodash";

export default function Navbar({ handleBlur }) {
    const { menu: menuId, restaurant: restaurantId } = useParams();
    const {
        layoutState,
        menu,
        setShowPreview,
        pageBlocks,
        overflowContent,
        sectionListRes,
        setSectionList,
        pageBlocksIndex,
        pageBlocksArray,
        setPageBlocksArray,
        undoRedoState,
        setUndoRedoState
    } = usePrinterContext();

    const [saveAddLoad, setSaveAddLoad] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [overflowPopup, setOverflowPopup] = useState(false);

    const { setAlertState, alert, setAlert } = useAlertContext();

    const menuName = menu?.displayName || "Menu Name";
    const template_id = menu?.templateConfigs?.template_id;

    const { pageLayout } = layoutState;

    const refreshFun = () => {
        const refreshToken = Cookie.get("sm_r_t");
        if (!refreshToken) {
            return window.location.replace(`${process.env.REACT_APP_LOGIN}`);
        } else {
            return (async () => {
                const response = await authClient(
                    "user/refresh-token",
                    null,
                    "GET",
                    {
                        Authorization: `${refreshToken}`
                    }
                );
                if (response) {
                    const oneHour = new Date(
                        new Date().getTime() + 1 * 60 * 60 * 1000
                    );
                    Cookie.set(
                        "sm_c_t",
                        response.AuthenticationResult?.AccessToken,
                        {
                            domain: process.env.REACT_APP_COOKIE_DOMAIN,
                            expires: oneHour
                        }
                    );

                    const expire_time = new Date(
                        new Date().getTime() +
                            response.AuthenticationResult.ExpiresIn * 1000
                    );

                    const tokenEx = expire_time.toISOString();

                    Cookie.set("sm_c_t_e", tokenEx, {
                        domain: process.env.REACT_APP_COOKIE_DOMAIN,
                        expires: oneHour
                    });

                    return response.AuthenticationResult?.AccessToken;
                } else {
                    return window.location.replace(
                        `${process.env.REACT_APP_LOGIN}`
                    );
                }
            })();
        }
    };

    const refreshCookie = async () => {
        const token = Cookie.get("sm_c_t");
        const tokenEx = new Date(Cookie.get("sm_c_t_e")).getTime();
        const cTime = new Date().getTime();

        if (!token || cTime > tokenEx) {
            await refreshFun();
        }
    };

    const handleAPI = async () => {
        const promises = [];
        promises.push(
            client(
                `menu/${restaurantId}/${menuId}/update-printer-template/${template_id}`,
                {
                    body: {
                        pages_content: pageBlocks,
                        page_properties: {
                            page_layout: {
                                paper_size: pageLayout.paperSize.name,
                                orientation: pageLayout.activeOrientation,
                                margin: pageLayout.marginValue
                            }
                        }
                    },
                    method: "PUT"
                }
            )
        );

        await Promise.all(promises);
    };

    const handleSubmit = async () => {
        setSaveAddLoad(true);
        setDisableBtn(true);
        try {
            await refreshCookie();
            await handleAPI();

            // setAlert({
            //     type: "success",
            //     message: "The data saved successfully..."
            // });
            // setAlertState(true);
        } catch (e) {
            await refreshCookie();
            const phrase = "Token expired at";

            if (e?.error?.includes(phrase)) {
                await refreshFun();
                await handleAPI();
                return;
            }

            console.log(e);

            setAlert({ type: "error", message: e.message || e.error });
            setAlertState(true);
        }
        setSaveAddLoad(false);
        setDisableBtn(false);
    };

    useEffect(() => {
        const filteredSections = sectionListRes?.filter((section) => {
            const sectionId = section?._id;
            const isSectionUsed = pageBlocks?.some((page) =>
                page?.row?.some((row) =>
                    row?.column?.some((column) =>
                        column?.blocks?.some(
                            (block) =>
                                block?.data?.items?._id === sectionId ||
                                (block?.data?.items?.items || []).some(
                                    (item) => item?._id === sectionId
                                )
                        )
                    )
                )
            );

            return !isSectionUsed;
        });

        setSectionList(filteredSections);

        const submitTimeout = setTimeout(async () => {
            if (!firstLoad) {
                const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
                const clonedPageBlocks = cloneDeep(pageBlocks);
                const historyBlocks =
                    undoRedoState?.value === "undo"
                        ? clonedPageBlocksArray[pageBlocksIndex.current + 1]
                        : undoRedoState?.value === "redo"
                        ? clonedPageBlocksArray[pageBlocksIndex.current - 1]
                        : clonedPageBlocksArray[pageBlocksIndex.current];

                if (!isEqual(historyBlocks, pageBlocks)) {
                    handleSubmit();
                    if (undoRedoState?.state) {
                        pageBlocksIndex.current = pageBlocksIndex.current + 1;
                        setPageBlocksArray([
                            ...clonedPageBlocksArray.slice(
                                0,
                                pageBlocksIndex.current
                            ),
                            clonedPageBlocks
                        ]);
                    }
                    setUndoRedoState({ state: true, value: "" });
                }
            }
            setFirstLoad(false);
        }, 1500);

        return () => clearTimeout(submitTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageBlocks, layoutState]);

    const InfoMessage = () => {
        return (
            <p className="infoText" style={{ lineHeight: "22px" }}>
                Your menu currently has overflow content. If proceeding with
                printing some of your menu content may we be cut off.
            </p>
        );
    };

    return (
        <Root item xs={12} className={classes.navbar} onClick={handleBlur}>
            <Alert {...alert} />
            <div className={`${classes.leftBlock} ${classes.flexBox}`}>
                <CustomTooltip title="Back">
                    <span
                        className={classes.iconCircle}
                        onClick={() =>
                            (window.location.href =
                                process.env.REACT_APP_MENU_MANAGER || "#")
                        }
                    >
                        <Icon name="back" />
                    </span>
                </CustomTooltip>
                <span className={classes.title}>{menuName}</span>
            </div>
            <div className={`${classes.centerBlock} ${classes.flexBox}`}>
                <Button
                    type="secondary"
                    color="dark"
                    innerText="Preview"
                    className="preview_btn"
                    icon="preview"
                    onClick={() => setShowPreview((s) => !s)}
                ></Button>
                {overflowContent ? (
                    <Button
                        type="primary"
                        color="orange"
                        innerText="Print"
                        className="print_btn"
                        icon="print"
                        onClick={() => setOverflowPopup(true)}
                    ></Button>
                ) : (
                    <HandlePrint />
                )}
            </div>
            <div className={`${classes.rightBlock} ${classes.flexBox}`}>
                <Button
                    type="primary"
                    color="dark"
                    innerText="Save Changes"
                    itemLoading={saveAddLoad}
                    disableBtn={disableBtn}
                    className="save_btn"
                    icon="save"
                    onClick={() => handleSubmit()}
                ></Button>
            </div>
            <ConfirmAction
                open={overflowPopup}
                button1Text="Cancel Print"
                button2Text={
                    <HandlePrint
                        setOverflowPopup={setOverflowPopup}
                        type="secondary"
                        buttonText="Print Menu"
                    />
                }
                actionConfirm={() => setOverflowPopup(false)}
                actionCancel={() => setOverflowPopup(false)}
                icon="warning"
                dialogText={`Menu content is overflow`}
                note={<InfoMessage />}
            />
        </Root>
    );
}
