import { useCallback, useState } from "react";
import { Root } from "../style";
import { Root as SectionRoot } from "./style";
import Icon from "../../../../../UI/Icon";
import { Box } from "@mui/system";
import { Chip, Collapse } from "@mui/material";
import { CollapseRoot } from "../../../../../UI/CommonStyles";
import SectionLayout from "./SectionBlocks/SectionLayout";
import SectionTitle from "./SectionBlocks/SectionTitle";
import SectionHeader from "./SectionBlocks/SectionHeader";
import SubSectionTitle from "./SectionBlocks/SubSectionTitle";
import { usePrinterContext } from "../../../../../../context/printer";
import ConfirmAction from "../../../../../Modals";
import ItemLayout from "./SectionBlocks/ItemLayout";
import ItemsNames from "./SectionBlocks/ItemNames";
import ItemsDescription from "./SectionBlocks/ItemsDescription";
import ItemsPrices from "./SectionBlocks/ItemsPrices";

const sectionStylesData = [
    { name: "Section Layout", dataId: "sectionLayout" },
    { name: "Section Title", dataId: "sectionTitle" },
    { name: "Section Header", dataId: "sectionHeader" },
    { name: "Item Layout", dataId: "itemLayout" },
    { name: "Item Names", dataId: "itemName" },
    { name: "Item Descriptions", dataId: "itemDescription" },
    { name: "Item Prices", dataId: "itemPrice" },
    { name: "Sub-Section Titles", dataId: "subSectionTitle" }
];

const SectionStyles = ({ data }) => {
    const { pageBlocks, setPageBlocks } = usePrinterContext();

    const [expanded, setExpanded] = useState(false);
    const [applyAllModal, setApplyAllModal] = useState(false);
    const [styleBlock, setStyleBlock] = useState({});

    const toggleRow = useCallback((index) => {
        setExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }, []);

    const handleApplyAll = () => {
        const blockId = styleBlock.dataId;
        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.styles[blockId];
        const bgBlockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.styles["background"];
        const borderBlockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.styles["border"];

        const itemBlockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.styles["item"][blockId];

        if (!blockId.startsWith("item")) {
            if (blockToUpdate) {
                updatedPageBlocks.forEach((pageBlock) => {
                    pageBlock.row.forEach((rowBlock) => {
                        rowBlock.column.forEach((colBlock) => {
                            colBlock?.blocks.forEach((block) => {
                                if (block.blockType === data.blockType) {
                                    if (blockId === "sectionLayout") {
                                        block.data.styles["background"] = {
                                            ...bgBlockToUpdate
                                        };
                                        block.data.styles["border"] = {
                                            ...borderBlockToUpdate
                                        };
                                    }
                                    block.data.styles[blockId] = {
                                        ...blockToUpdate
                                    };
                                }
                            });
                        });
                    });
                });

                // Update the state with the modified pageBlocks
                setPageBlocks(updatedPageBlocks);
                setApplyAllModal(false);
            }
        } else {
            if (itemBlockToUpdate) {
                updatedPageBlocks.forEach((pageBlock) => {
                    pageBlock.row.forEach((rowBlock) => {
                        rowBlock.column.forEach((colBlock) => {
                            colBlock?.blocks.forEach((block) => {
                                if (block.blockType === data.blockType) {
                                    block.data.styles["item"][blockId] = {
                                        ...itemBlockToUpdate
                                    };
                                }
                            });
                        });
                    });
                });

                // Update the state with the modified pageBlocks
                setPageBlocks(updatedPageBlocks);
                setApplyAllModal(false);
            }
        }
    };

    const InfoMessage = () => {
        const blockName = styleBlock.name;
        return (
            <p className="infoText">
                This will apply the current <strong>{blockName}</strong> to the
                entire menu.
            </p>
        );
    };

    return (
        <Root className="stylesBlockWrapper stylingChanges">
            <SectionRoot className="sectionStylesBlock">
                {sectionStylesData?.map((sectionBlock, index) => {
                    const isExpanded = expanded[index];
                    return (
                        <CollapseRoot
                            key={index}
                            className={`container ${
                                isExpanded ? "active" : ""
                            }`}
                        >
                            <div className="collapseWrapper">
                                <span
                                    onClick={() => toggleRow(index)}
                                    className={`expand_section ${
                                        isExpanded ? "active" : ""
                                    }`}
                                >
                                    <Icon name={"arrowRight"} />
                                </span>
                                <Box className="flexBox justifyBetween flex1 alignCenter">
                                    <span className="layoutName">
                                        {sectionBlock.name}
                                    </span>
                                    {isExpanded ? (
                                        <Chip
                                            className="applyAllLabel"
                                            label="Apply to all"
                                            onClick={() => {
                                                setApplyAllModal(true);
                                                setStyleBlock(sectionBlock);
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </div>
                            <Collapse
                                in={isExpanded}
                                style={{ padding: 0 }}
                                className="collapseData"
                            >
                                {(() => {
                                    switch (sectionBlock?.dataId) {
                                        case "sectionLayout":
                                            return (
                                                <SectionLayout data={data} />
                                            );
                                        case "sectionTitle":
                                            return <SectionTitle data={data} />;
                                        case "sectionHeader":
                                            return (
                                                <SectionHeader data={data} />
                                            );
                                        case "itemLayout":
                                            return <ItemLayout data={data} />;
                                        case "itemName":
                                            return <ItemsNames data={data} />;
                                        case "itemDescription":
                                            return (
                                                <ItemsDescription data={data} />
                                            );
                                        case "itemPrice":
                                            return <ItemsPrices data={data} />;
                                        case "subSectionTitle":
                                            return (
                                                <SubSectionTitle data={data} />
                                            );

                                        default:
                                            return null;
                                    }
                                })()}
                            </Collapse>
                        </CollapseRoot>
                    );
                })}
            </SectionRoot>
            <ConfirmAction
                open={applyAllModal}
                button1Text="Apply to All"
                actionConfirm={handleApplyAll}
                actionCancel={() => setApplyAllModal(false)}
                icon="warning"
                dialogText={`Apply changes to all sections`}
                note={<InfoMessage />}
            />
        </Root>
    );
};
export default SectionStyles;
