import Icon from "../../../../UI/Icon";
import { Collapse, InputLabel, MenuItem, Select } from "@mui/material";
import { PAPER_SIZES } from "../../../../../const/paper";
import { usePrinterContext } from "../../../../../context/printer";
import { CollapseRoot, ImageCardStyles } from "../../../../UI/CommonStyles";
import { ImageCard, Tooltip } from "../../../../UI/CommonComponents";
import ConfirmAction from "../../../../Modals";
import { useState } from "react";
import { PAPER_LAYOUT_BLOCKS_ID } from "../../../../../const/layout";
import { SelectRoot } from "../style";

export default function PaperLayout() {
    const { layoutState, setLayoutState, pageBlocks } = usePrinterContext();

    const { pageLayout } = layoutState;

    const [handleOrientationChange, setHandleOrientationChange] =
        useState(false);

    let layoutPageIndex = -1; // Initialize with a default value indicating no match

    const hasLayout = pageBlocks?.some((page, index) => {
        if (
            page?.page_properties?.layout?.columnCount === "4" ||
            page?.page_properties?.layout?.columnCount === "2/2"
        ) {
            // Set the layoutPageIndex to the index of the matched page
            layoutPageIndex = index;
            return true; // Return true to break out of the some loop
        }
        return false; // Continue iteration if no match
    });

    const handleOrientationClick = (orientation) => {
        if (orientation === "portrait" && hasLayout) {
            setHandleOrientationChange(true);
            return;
        }
        setLayoutState((prevState) => ({
            ...prevState,
            pageLayout: {
                ...pageLayout,
                activeOrientation: orientation
            }
        }));
    };

    const onPaperChange = (e) => {
        setLayoutState((prevState) => ({
            ...prevState,
            pageLayout: {
                ...pageLayout,
                paperSize: PAPER_SIZES[e.target.value]
            }
        }));
    };

    const PaperSizeComp = () => {
        return (
            <SelectRoot size="small" fullWidth>
                <InputLabel id="input-label" className="paperSize-label">
                    Paper size <span className="required-star">* </span>
                </InputLabel>
                <Select
                    labelId="input-label"
                    id="input-select"
                    value={pageLayout.paperSize.name}
                    label="Paper size"
                    onChange={onPaperChange}
                    className="input-select"
                    MenuProps={{
                        classes: {
                            paper: "customSelectList"
                        }
                    }}
                >
                    {/* Display all page sizes */}

                    {Object.keys(PAPER_SIZES).map((key) => (
                        <MenuItem value={key} key={key}>
                            {PAPER_SIZES[key].display}
                        </MenuItem>
                    ))}
                </Select>
            </SelectRoot>
        );
    };

    const PaperOrientationComp = () => {
        return (
            <ImageCardStyles className="imageCardWrap">
                <ImageCard
                    icon="portrait"
                    label="Portrait"
                    onClick={() => handleOrientationClick("portrait")}
                    isActive={pageLayout.activeOrientation === "portrait"}
                />
                <ImageCard
                    icon="landscape"
                    label="Landscape"
                    onClick={() => handleOrientationClick("landscape")}
                    isActive={pageLayout.activeOrientation === "landscape"}
                />
            </ImageCardStyles>
        );
    };

    const toggleRow = (index) => {
        setLayoutState((prevState) => ({
            ...prevState,
            pageLayout: {
                ...pageLayout,
                expanded: pageLayout.expanded.map((item, i) =>
                    i === index ? !item : item
                )
            }
        }));
    };

    const { PAPER_SIZE, PAPER_ORIENTATION } = PAPER_LAYOUT_BLOCKS_ID;

    return (
        <>
            {pageLayout?.data.map((layoutRows, index) => {
                const isExpanded = pageLayout.expanded[index];
                return (
                    <CollapseRoot
                        key={index}
                        className={`container ${isExpanded ? "active" : ""}`}
                    >
                        <div className="collapseWrapper">
                            <span
                                onClick={() => toggleRow(index)}
                                className={`expand_section ${
                                    isExpanded ? "active" : ""
                                }`}
                            >
                                <Icon name={"arrowRight"} />
                            </span>
                            <span className="layoutName flexBox alignCenter">
                                {layoutRows.name}
                                <Tooltip style={{ marginLeft: "10px" }} />
                            </span>
                        </div>
                        <Collapse in={isExpanded} style={{ padding: 0 }}>
                            <div className="collapseContentWrapper">
                                {(() => {
                                    switch (layoutRows?.dataId) {
                                        case PAPER_SIZE:
                                            return <PaperSizeComp />;
                                        case PAPER_ORIENTATION:
                                            return <PaperOrientationComp />;

                                        default:
                                            return null;
                                    }
                                })()}
                            </div>
                        </Collapse>
                        <ConfirmAction
                            open={handleOrientationChange}
                            button1Text=""
                            button2Text="OK"
                            actionCancel={() =>
                                setHandleOrientationChange(false)
                            }
                            icon="warning"
                            dialogText={`Orientation Change`}
                            note={`The layout for page number ${
                                layoutPageIndex + 1
                            } is not available in portrait mode. Please adjust the layout to ensure compatibility with portrait orientation.`}
                        />
                    </CollapseRoot>
                );
            })}
        </>
    );
}
