const portLayout = [
    {
        icon: "port1Col",
        label: "1 column",
        onClick: "1",
        isActive: "1"
    },
    {
        icon: "port2Col",
        label: "2 column",
        onClick: "2",
        isActive: "2"
    },
    {
        icon: "port3Col",
        label: "3 column",
        onClick: "3",
        isActive: "3"
    },
    {
        icon: "port12Col",
        label: "1/2 Page - 1 Column",
        onClick: "1/2",
        isActive: "1/2"
    },
    {
        icon: "gridLayout",
        label: "Grid",
        onClick: "grid",
        isActive: "grid"
    }
];

const landLayout = [
    {
        icon: "land1Col",
        label: "1 column",
        onClick: "1",
        isActive: "1"
    },
    {
        icon: "land2Col",
        label: "2 column",
        onClick: "2",
        isActive: "2"
    },
    {
        icon: "land3Col",
        label: "3 column",
        onClick: "3",
        isActive: "3"
    },
    {
        icon: "land4Col",
        label: "4 column",
        onClick: "4",
        isActive: "4"
    },
    {
        icon: "land12Col",
        label: "1/2 Page - 1 Column",
        onClick: "1/2",
        isActive: "1/2"
    },
    {
        icon: "land12Col2",
        label: "1/2 Page - 2 Column",
        onClick: "2/2",
        isActive: "2/2"
    },
    {
        icon: "gridLayoutLandscape",
        label: "Grid",
        onClick: "grid",
        isActive: "grid"
    }
];

const PAPER_LAYOUT_BLOCKS_ID = {
    PAPER_SIZE: "paper-size",
    PAPER_ORIENTATION: "paper-orientation"
};

const PAGE_LAYOUT_BLOCKS_ID = {
    SELECT_LAYOUT: "select-layout",
    COLUMN_SPACING: "column-spacing",
    PAGE_MARGIN: "page-margin",
    PAGE_BACKGROUND: "page-background",
    PAGE_BORDER: "page-border"
};

const pageLayoutData = [
    { name: "Select Layout", dataId: PAGE_LAYOUT_BLOCKS_ID.SELECT_LAYOUT },
    { name: "Column Spacing", dataId: PAGE_LAYOUT_BLOCKS_ID.COLUMN_SPACING },
    { name: "Paper Margin", dataId: PAGE_LAYOUT_BLOCKS_ID.PAGE_MARGIN },
    { name: "Background", dataId: PAGE_LAYOUT_BLOCKS_ID.PAGE_BACKGROUND },
    { name: "Border", dataId: PAGE_LAYOUT_BLOCKS_ID.PAGE_BORDER }
];

const paperLayoutData = [
    { name: "Paper Size", dataId: PAPER_LAYOUT_BLOCKS_ID.PAPER_SIZE },
    {
        name: "Paper Orientation",
        dataId: PAPER_LAYOUT_BLOCKS_ID.PAPER_ORIENTATION
    }
];

export {
    portLayout,
    landLayout,
    pageLayoutData,
    paperLayoutData,
    PAGE_LAYOUT_BLOCKS_ID,
    PAPER_LAYOUT_BLOCKS_ID
};
