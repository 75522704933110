import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { ImageCardStyles } from "../../../../../../UI/CommonStyles";
import {
    ImageCard,
    NumberCount,
    RangeSlider
} from "../../../../../../UI/CommonComponents";
import { usePrinterContext } from "../../../../../../../context/printer";
import { cloneDeep, isEqual } from "lodash";
import PaperBackground from "./PaperBackground";
import PaperBorder from "./PaperBorder";

const columnState = [
    {
        column: 1,
        label: "1 column",
        iconName: "columnOneIcon"
    },
    {
        column: 2,
        label: "2 column",
        iconName: "columnTwoIcon"
    }
];

export default function SectionLayout({ data }) {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray
    } = usePrinterContext();
    const [dependencyChanged, setDependencyChanged] = useState(false);

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex];

    const [column, setColumn] = useState(
        blockToUpdate?.data?.styles?.sectionLayout?.column
    );
    const [columnSpace, setColumnSpace] = useState(
        blockToUpdate?.data?.styles?.sectionLayout?.columnSpace
    );
    const [itemSpace, setItemSpace] = useState(
        blockToUpdate?.data?.styles?.sectionLayout?.itemSpace
    );
    const [titleItemSpace, setTitleItemSpace] = useState(
        blockToUpdate?.data?.styles?.sectionLayout?.titleItemSpace
    );
    const [topPadding, setTopPadding] = useState(
        blockToUpdate?.data?.styles?.sectionLayout?.topPadding
    );
    const [bottomPadding, setBottomPadding] = useState(
        blockToUpdate?.data?.styles?.sectionLayout?.bottomPadding
    );
    const [rightPadding, setRightPadding] = useState(
        blockToUpdate?.data?.styles?.sectionLayout?.rightPadding
    );
    const [leftPadding, setLeftPadding] = useState(
        blockToUpdate?.data?.styles?.sectionLayout?.leftPadding
    );

    const handleOnClick = (columnCount) => {
        setDependencyChanged(true);
        setColumn(columnCount);
    };

    const handleItemSpaceChange = (event, max) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = parsedValue < max ? parsedValue : max;
        if (typeof newValue === "number") {
            setDependencyChanged(true);
            setItemSpace(newValue);
        }
    };

    const handleTitleItemSpaceChange = (event, max) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = parsedValue < max ? parsedValue : max;
        if (typeof newValue === "number") {
            setDependencyChanged(true);
            setTitleItemSpace(newValue);
        }
    };
    const handleColumnSpaceChange = (event, max) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = parsedValue < max ? parsedValue : max;
        if (typeof newValue === "number") {
            setDependencyChanged(true);
            setColumnSpace(newValue);
        }
    };

    useEffect(() => {
        if (undoRedoState?.state && isEqual(data, sectionBlock.data)) {
            return;
        }

        setColumn(blockToUpdate?.data?.styles?.sectionLayout?.column);
        setColumnSpace(blockToUpdate?.data?.styles?.sectionLayout?.columnSpace);
        setItemSpace(blockToUpdate?.data?.styles?.sectionLayout?.itemSpace);
        setTitleItemSpace(
            blockToUpdate?.data?.styles?.sectionLayout?.titleItemSpace
        );
        setTopPadding(blockToUpdate?.data?.styles?.sectionLayout?.topPadding);
        setLeftPadding(blockToUpdate?.data?.styles?.sectionLayout?.leftPadding);
        setRightPadding(
            blockToUpdate?.data?.styles?.sectionLayout?.rightPadding
        );
        setBottomPadding(
            blockToUpdate?.data?.styles?.sectionLayout?.bottomPadding
        );

        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.pageIndex, data.columnIndex, data.blockIndex, undoRedoState]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            column,
            columnSpace,
            itemSpace,
            titleItemSpace,
            topPadding,
            bottomPadding,
            leftPadding,
            rightPadding
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex];

        if (blockToUpdate) {
            blockToUpdate.data.styles.sectionLayout = {
                ...blockToUpdate.data.styles.sectionLayout,
                ...newStyles
            };

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }

        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        column,
        columnSpace,
        itemSpace,
        titleItemSpace,
        topPadding,
        bottomPadding,
        leftPadding,
        rightPadding
    ]);

    return (
        <div className="collapseContentWrapper sectionLayout">
            <Box className="fontLabel">Columns</Box>
            <ImageCardStyles className="imageCardWrap">
                {columnState.map((item, index) => (
                    <ImageCard
                        key={index}
                        icon={item.iconName}
                        label={item.label}
                        onClick={() => handleOnClick(item.column)}
                        isActive={column === item.column}
                    />
                ))}
            </ImageCardStyles>
            {column !== 1 ? (
                <RangeSlider
                    sliderLabel="Space Between Column"
                    sliderVal={columnSpace}
                    handleSliderChange={(event) =>
                        handleColumnSpaceChange(event, 50)
                    }
                    min={0}
                    max={50}
                    step={1}
                />
            ) : (
                ""
            )}
            <RangeSlider
                sliderLabel="Space Between Items"
                sliderVal={itemSpace}
                handleSliderChange={(event) => handleItemSpaceChange(event, 30)}
                min={0}
                max={30}
                step={1}
            />
            <RangeSlider
                sliderLabel="Space Between Title & Items"
                sliderVal={titleItemSpace}
                handleSliderChange={(event) =>
                    handleTitleItemSpaceChange(event, 50)
                }
                min={0}
                max={50}
                step={1}
            />

            <Box className="sectionLayoutInner" sx={{ mt: 2, mb: 2.5 }}>
                <Box className="blockTitle" sx={{ mb: 1 }}>
                    <span className="mediumFont">Section Padding</span>
                </Box>
                <Box className="containerPadding flexBox alignCenter justifyCenter">
                    <NumberCount
                        setNumberCount={setTopPadding}
                        numberCount={topPadding}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setRightPadding}
                        numberCount={rightPadding}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setBottomPadding}
                        numberCount={bottomPadding}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setLeftPadding}
                        numberCount={leftPadding}
                        setDependencyChanged={setDependencyChanged}
                    />
                </Box>
                <Box className="flexBox justifyAround alignCenter alignmentsText">
                    <Box className="mediumFont">Top</Box>
                    <Box className="mediumFont">Right</Box>
                    <Box className="mediumFont">Bottom</Box>
                    <Box className="mediumFont">Left</Box>
                </Box>
            </Box>
            <Box sx={{ mb: 4 }}>
                <PaperBackground data={data} />
            </Box>
            <Box>
                <PaperBorder data={data} />
            </Box>
        </div>
    );
}
