import { styled } from "@mui/material/styles";
const Root = styled("div")(() => ({
    "&.PageEditorWrap": {
        position: "relative",
        transition: "height 0.5s, width 0.5s",
        scrollMargin: "20px",
        "& + .PageEditorWrap": {
            marginTop: 50
        },
        "&.showPreview": {
            overflow: "hidden",
            "& .PagesBlock": {
                marginTop: 0
            }
        }
    },
    "& .PageTitleWrap": {
        display: "flex",
        alignItems: "center",
        "& > svg": {
            minWidth: 13
        },
        "& .PageTitle": {
            fontSize: "18px",
            fontWeight: "400",
            marginRight: "10px",
            border: "none !important",
            outline: "none !important",
            background: "transparent"
        }
    },
    "& .gridRowWrapper": {
        "&:hover": {
            zIndex: 1,
            "& .resizeHandle": {
                backgroundColor: "rgba(24 ,144, 255,0.4)"
            },
            "& > .focusableBlock:not(.active)": {
                opacity: 0.5,
                borderColor: "#1890FF",
                "& .editBlock": {
                    display: "flex"
                }
            }
        },
        "& .columnWrapper": {
            position: "relative",
            "&:hover": {
                "& .ResizeHandle--width": {
                    backgroundColor: "rgba(24 ,144, 255,0.4)"
                }
            }
        }
    },
    "& .PageEditor": {
        height: "100%",
        width: "100%",
        transition: "width 0.5s,height 0.5s",
        position: "relative",
        overflow: "hidden",
        "& .previewPage": {
            border: "none !important",
            boxShadow: "none !important",
            backgroundColor: "transparent !important",
            "& .focusableBlock": {
                display: "none"
            }
        },
        "& .singleRowWrap": {
            "& .DropAreaWrap": {
                position: "absolute",
                left: "50%",
                top: "50%",
                display: "inline-flex",
                alignItems: "center",
                flexDirection: "column",
                transform: "translate(-50%,-50%)",
                cursor: "pointer",
                "& .DropAreaIcon": {
                    width: "48px",
                    height: "48px",
                    borderRadius: "25px",
                    border: "2px dashed #D3D3D3",
                    padding: "14px",
                    marginBottom: "20px",
                    transition: "all 0.3s",
                    "& svg path": {
                        transition: "all 0.3s"
                    }
                },
                "& .DropAreaTitle": {
                    fontSize: "12px",
                    fontWeight: 500,
                    fontStyle: "italic",
                    color: "#D3D3D3",
                    textAlign: "center",
                    transition: "all 0.3s"
                }
            }
        },
        "& .GridHtmlWrap": {
            height: "100%",
            "& .PageEditorInner": {
                boxShadow: "none",
                overflow: "hidden"
            }
        },
        "& .PageEditorInner": {
            width: "100%",
            height: "100%",
            minHeight: "100%",
            border: "1px dashed #939393",
            boxShadow: "inset 0 0 0 2px #EAEAEA",
            position: "relative",
            zIndex: 1,
            transition: "all 0.3s",
            "&.active": {
                borderStyle: "solid",
                borderColor: "#1890FF",
                boxShadow: "inset 0px 0px 0px 1px #1890FF",
                background: `#1890FF1A`,
                "& .placementMarker": {
                    opacity: 1
                },
                "& .DropAreaWrap": {
                    "& .DropAreaIcon": {
                        borderColor: "#1890FF",
                        "& svg path": {
                            fill: "#1890FF"
                        }
                    },
                    "& .DropAreaTitle": {
                        color: "#1890FF"
                    }
                }
            },

            "& .contentBlock:first-of-type": {
                "& .editBlock": {
                    top: "0",
                    bottom: "auto",
                    borderRadius: "0 0 15px 15px"
                }
            }
        },
        "& .MirrorHtmlWrap": {
            "& .PageEditorInner, & .ReplicatedContent": {
                overflow: "hidden"
            },
            "& > div": {
                width: "50%"
            }
        }
    },
    "& .PagesBlock": {
        backgroundRepeat: "no-repeat",
        backgroundColor: "#fff",
        boxShadow: "2px 2px 8px 0px #22222214",
        position: "relative",
        padding: "10px",
        transition: "width 0.5s, height 0.5s",
        marginTop: "12px"
    },
    "& .PageEditor__wrap": {
        padding: "10px",
        height: "100%",
        width: "100%",
        position: "relative"
    },
    "& .PageEditIcon": {
        "& .PageEditIconInner": {
            border: "1px solid #727272",
            borderRadius: "5px",
            padding: "6px",
            cursor: "pointer",
            transition: "all 0.3s",
            "& svg path": { transition: "all 0.3s" },
            "&:hover": {
                backgroundColor: "#727272",
                "& svg path": { fill: "#fff" },
                "& p": {
                    color: "#fff"
                }
            },
            "& p": {
                transition: "all 0.3s",
                marginLeft: 5,
                fontSize: 12,
                fontWeight: 500
            }
        }
    },
    "& .addContainerWrap": {
        textAlign: "center",
        zIndex: 1,
        position: "relative",
        "& > span": {
            height: "30px",
            width: "30px",
            display: "inline-flex",
            marginBottom: "10px",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            backgroundColor: "#FDF6F2",
            cursor: "pointer"
        },
        "& svg path": { fill: "#B84F03", transition: "all 0.3s" },
        "& .addContainerInner": {
            border: "1px solid #EAEAEA",
            display: "inline-flex",
            borderRadius: "25px",
            padding: "6px 12px",
            cursor: "pointer",
            transition: "all 0.3s",
            "&:hover": {
                backgroundColor: "#727272",
                "& svg path": { fill: "#fff !important" },
                "& p": {
                    color: "#fff"
                }
            },
            "& svg": {
                height: "12px",
                width: "12px"
            },
            "& p": {
                transition: "all 0.3s",
                color: "#3A3A3A",
                marginLeft: 5
            }
        },
        "& p": {
            fontSize: "12px",
            fontWeight: 600,
            fontStyle: "italic",
            color: "#B4B3B3"
        }
    },
    "& .PageMarginBorder": {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        "&.toEdge": {
            "&:before, &:after": {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            }
        },
        "&:before, &:after": {
            content: '""',
            position: "absolute"
            // zIndex: 1
        },
        "&:before": {
            borderTop: "2px dashed #dedede",
            borderBottom: "2px dashed #dedede",
            top: 10,
            left: 0,
            right: 0,
            bottom: 10
        },
        "&:after": {
            borderLeft: "2px dashed #dedede",
            borderRight: "2px dashed #dedede",
            left: 10,
            right: 10,
            bottom: 0,
            top: 0
        }
    },
    "& .GridHtmlWrap": {
        "& .addContainerWrap": {
            marginTop: 24
        }
    },
    "& .pageModification": {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: "calc(100% + 12px)",
        bottom: "20px",
        "& svg": {
            margin: "12px",
            cursor: "pointer"
        }
    },
    "& .ReplicatedContent": {
        position: "relative",
        pointerEvents: "none",
        "& > div": {
            width: "100%",
            height: "100%",
            minHeight: "100%"
        }
    },
    "& .contentBlock": {
        "&.sectionWrapper": {
            position: "relative"
        },
        "&:nth-last-of-type(2)": {
            "& .blockWrapper": {
                marginBottom: "0 !important"
            }
        }
    },
    "& .resizeHandle": {
        position: "absolute",
        top: "auto",
        bottom: "calc(100% - 5px)",
        left: 0,
        width: "100%",
        height: "5px",
        zIndex: 2,
        cursor: "ns-resize",
        backgroundColor: "transparent",
        transition: "background-color 0.3s"
    },
    "& .ResizeHandle--width": {
        position: "absolute",
        zIndex: 2,
        cursor: "ew-resize",
        backgroundColor: "transparent",
        transition: "background-color 0.3s",
        height: "100%",
        width: "5px",
        left: "auto",
        right: "calc(100% - 5px)"
    }
}));

export { Root };
