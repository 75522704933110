import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const TooltipCustom = styled(
    ({ title, delay, children, placement, className }) => (
        <Tooltip
            delay={delay}
            title={title}
            placement={placement}
            classes={{ popper: className }}
            arrow
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, -5]
                            }
                        }
                    ]
                }
            }}
        >
            {children}
        </Tooltip>
    )
)(({ theme, delay, className }) => ({
    [`&.${className}`]: {
        "& div": {
            borderRadius: "4px",
            background: "#222222",
            display: "flex",
            border: "none",
            padding: "5px 8px",
            color: "#ffffff",
            fontSize: 13,
            opacity: 0,
            fontWeight: 500,
            // transform: "none !important",
            // transition: `all 600ms ease-in-out ${delay}ms !important`,
            "& .MuiTooltip-arrow": {
                color: "#222222",
                "&:before": {
                    borderRadius: "1px"
                }
            }
        }
    }
}));

export { TooltipCustom };
