import { TooltipCustom } from "./style";

const CustomTooltip = ({
    title,
    delay = 300,
    children,
    placement = "bottom"
}) => {
    return (
        <TooltipCustom
            title={title}
            delay={delay}
            children={children}
            placement={placement}
            className="customPopper"
        ></TooltipCustom>
    );
};

export default CustomTooltip;
