/*
    ---- USAGE ---
    - import context hook
        import { useAlertContext } from "....../context/alert";
    - get alert states from context
        const { setAlertState, alert, setAlert } = useAlertContext();
    - set alert message, alert type and alert state
        setAlert({ type: "success", message: res.message });
        setAlertState(true);
    - Need to add component <Alert /> wherever you'll use it
        <Alert type={alert?.type} message={alert?.message} />
*/

import React, { useState } from "react";

export interface AlertInterface {
    type: string;
    message: string;
}

interface IContext {
    alertState: boolean;
    setAlertState: any;
    alert: AlertInterface;
    setAlert: any;
}

const AlertContext = React.createContext<IContext>({
    alertState: false,
    setAlertState: () => {},
    alert: {
        type: "",
        message: ""
    },
    setAlert: () => {}
});
AlertContext.displayName = "AlertContext";

function AlertContextProvider(props: any) {
    const [alertState, setAlertState] = useState(false);
    const [alert, setAlert] = useState<AlertInterface>({
        type: "",
        message: ""
    });

    const value = React.useMemo(
        () => ({
            alertState,
            setAlertState,
            alert,
            setAlert
        }),
        [alertState, alert, setAlert]
    );

    return <AlertContext.Provider value={value} {...props} />;
}

function useAlertContext() {
    const context = React.useContext(AlertContext);
    if (!context) {
        throw new Error(
            "PrinterContext must be used within a GlobalContextProvider"
        );
    }
    return context;
}

export { AlertContext, AlertContextProvider, useAlertContext };
