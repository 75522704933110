import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Icon from "../UI/Icon";
import { MyTabPanel, MyTabs } from "./style";
import Layout from "./Tabs/Layout";
import Content from "./Tabs/Content";
import Styles from "./Tabs/Styles";
import { usePrinterContext } from "../../context/printer";
import { SIDEBAR_TAB_INDEX } from "../../const/general";
import { handleOverRef } from "../../utils/common";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`sidebar-tabpanel-${index}`}
            aria-labelledby={`sidebar-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

const tabLabel = (text) => {
    return <span className="labelText">{text}</span>;
};

const labelProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
        key: index,
        value: index
    };
};

export default function Sidebar({ overRef }) {
    const [blockData, setBlockData] = useState();
    const [pageData, setPageData] = useState();

    const { tabIndex, setTabIndex, handleDrawer, setHandleDrawer } =
        usePrinterContext();

    const { LAYOUT, CONTENT, STYLES } = SIDEBAR_TAB_INDEX;

    useEffect(() => {
        let returnedEvent = window.addEventListener(
            "sendStylesEvent",
            (event) => {
                setBlockData(event?.detail);
            }
        );
        return () => returnedEvent;
    }, []);

    useEffect(() => {
        let returnedEvent = window.addEventListener(
            "sendRowStylesEvent",
            (event) => {
                setBlockData(event?.detail);
            }
        );
        return () => returnedEvent;
    }, []);

    useEffect(() => {
        let returnedEvent = window.addEventListener(
            "sendItemStylesEvent",
            (event) => {
                setBlockData(event?.detail);
            }
        );
        return () => returnedEvent;
    }, []);

    useEffect(() => {
        let returnedEvent = window.addEventListener(
            "sendPageStylesEvent",
            (event) => {
                setPageData(event?.detail);
            }
        );
        return () => returnedEvent;
    }, []);

    const handleChange = (event, newValue) => {
        if (handleDrawer) {
            setHandleDrawer(false);
            handleOverRef(overRef);
        }
        setTabIndex(newValue);
    };

    const handleCollapse = () => {
        setHandleDrawer(!handleDrawer);
        handleOverRef(overRef);
    };

    return (
        <>
            <MyTabs
                orientation="vertical"
                value={tabIndex}
                onChange={handleChange}
                aria-label="Main Sidebar"
            >
                <Tab
                    icon={<Icon name="layout" />}
                    label={tabLabel("Layout")}
                    {...labelProps(LAYOUT)}
                />
                <Tab
                    icon={<Icon name="content" />}
                    label={tabLabel("Content")}
                    {...labelProps(CONTENT)}
                />
                <Tab
                    icon={<Icon name="style" />}
                    label={tabLabel("Styles")}
                    {...labelProps(STYLES)}
                />
            </MyTabs>

            <MyTabPanel
                className={
                    handleDrawer
                        ? "tabsContentWrapper hideDrawer"
                        : "tabsContentWrapper"
                }
            >
                <TabPanel value={tabIndex} index={LAYOUT}>
                    <Layout blockData={pageData} />
                </TabPanel>
                <TabPanel value={tabIndex} index={CONTENT}>
                    <Content />
                </TabPanel>
                <TabPanel value={tabIndex} index={STYLES}>
                    <Styles blockData={blockData} />
                </TabPanel>
            </MyTabPanel>
            <Box
                sx={{
                    position: "absolute",
                    left: "100%",
                    bottom: "50%",
                    transform: "translateY(50%)",
                    border: "1px solid #dadada",
                    borderRadius: "0 20px 20px 0",
                    borderLeftWidth: 0,
                    backgroundColor: "#fff",
                    height: "70px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    zIndex: "2",
                    cursor: "pointer"
                }}
                onClick={handleCollapse}
            >
                <Icon
                    style={{
                        transition: "all 0.5s",
                        transform: handleDrawer
                            ? "rotate(180deg)"
                            : "rotate(0deg)"
                    }}
                    name="arrowLeft"
                />
            </Box>
        </>
    );
}
