import React, { useRef, useState } from "react";
import { DEFAULT_PAPER_SIZE, PAPER_SIZES } from "../const/paper";
import { IRestaurant } from "../adapters/output/IRestaurant";
import IMenu from "../adapters/output/IMenu";
import {
    DEFAULT_INDEX,
    DEFAULT_PAGE_PROPS,
    RESET_ROW_COL
} from "../const/general";
import { pageLayoutData, paperLayoutData } from "../const/layout";
import { cloneDeep } from "lodash";
interface IContext {
    undoRedoState: any;
    setUndoRedoState: any;
    pageBlocksIndex: any;
    pageBlocksArray: any;
    setPageBlocksArray: any;
    handleDrawer: boolean;
    setHandleDrawer: boolean;
    layoutTabIndex: number;
    setLayoutTabIndex: number;
    tabIndex: number;
    setTabIndex: number;
    layoutState: any;
    setLayoutState: any;
    overflowContent: any;
    setOverflowContent: any;
    printLoading: any;
    setPrintLoading: any;
    draggedItem: any;
    setDraggedItem: any;
    sectionItems: any;
    setSectionItems: any;
    imageLoading: any;
    setImageLoading: any;
    zoomLevel: any;
    setZoomLevel: any;
    sectionList: any;
    setSectionList: any;
    sectionListRes: any;
    setSectionListRes: any;
    sectionBlock: any;
    setSectionBlock: any;
    pageBlocks: any;
    setPageBlocks: any;
    showPreview: boolean;
    setShowPreview: any;
    revertItemStyle: boolean;
    setRevertItemStyle: any;
    restaurant: IRestaurant;
    setRestaurant: React.Dispatch<React.SetStateAction<IRestaurant>>;
    menu: IMenu;
    setMenu: React.Dispatch<React.SetStateAction<IMenu>>;
    printPageElement: React.MutableRefObject<any>;
}

const PrinterContext = React.createContext<Partial<IContext>>({});
PrinterContext.displayName = "PrinterContext";

function PrinterContextProvider(props: any) {
    const printPageElement = React.useRef(null);
    const [overflowContent, setOverflowContent] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [draggedItem, setDraggedItem] = useState("");
    const [sectionItems, setSectionItems] = useState({});
    const [imageLoading, setImageLoading] = useState(false);
    const [sectionBlock, setSectionBlock] = useState({});
    const [sectionList, setSectionList] = useState([]);
    const [sectionListRes, setSectionListRes] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(8);
    const [showPreview, setShowPreview] = React.useState<boolean>(false);
    const [revertItemStyle, setRevertItemStyle] = useState(false);

    const defaultPageProps = cloneDeep(DEFAULT_PAGE_PROPS);
    const resetRowCol = cloneDeep(RESET_ROW_COL);
    const [pageBlocks, setPageBlocks] = useState([
        {
            page_no: 1,
            page_name: "Page Name",
            page_properties: defaultPageProps,
            row: resetRowCol
        }
    ]);
    const [layoutState, setLayoutState] = useState({
        orientation: "portrait",
        pageLayout: {
            data: paperLayoutData,
            expanded: Array(paperLayoutData.length).fill(true),
            paperSize: PAPER_SIZES[DEFAULT_PAPER_SIZE],
            activeOrientation: "portrait",
            marginValue: 20
        },
        columnLayout: {
            data: pageLayoutData,
            expanded: Array(pageLayoutData.length).fill(true)
        }
    });

    const [restaurant, setRestaurant] = React.useState<IRestaurant | null>(
        null
    );
    const [menu, setMenu] = React.useState<IMenu | null>(null);
    const [tabIndex, setTabIndex] = useState(DEFAULT_INDEX);
    const [layoutTabIndex, setLayoutTabIndex] = useState(DEFAULT_INDEX);
    const [handleDrawer, setHandleDrawer] = useState(false);
    const [undoRedoState, setUndoRedoState] = useState({
        state: true,
        value: ""
    });
    const [pageBlocksArray, setPageBlocksArray] = useState([pageBlocks]);
    const pageBlocksIndex = useRef(0);

    const value = React.useMemo(
        () => ({
            undoRedoState,
            setUndoRedoState,
            pageBlocksIndex,
            pageBlocksArray,
            setPageBlocksArray,
            handleDrawer,
            setHandleDrawer,
            layoutTabIndex,
            setLayoutTabIndex,
            tabIndex,
            setTabIndex,
            layoutState,
            setLayoutState,
            overflowContent,
            setOverflowContent,
            printLoading,
            setPrintLoading,
            draggedItem,
            setDraggedItem,
            sectionItems,
            setSectionItems,
            imageLoading,
            setImageLoading,
            zoomLevel,
            setZoomLevel,
            sectionList,
            setSectionList,
            sectionListRes,
            setSectionListRes,
            sectionBlock,
            setSectionBlock,
            showPreview,
            setShowPreview,
            revertItemStyle,
            setRevertItemStyle,
            pageBlocks,
            setPageBlocks,
            restaurant,
            setRestaurant,
            menu,
            setMenu,
            printPageElement
        }),
        [
            undoRedoState,
            setUndoRedoState,
            pageBlocksIndex,
            pageBlocksArray,
            setPageBlocksArray,
            handleDrawer,
            setHandleDrawer,
            layoutTabIndex,
            setLayoutTabIndex,
            tabIndex,
            setTabIndex,
            layoutState,
            setLayoutState,
            overflowContent,
            setOverflowContent,
            printLoading,
            setPrintLoading,
            draggedItem,
            setDraggedItem,
            sectionItems,
            setSectionItems,
            imageLoading,
            setImageLoading,
            zoomLevel,
            setZoomLevel,
            sectionList,
            setSectionList,
            sectionListRes,
            setSectionListRes,
            sectionBlock,
            setSectionBlock,
            showPreview,
            setShowPreview,
            revertItemStyle,
            setRevertItemStyle,
            pageBlocks,
            setPageBlocks,
            restaurant,
            setRestaurant,
            menu,
            setMenu,
            printPageElement
        ]
    );

    return <PrinterContext.Provider value={value} {...props} />;
}

function usePrinterContext() {
    const context = React.useContext(PrinterContext);
    if (!context) {
        throw new Error(
            "PrinterContext must be used within a GlobalContextProvider"
        );
    }
    return context;
}

export { PrinterContext, PrinterContextProvider, usePrinterContext };
