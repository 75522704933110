import { Box } from "@mui/system";
import Icon from "../../../../UI/Icon";
import { Root } from "./style";
import { useState } from "react";
import { usePrinterContext } from "../../../../../context/printer";
import BlockData from "../../../../../const/BlockData";
import { Tooltip } from "../../../../UI/CommonComponents";

function TabPanelCard(props) {
    const { label, icon, id } = props;
    const [activeId, setActiveId] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const { setDraggedItem } = usePrinterContext();

    // Handle Active Items
    const handleMouseDown = () => {
        setIsClicked(true);
    };
    const handleMouseUp = () => {
        setIsClicked(false);
    };

    // Handle Dragging
    function handleDragStart(e) {
        if (!activeId) {
            setDraggedItem(e.target.id);
            setActiveId(true);
        }
    }
    function handleDragEnd(e) {
        setDraggedItem("");
        setActiveId(false);
        setIsClicked(false);
    }

    return (
        <Box className="dragItemsBox">
            <Box
                onDragStart={(event) => handleDragStart(event)}
                onDragEnd={(event) => handleDragEnd(event)}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                className={`dragItemsBoxInner ${isClicked ? "active" : ""}`}
                draggable="true"
                id={id}
            >
                <Icon className="dragging-icon" name="plusRounded" />
                <Icon name={icon} />
            </Box>
            <Box className="dragItemsBoxTitle">{label}</Box>
        </Box>
    );
}
export default function ContentTab() {
    const { menu, restaurant } = usePrinterContext();
    const { BLOCKTYPE } = BlockData();

    return (
        <Root>
            <Box className="dragItemsTitle">
                Drag and Drop
                <Tooltip />
            </Box>
            <Box className="dragItemsWrap">
                <Box className="dragItems">
                    {restaurant?.logo_image && (
                        <TabPanelCard
                            icon="restaurantLogo"
                            label="Restaurant Logo"
                            id={BLOCKTYPE.RESTAURANT_LOGO}
                        />
                    )}
                    {menu?.displayName.trim() && (
                        <TabPanelCard
                            icon="menuTitle"
                            label="Menu Title"
                            id={BLOCKTYPE.MENU_TITLE}
                        />
                    )}
                    {menu?.headerText.trim() && (
                        <TabPanelCard
                            icon="menuHeader"
                            label="Menu Header"
                            id={BLOCKTYPE.MENU_HEADER}
                        />
                    )}
                    {menu?.footerText.trim() && (
                        <TabPanelCard
                            icon="menuFooter"
                            label="Menu Footer"
                            id={BLOCKTYPE.MENU_FOOTER}
                        />
                    )}
                    <TabPanelCard
                        icon="customTitle"
                        label="Custom Title"
                        id={BLOCKTYPE.CUSTOM_TITLE}
                    />
                    <TabPanelCard
                        icon="customParagraph"
                        label="Custom Paragraph"
                        id={BLOCKTYPE.CUSTOM_PARAGRAPH}
                    />
                    <TabPanelCard
                        icon="menuImage"
                        label="Image"
                        id={BLOCKTYPE.MENU_IMAGE}
                    />
                    <TabPanelCard
                        icon="divider"
                        label="Divider"
                        id={BLOCKTYPE.DIVIDER}
                    />
                </Box>
            </Box>
        </Root>
    );
}
