import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { cloneDeep, isEqual } from "lodash";
import { usePrinterContext } from "../../../../../../../context/printer";
import { ColorInput, NumberCount } from "../../../../../../UI/CommonComponents";
import { BORDER_STYLES } from "../../../../../../../const/general";
import { SelectRoot } from "../../../../Layout/style";
import { MenuItem, Select } from "@mui/material";
import Icon from "../../../../../../UI/Icon";

export default function PaperBorder({ data }) {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray,
        revertItemStyle
    } = usePrinterContext();

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex]?.data?.items?.items[data?.itemIndex];

    const blockStyles = blockToUpdate?.styles?.border;

    const [itemStyleEnabled, setItemStyleEnabled] = useState(
        blockToUpdate?.styles?.enabled
    );
    const [dependencyChanged, setDependencyChanged] = useState(false);
    const [borderColor, setBorderColor] = useState(blockStyles?.color);
    const [borderStyle, setBorderStyle] = useState(blockStyles?.style);
    const [borderWidth, setBorderWidth] = useState(blockStyles?.width);
    const [border, setBorder] = useState(blockStyles?.position);

    const [topRadius, setTopRadius] = useState(blockStyles?.radius?.top);
    const [bottomRadius, setBottomRadius] = useState(
        blockStyles?.radius?.bottom
    );
    const [rightRadius, setRightRadius] = useState(blockStyles?.radius?.right);
    const [leftRadius, setLeftRadius] = useState(blockStyles?.radius?.left);

    const handleStyleChange = (value) => {
        setDependencyChanged(true);
        setBorderStyle(value);
    };

    useEffect(() => {
        if (
            undoRedoState?.state &&
            isEqual(data, sectionBlock.data) &&
            revertItemStyle
        ) {
            return;
        }
        setItemStyleEnabled(blockToUpdate?.styles?.enabled);
        setBorderColor(blockStyles?.color);
        setBorderStyle(blockStyles?.style);
        setBorderWidth(blockStyles?.width);
        setBorder(blockStyles?.position);
        setTopRadius(blockStyles?.radius?.top);
        setLeftRadius(blockStyles?.radius?.left);
        setRightRadius(blockStyles?.radius?.right);
        setBottomRadius(blockStyles?.radius?.bottom);
        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, undoRedoState, revertItemStyle]);

    useEffect(() => {
        if (!itemStyleEnabled && dependencyChanged) {
            setItemStyleEnabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencyChanged]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            position: {
                ...border
            },
            width: borderWidth,
            color: borderColor,
            style: borderStyle,
            radius: {
                top: topRadius,
                bottom: bottomRadius,
                left: leftRadius,
                right: rightRadius
            }
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.items?.items[data?.itemIndex];

        if (blockToUpdate) {
            blockToUpdate.styles.border = {
                ...blockToUpdate?.styles?.border,
                ...newStyles
            };
            blockToUpdate.styles.enabled = itemStyleEnabled;
            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }
        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        border,
        borderWidth,
        borderColor,
        borderStyle,
        topRadius,
        bottomRadius,
        leftRadius,
        rightRadius,
        itemStyleEnabled
    ]);

    const updateBorder = (border) => {
        setBorder((prevBorder) => ({
            ...prevBorder,
            [border]: !prevBorder[border] // Toggle the border value
        }));
    };

    return (
        <Box className="collapseContentWrapper paperBorder">
            <Box className="borderStyles flexBox alignCenter justifyBetween">
                <Box className="fontLabel mb-0 mt-0">Borders</Box>
                <Box className="flexBox alignCenter">
                    <span
                        className={`borderBlock top flexBox alignCenter justifyCenter ${
                            border?.top ? "active" : ""
                        }`}
                        onClick={() => {
                            updateBorder("top");
                            setDependencyChanged(true);
                        }}
                    ></span>
                    <span
                        className={`borderBlock right flexBox alignCenter justifyCenter ${
                            border?.right ? "active" : ""
                        }`}
                        onClick={() => {
                            updateBorder("right");
                            setDependencyChanged(true);
                        }}
                    ></span>
                    <span
                        className={`borderBlock bottom flexBox alignCenter justifyCenter ${
                            border?.bottom ? "active" : ""
                        }`}
                        onClick={() => {
                            updateBorder("bottom");
                            setDependencyChanged(true);
                        }}
                    ></span>
                    <span
                        className={`borderBlock left flexBox alignCenter justifyCenter ${
                            border?.left ? "active" : ""
                        }`}
                        onClick={() => {
                            updateBorder("left");
                            setDependencyChanged(true);
                        }}
                    ></span>
                </Box>
            </Box>
            <Box className="flexBox alignEnd justifyBetween">
                <Box className="flex1 borderWidth">
                    <Box className="fontLabel mt-0">
                        Style / Thickness / Color
                    </Box>
                    <Box className="flexBox">
                        <Box sx={{ width: 118 }}>
                            <SelectRoot size="small" sx={{ width: "100%" }}>
                                <Select
                                    labelId="input-label"
                                    id="input-select"
                                    value={borderStyle}
                                    onChange={(e) =>
                                        handleStyleChange(e.target.value)
                                    }
                                    className="input-select"
                                    MenuProps={{
                                        classes: {
                                            paper: "customSelectList"
                                        }
                                    }}
                                >
                                    {BORDER_STYLES?.map((position, index) => (
                                        <MenuItem value={position} key={index}>
                                            {position}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </SelectRoot>
                        </Box>
                        <NumberCount
                            setNumberCount={setBorderWidth}
                            numberCount={borderWidth}
                            setDependencyChanged={setDependencyChanged}
                        />
                        <ColorInput
                            value={borderColor}
                            setValue={setBorderColor}
                            setDependencyChanged={setDependencyChanged}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="sectionLayoutInner" sx={{ mt: 2, mb: 2.5 }}>
                <Box className="blockTitle" sx={{ mb: 1 }}>
                    <span className="mediumFont">Round Corners</span>
                </Box>
                <Box className="flexBox justifyAround alignCenter alignmentsText borderCorners">
                    <Icon name="topLeftBorder" />
                    <Icon name="topRightBorder" />
                    <Icon name="bottomRightBorder" />
                    <Icon name="bottomLeftBorder" />
                </Box>
                <Box className="containerPadding flexBox alignCenter justifyCenter">
                    <NumberCount
                        setNumberCount={setTopRadius}
                        numberCount={topRadius}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setRightRadius}
                        numberCount={rightRadius}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setBottomRadius}
                        numberCount={bottomRadius}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setLeftRadius}
                        numberCount={leftRadius}
                        setDependencyChanged={setDependencyChanged}
                    />
                </Box>
            </Box>
        </Box>
    );
}
