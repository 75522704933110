import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { NumberCount } from "../../../../../../UI/CommonComponents";
import { usePrinterContext } from "../../../../../../../context/printer";
import { cloneDeep, isEqual } from "lodash";

export default function ItemPadding({ data }) {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray,
        revertItemStyle
    } = usePrinterContext();
    const [dependencyChanged, setDependencyChanged] = useState(false);

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex]?.data?.items?.items[data?.itemIndex];

    const [itemStyleEnabled, setItemStyleEnabled] = useState(
        blockToUpdate?.styles?.enabled
    );
    const [topPadding, setTopPadding] = useState(
        blockToUpdate.styles?.padding?.top
    );
    const [bottomPadding, setBottomPadding] = useState(
        blockToUpdate.styles?.padding?.bottom
    );
    const [rightPadding, setRightPadding] = useState(
        blockToUpdate.styles?.padding?.right
    );
    const [leftPadding, setLeftPadding] = useState(
        blockToUpdate.styles?.padding?.left
    );

    useEffect(() => {
        if (
            undoRedoState?.state &&
            isEqual(data, sectionBlock.data) &&
            revertItemStyle
        ) {
            return;
        }

        setItemStyleEnabled(blockToUpdate?.styles?.enabled);
        setTopPadding(blockToUpdate?.styles?.padding?.top);
        setLeftPadding(blockToUpdate?.styles?.padding?.left);
        setRightPadding(blockToUpdate?.styles?.padding?.right);
        setBottomPadding(blockToUpdate?.styles?.padding?.bottom);

        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data.pageIndex,
        data.rowIndex,
        data.columnIndex,
        data.blockIndex,
        data.itemIndex,
        undoRedoState,
        revertItemStyle
    ]);

    useEffect(() => {
        if (!itemStyleEnabled && dependencyChanged) {
            setItemStyleEnabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencyChanged]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            top: topPadding,
            bottom: bottomPadding,
            left: leftPadding,
            right: rightPadding
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.items?.items[data?.itemIndex];

        if (blockToUpdate) {
            blockToUpdate.styles.padding = {
                ...blockToUpdate.styles.padding,
                ...newStyles
            };

            blockToUpdate.styles.enabled = itemStyleEnabled;

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }

        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        topPadding,
        bottomPadding,
        leftPadding,
        rightPadding,
        itemStyleEnabled
    ]);

    return (
        <div className="collapseContentWrapper itemPadding">
            <Box>
                <Box className="blockTitle" sx={{ mb: 1 }}>
                    <span className="mediumFont">Item Padding</span>
                </Box>
                <Box className="containerPadding flexBox alignCenter justifyCenter">
                    <NumberCount
                        setNumberCount={setTopPadding}
                        numberCount={topPadding}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setRightPadding}
                        numberCount={rightPadding}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setBottomPadding}
                        numberCount={bottomPadding}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <NumberCount
                        setNumberCount={setLeftPadding}
                        numberCount={leftPadding}
                        setDependencyChanged={setDependencyChanged}
                    />
                </Box>
                <Box className="flexBox justifyAround alignCenter alignmentsText">
                    <Box className="mediumFont">Top</Box>
                    <Box className="mediumFont">Right</Box>
                    <Box className="mediumFont">Bottom</Box>
                    <Box className="mediumFont">Left</Box>
                </Box>
            </Box>
        </div>
    );
}
