import { Fragment, useState } from "react";
import Icon from "../../../../UI/Icon";
import { Collapse } from "@mui/material";
import { usePrinterContext } from "../../../../../context/printer";
import { CollapseRoot, ImageCardStyles } from "../../../../UI/CommonStyles";
import {
    CheckBox,
    ImageCard,
    RangeSlider,
    Tooltip
} from "../../../../UI/CommonComponents";
import ConfirmAction from "../../../../Modals";
import {
    PAGE_LAYOUT_BLOCKS_ID,
    landLayout,
    portLayout
} from "../../../../../const/layout";
import { isNormalNumber } from "../../../../../utils/common";
import PaperBorder from "./PaperBorder";
import PaperBackground from "./PaperBackground";

export default function PageLayout({ pageIndex }) {
    const { layoutState, setLayoutState, pageBlocks, setPageBlocks } =
        usePrinterContext();

    const { columnLayout } = layoutState;

    const [handleLayoutChange, setHandleLayoutChange] = useState({
        value: "",
        state: false
    });

    const updatePageLayout = (layoutType, layout) => {
        setPageBlocks((prevPageBlocks) => {
            return prevPageBlocks.map((page, index) => {
                if (index !== pageIndex) return page; // Return unchanged if not the target page

                return {
                    ...page,
                    page_properties: {
                        ...page?.page_properties,
                        layout: {
                            ...page?.page_properties?.layout,
                            columnCount: layout,
                            mirrorMode: layoutType === "mirror",
                            gridMode: {
                                ...page?.page_properties?.layout?.gridMode,
                                active: layoutType === "grid",
                                containerCount: 0,
                                column:
                                    layoutType === "grid"
                                        ? page?.page_properties?.layout
                                              ?.gridMode?.column
                                        : ""
                            }
                        }
                    }
                };
            });
        });
    };

    const layoutChange = (layout) => {
        if (isNormalNumber(layout)) {
            updatePageLayout("normal", layout);
        } else if (layout === "grid") {
            updatePageLayout("grid", layout);
        } else {
            updatePageLayout("mirror", layout);
        }
    };

    const handleLayoutConfirm = () => {
        const layout = handleLayoutChange?.value;
        layoutChange(layout);
        setHandleLayoutChange({ value: "", state: false });
    };

    const handleLayoutClick = (layout) => {
        if (
            layout ===
            pageBlocks[pageIndex]?.page_properties?.layout?.columnCount
        ) {
            return;
        }

        const hasBlocks = pageBlocks[pageIndex]?.row.some((row) =>
            row?.column.some((column) => column?.blocks?.length > 0)
        );

        if (hasBlocks) {
            setHandleLayoutChange({ value: layout, state: true });
            return;
        }

        layoutChange(layout);
    };

    const toggleRow = (index) => {
        setLayoutState((prevState) => ({
            ...prevState,
            columnLayout: {
                ...columnLayout,
                expanded: columnLayout.expanded.map((item, i) =>
                    i === index ? !item : item
                )
            }
        }));
    };

    const updateSpaceChange = (spaceType, newValue) => {
        setPageBlocks((prevPageBlocks) => {
            return prevPageBlocks.map((page, index) => {
                if (index !== pageIndex) return page; // Return unchanged if not the target page

                return {
                    ...page,
                    page_properties: {
                        ...page?.page_properties,
                        marginValue:
                            spaceType === "margin"
                                ? newValue
                                : page?.page_properties?.marginValue,
                        layout: {
                            ...page?.page_properties?.layout,
                            columnSpace:
                                spaceType === "column"
                                    ? newValue
                                    : page?.page_properties?.layout?.columnSpace
                        }
                    }
                };
            });
        });
    };

    const handleColSpace = (event, spaceType, max) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = parsedValue < max ? parsedValue : max;
        if (typeof newValue === "number") {
            updateSpaceChange(spaceType, newValue);
        }
    };

    const pageMargin = pageBlocks[pageIndex]?.page_properties?.marginValue;
    const toEdge = pageBlocks[pageIndex]?.page_properties?.printToEdge;
    const gridMode =
        pageBlocks[pageIndex]?.page_properties?.layout?.gridMode?.active;
    const columnSpace =
        pageBlocks[pageIndex]?.page_properties?.layout?.columnSpace;
    const columnCount =
        pageBlocks[pageIndex]?.page_properties?.layout?.columnCount;

    const {
        SELECT_LAYOUT,
        COLUMN_SPACING,
        PAGE_MARGIN,
        PAGE_BACKGROUND,
        PAGE_BORDER
    } = PAGE_LAYOUT_BLOCKS_ID;

    const setChecked = (event) => {
        const value = event.target.checked;
        setPageBlocks((prevPageBlocks) => {
            return prevPageBlocks.map((page, index) => {
                if (index !== pageIndex) return page; // Return unchanged if not the target page

                return {
                    ...page,
                    page_properties: {
                        ...page?.page_properties,
                        printToEdge: value
                    }
                };
            });
        });
    };

    return (
        <>
            {columnLayout?.data.map((contentTabRows, index) => {
                const isExpanded = columnLayout.expanded[index];
                const shouldHide =
                    contentTabRows.dataId === "column-spacing" &&
                    ["1", "1/2", "grid"].includes(columnCount);
                return (
                    <Fragment key={index}>
                        {!shouldHide && (
                            <CollapseRoot
                                className={`container ${
                                    isExpanded ? "active" : ""
                                }`}
                            >
                                <div className="collapseWrapper">
                                    <span
                                        onClick={() => toggleRow(index)}
                                        className={`expand_section ${
                                            isExpanded ? "active" : ""
                                        }`}
                                    >
                                        <Icon name={"arrowRight"} />
                                    </span>
                                    <span className="layoutName flexBox alignCenter">
                                        {contentTabRows.name}
                                        <Tooltip
                                            style={{ marginLeft: "10px" }}
                                        />
                                    </span>
                                </div>

                                <Collapse
                                    in={isExpanded}
                                    style={{ padding: 0 }}
                                >
                                    <div className="collapseContentWrapper">
                                        {(() => {
                                            switch (contentTabRows?.dataId) {
                                                case SELECT_LAYOUT:
                                                    return (
                                                        <ImageCardStyles className="imageCardWrap layoutBox hasThreeCol">
                                                            {layoutState
                                                                .pageLayout
                                                                .activeOrientation ===
                                                            "portrait" ? (
                                                                <>
                                                                    {portLayout.map(
                                                                        (
                                                                            layout,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <ImageCard
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    icon={
                                                                                        layout.icon
                                                                                    }
                                                                                    label={
                                                                                        layout.label
                                                                                    }
                                                                                    onClick={() =>
                                                                                        handleLayoutClick(
                                                                                            layout.onClick
                                                                                        )
                                                                                    }
                                                                                    isActive={
                                                                                        columnCount ===
                                                                                        layout.isActive
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                                </>
                                                            ) : layoutState
                                                                  .pageLayout
                                                                  .activeOrientation ===
                                                              "landscape" ? (
                                                                <>
                                                                    {landLayout.map(
                                                                        (
                                                                            layout,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <ImageCard
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    icon={
                                                                                        layout.icon
                                                                                    }
                                                                                    label={
                                                                                        layout.label
                                                                                    }
                                                                                    onClick={() =>
                                                                                        handleLayoutClick(
                                                                                            layout.onClick
                                                                                        )
                                                                                    }
                                                                                    isActive={
                                                                                        columnCount ===
                                                                                        layout.isActive
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </ImageCardStyles>
                                                    );
                                                case COLUMN_SPACING:
                                                    return (
                                                        <RangeSlider
                                                            sliderLabel="Adjust Spacing"
                                                            sliderVal={
                                                                columnSpace
                                                            }
                                                            handleSliderChange={(
                                                                event
                                                            ) =>
                                                                handleColSpace(
                                                                    event,
                                                                    "column",
                                                                    100
                                                                )
                                                            }
                                                            min={0}
                                                            max={100}
                                                            step={1}
                                                        />
                                                    );
                                                case PAGE_MARGIN:
                                                    return (
                                                        <>
                                                            <RangeSlider
                                                                sliderLabel="Adjust Margin"
                                                                sliderVal={
                                                                    pageMargin
                                                                }
                                                                handleSliderChange={(
                                                                    event
                                                                ) =>
                                                                    handleColSpace(
                                                                        event,
                                                                        "margin",
                                                                        100
                                                                    )
                                                                }
                                                                min={0}
                                                                max={100}
                                                                step={1}
                                                            />
                                                            {!gridMode ? (
                                                                <CheckBox
                                                                    label="Remove Safe Zone Padding"
                                                                    checked={
                                                                        toEdge
                                                                    }
                                                                    setChecked={
                                                                        setChecked
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    );
                                                case PAGE_BACKGROUND:
                                                    return (
                                                        <PaperBackground
                                                            pageIndex={
                                                                pageIndex
                                                            }
                                                        />
                                                    );
                                                case PAGE_BORDER:
                                                    return (
                                                        <PaperBorder
                                                            pageIndex={
                                                                pageIndex
                                                            }
                                                        />
                                                    );
                                                default:
                                                    return null;
                                            }
                                        })()}
                                    </div>
                                </Collapse>
                            </CollapseRoot>
                        )}
                    </Fragment>
                );
            })}
            <ConfirmAction
                open={handleLayoutChange?.state}
                button1Text="Confirm"
                button2Text="Cancel"
                actionCancel={() =>
                    setHandleLayoutChange({
                        value: "",
                        state: false
                    })
                }
                actionConfirm={handleLayoutConfirm}
                icon="warning"
                dialogText={`Column Layout Change`}
                note="Please be aware that any data within a column that is being removed will be removed from your menu layout. Any custom blocks (titles, paragraphs, and images) will be lost."
            />
        </>
    );
}
