import "./App.css";
import { AuthProvider } from "./context/auth";
import Routing from "./routing/Routing";
import mainTheme from "./themes/MainTheme";
import { useCallback, useEffect, useState } from "react";
import NewVersionAlert from "./components/Dialogs/NewVersionAlert";
import { ThemeProvider } from "@mui/material";

function App({ version: localVersion }: { version: string }) {
    const [showUpdatedMessage, setShowUpdatedMessage] = useState(
        window.location.href.includes("updated")
    );
    const isUpdated = useCallback(
        (targetVersion: string) => {
            // Compare local version with server(target) version to see if a hard reload is required
            const [localMajor, localMinor, localPatch] = localVersion
                .split(".")
                .map(Number);
            const [serverMajor, serverMinor, serverPatch] = targetVersion
                .split(".")
                .map(Number);

            if (localMajor < serverMajor) return false;
            if (localMinor < serverMinor) return false;
            if (localPatch < serverPatch) return false;
            return true;
        },
        [localVersion]
    );

    const handleHardReload = async () => {
        const assets = performance
            .getEntries()
            .filter((x) => x.name.match(new RegExp(".+main.+|.+bundle.+")))
            .map((x) => x.name);
        await fetch(window.location.href, {
            headers: {
                Pragma: "no-cache",
                Expires: "-1",
                "Cache-Control": "no-cache"
            }
        });
        for (const url of assets) {
            await fetch(url, {
                headers: {
                    Pragma: "no-cache",
                    Expires: "-1",
                    "Cache-Control": "no-cache"
                }
            });
        }
        window.location.href.includes("updated") &&
            //@ts-ignore
            window.location.reload(true);
        !window.location.href.includes("updated") &&
            (window.location.href = window.location.href + "?updated");
    };

    const fetchVersion = useCallback(async () => {
        const baseUrl = window.location.origin;
        console.log("Using version " + localVersion);
        const response = await fetch(
            `${baseUrl}/meta.json?no-cache=${Date.now()}`
        );
        const { version } = await response.json();
        const hasLatestVersion = isUpdated(version);
        if (!hasLatestVersion) {
            handleHardReload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
        fetchVersion();
    }, [fetchVersion]);
    return (
        <ThemeProvider theme={mainTheme}>
            <AuthProvider>
                <NewVersionAlert
                    showUpdatedMessage={showUpdatedMessage}
                    setShowUpdatedMessage={setShowUpdatedMessage}
                />
                <Routing />
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
