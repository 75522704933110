import { styled } from "@mui/system";
import { FormControl, Tabs } from "@mui/material";

const MyTabPanel = styled("div")(({ theme }) => ({
    "&.tabsContentWrapper": {
        width: 294,
        boxShadow: "5px 0px 16px 0px #22222208",
        borderRight: "1px solid #dadada",
        height: "100%",
        backgroundColor: "#ffffff",
        position: "relative",
        transition: "all 0.5s",
        padding: "20px 0",
        overflowY: "scroll",
        scrollbarWidth: "thin",
        "&.hideDrawer": {
            width: 0,
            opacity: 0,
            visibility: "hidden"
        }
    },
    "& .noBlockStyle": {
        maxWidth: 150,
        textAlign: "center",
        margin: "0 auto",
        padding: "32px 0",
        "&.layoutTab": {
            maxWidth: 240,
            padding: "32px 10px",
            "& svg": {
                width: 28,
                height: "auto",
                "& path": {
                    fill: "#9E9E9E"
                }
            }
        },
        "& p": {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "20px",
            marginTop: 10,
            color: "rgba(0,0,0,0.4)"
        }
    },
    "& .invisibleColorPicker": {
        opacity: 0,
        cursor: "pointer",
        padding: "0px !important",
        width: "30px",
        height: "30px",
        "&::-webkit-file-upload-button": {
            cursor: "pointer"
        }
    },
    "& .fontTextField": {
        width: "65px",
        marginLeft: 10,
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "30px"
        },
        "& input": {
            padding: "5px 12px !important",
            "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                opacity: 1
            }
        },
        "& input, div": {
            fontSize: 14
        }
    },
    "& .colorPickerContainer": {
        "& .cancelIcon": {
            marginRight: 10,
            cursor: "pointer",
            "& path": {
                transition: "stroke 0.3s"
            },
            "&:hover": {
                "& path": {
                    stroke: "#5A5A5A"
                }
            }
        }
    },
    "& .borderStyles": {
        marginBottom: "20px",
        "& .borderBlock": {
            height: "28px",
            width: "28px",
            border: "1px solid #D3D3D3",
            transition: "border 0.3s, boxShadow 0.3s",
            cursor: "pointer",
            borderRadius: "4px",
            position: "relative",

            "&:after": {
                content: "''",
                width: "14px",
                height: "2px",
                backgroundColor: "#5a5a5a",
                position: "absolute",
                top: "6px",
                left: "6px"
            },
            "&:before": {
                width: "14px",
                height: "14px",
                content: "''",
                backgroundColor: "#d3d3d3",
                display: "block",
                borderRadius: "1px"
            },
            "&:hover": {
                borderColor: "#5a5a5a"
            },
            "&.active": {
                borderColor: "#B84F03",
                boxShadow: "1px 1px 4px 0px #0000001F",
                "&:after": { backgroundColor: "#B84F03" }
            },
            "& + .borderBlock": {
                marginLeft: 14
            },
            "&.right:after": {
                width: "2px",
                height: "14px",
                top: "6px",
                right: "6px",
                left: "auto"
            },
            "&.bottom:after": { bottom: "6px", top: "auto" },
            "&.left:after": {
                width: "2px",
                height: "14px",
                top: "6px",
                left: "6px"
            }
        }
    },
    "& .pageBorderWrapper": {
        "&.paperBorder": {
            "& .input-select .MuiSelect-select": {
                minHeight: 30,
                paddingTop: 4,
                paddingBottom: 4
            },
            "& .fontTextField": {
                marginLeft: "16px"
            },
            "& .styleOption": {
                marginLeft: "16px"
            }
        },
        "& .numberCount": {
            margin: "0 !important",
            "+ .numberCount": {
                "& .fontTextField fieldset": {
                    // borderLeftWidth: 0
                }
            },
            "&:first-of-type": {
                "& .fontTextField fieldset": {
                    borderRadius: "30px 0 0 30px"
                }
            },
            "&:last-of-type": {
                "& .fontTextField fieldset": {
                    borderRadius: "0 30px 30px 0"
                }
            }
        },
        "& .pageBorderCorners": {
            "& .fontTextField ": {
                margin: "0 !important",
                width: "63px",
                "& fieldset": {
                    borderRadius: 0
                },
                "& input": {
                    padding: "5px 5px 5px 15px !important"
                }
            }
        },
        "& .borderCorners": {
            marginBottom: 10
        }
    },
    "& .locationWrapper": {
        marginRight: "15px",
        "& > .MuiFormControl-root": {
            width: "108px",
            border: "1px solid #D3D3D3",
            borderRadius: "10px",
            padding: "5px",
            "& .MuiFormControlLabel-root": {
                width: "32px",
                height: "32px",
                margin: 0,
                justifyContent: "center",
                "& .MuiRadio-root": {
                    padding: 5
                }
            }
        }
    },
    "& .backgroundWrapper": {
        "& .coverInfo": {
            fontSize: 12,
            color: "#b4b3b3",
            fontStyle: "italic"
        }
    },
    "& .borderWidth": {
        marginRight: "16px"
    }
}));

const MyTabs = styled(Tabs)({
    backgroundColor: "#CC5803",
    height: "100%",
    boxShadow: "1px 0px 6px 0px #22222226",
    padding: "20px 0",
    position: "relative",
    zIndex: 1,
    "& .labelText": {
        color: "#fff",
        textTransform: "capitalize",
        fontSize: 14,
        fontWeight: 500
    },
    "& .MuiTab-root": {
        transition: "all 300ms linear,opacity 0ms",
        padding: "16px 8px",
        minWidth: "70px",
        minHeight: "90px",
        "& > svg path": {
            fill: "#fff"
        },
        "&:hover": {
            backgroundColor: "rgba(255,255,255,0.15)"
        },
        "&.Mui-selected": {
            backgroundColor: "#fff",
            color: "#dadada",
            "& > svg path": {
                fill: "#222"
            },
            "& .labelText": {
                color: "#3a3a3a"
            }
        }
    },
    ".MuiTabs-indicator": {
        display: "none" // Hide the indicator
    }
});

const SelectRoot = styled(FormControl)(({ theme }) => ({
    "& .MuiFormLabel-root": {
        fontSize: 12,
        fontWeight: 500,
        color: "#222",
        transform: "none",
        top: "-8px",
        left: "14px"
    },
    "& .input-select": {
        borderRadius: "50px",
        "& .MuiSelect-select": {
            minHeight: "44px",
            boxSizing: "border-box",
            padding: "10px 30px 10px 20px",
            fontSize: "14px",
            fontWeight: 400,
            color: "#b4b3b3",
            opacity: 1,
            textTransform: "capitalize"
        },
        "& fieldset": {
            borderColor: "#d9d9d9"
        }
    },
    "& .required-star": {
        color: theme.colors.orange[700]
    },
    "& .paperSize-label": {
        backgroundColor: "#fff",
        paddingRight: 3
    }
}));

export { MyTabPanel, MyTabs, SelectRoot };
