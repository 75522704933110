import ReactDOM from "react-dom/client";
import App from "./App";
import packageJSON from "../package.json";
import { IntercomProvider } from "react-use-intercom";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <IntercomProvider
        appId={process.env.REACT_APP_INTERCOM_APP_ID || ""}
        autoBoot={true}
    >
        <App version={packageJSON.version} />
    </IntercomProvider>
);
