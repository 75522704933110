import { Modal, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Root } from "./styles";
import Icon from "../UI/Icon";
import Button from "../Button";

const ConfirmAction = ({
    itemLoading = false,
    disableBtn = false,
    open = true,
    icon = "duplicate",
    dialogText,
    note = "",
    actionConfirm,
    actionSecondButton = null,
    actionCancel,
    params = [],
    children = null,
    button1Text = "Confirm",
    button2Text = "Cancel"
}) => {
    const [disable, setDisable] = useState(false);
    const [parameters, setParameters] = useState([]);

    const clickConfirm = (...props) => {
        setDisable(true);
        setTimeout(async () => {
            await actionConfirm(...props);
            setDisable(false);
        }, 200);
    };

    useEffect(() => {
        setParameters([...params]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (value, index) => {
        const newParameters = parameters.map((item, i) => {
            if (index === i) {
                return { ...item, value };
            }
            return item;
        });
        setParameters(newParameters);
    };

    return (
        <Modal open={open} onClose={actionCancel} disablePortal>
            <Root className="modal">
                <span className="sectionClose">
                    <span
                        className="closeButtonContainer"
                        onClick={actionCancel}
                    >
                        <Icon name="clear" className="closeButton" />
                    </span>
                </span>
                <span className="iconBox">
                    <Icon className="icon" name={icon} />
                </span>
                <p className="message">{dialogText}</p>
                {typeof note === "string" && <p className="infoText">{note}</p>}
                {typeof note !== "string" && note}
                <hr className="hrow hr" />
                {parameters.length > 0 && (
                    <div className="dialogForm">
                        {parameters.map((item, index) => (
                            <React.Fragment key={index}>
                                <p className="fieldDescription">
                                    {item.description}
                                </p>
                                <TextField
                                    fullWidth
                                    className="textField"
                                    label={item.label}
                                    value={item.value}
                                    onChange={(event) =>
                                        handleChange(event.target.value, index)
                                    }
                                />
                            </React.Fragment>
                        ))}
                    </div>
                )}
                {children && <div className="dialogForm">{children}</div>}

                <span className="actions flexBox alignCenter">
                    {button1Text !== "" ? (
                        <Button
                            innerText={button1Text}
                            type="primary"
                            color="orange"
                            disabled={disableBtn ? disableBtn : disable}
                            onClick={() =>
                                clickConfirm(...parameters.map((x) => x.value))
                            }
                            itemLoading={itemLoading}
                            disableBtn={disableBtn}
                        />
                    ) : (
                        ""
                    )}
                    {typeof button2Text === "object" ? (
                        button2Text
                    ) : button2Text !== "" ? (
                        <Button
                            onClick={() => {
                                typeof actionSecondButton === "function"
                                    ? actionSecondButton()
                                    : actionCancel();
                            }}
                            innerText={button2Text}
                            type="secondary"
                            color="orange"
                            itemLoading={itemLoading}
                            disableBtn={disableBtn}
                        />
                    ) : (
                        ""
                    )}
                </span>
            </Root>
        </Modal>
    );
};

export default ConfirmAction;
