import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
const Root = styled(Box)(() => ({
    "&.blockWrapper": {
        position: "relative",
        display: "flex",
        height: "100%",
        "&.sectionBlock": {
            overflow: "hidden"
        },
        "&.imageBlockWrapper": {
            justifyContent: "center"
        }
    },
    "& .textBlock": {
        width: "100%"
    },
    "& .focusableBlock": {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        border: "2px solid transparent",
        transition: "border 0.3s, opacity 0.3s",
        opacity: 0,
        "&:hover": {
            opacity: 0.5,
            borderColor: "#1890FF"
        },
        "&.active": {
            opacity: 1,
            borderColor: "#1890FF",
            "& .editBlock": {
                display: "flex"
            }
        }
    },
    "& .editBlock": {
        zIndex: 3,
        position: "absolute",
        backgroundColor: "#1890FF",
        left: "50%",
        bottom: "100%",
        transform: "translateX(-50%)",
        padding: "0 6px",
        borderRadius: "15px 15px 0 0",
        alignItems: "center",
        justifyContent: "center",
        display: "none",
        "& span": {
            lineHeight: 0
        },
        "& svg": {
            margin: "8px",
            cursor: "pointer",
            "&.sortIcon": {
                height: "13px",
                width: "13px",
                cursor: "grab",
                "&:focus": {
                    cursor: "grabbing"
                }
            },
            "&.deleteIcon": {
                height: "17px",
                width: "17px"
            },
            "&.editIcon": {
                height: "14px",
                width: "14px"
            },
            "& path": {
                fill: "#fff"
            }
        }
    },
    "& .editableBlock": {
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        boxShadow: "none",
        position: "relative",
        width: "100%",
        height: "100%",
        resize: "none"
    }
}));

export { Root };
