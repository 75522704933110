import { styled } from "@mui/system";

const Root = styled("img")({
    "&.logo": {
        transition: "opacity 0.3s ease-in-out",
        "&.imageLoaded": {
            opacity: 1
        },
        "&.imageLoading": {
            opacity: 0
        }
    }
});

export { Root };
