import { useEffect, useRef, useState } from "react";
import { Root } from "../style";
import Icon from "../../../../UI/Icon";
import { usePrinterContext } from "../../../../../context/printer";
import ContentEditable from "react-contenteditable";

const EditableTextBlock = ({
    text,
    editable = false,
    styles,
    focusableBlock: FocusableBlock
}) => {
    const textRef = useRef(null);
    const [disabled, setDisabled] = useState(true);
    const [editableText, setEditableText] = useState(text);
    const [isTyping, setIsTyping] = useState(false);
    const [blockData, setBlockData] = useState({});

    useEffect(() => {
        if (!isTyping) {
            setEditableText(text);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    const { pageBlocks, setPageBlocks } = usePrinterContext();

    const handleEdit = () => {
        setDisabled(false);
        textRef.current.style.zIndex = "2";
        setTimeout(() => {
            textRef.current.focus();
        }, 200);
    };

    // Dynamic styles
    const {
        fontFamily,
        fontWeight,
        fontSize,
        lineHeight,
        textAlign,
        fontStyle,
        letterSpacing,
        // boldFont,
        color,
        upperCase
    } = { ...styles };

    const customStyles = {
        fontFamily,
        fontWeight:
            // boldFont === "bold" ? "bold" :
            fontWeight,
        fontSize: fontSize ? `${fontSize}px` : "",
        lineHeight: lineHeight ? `${lineHeight}px` : "",
        textAlign,
        fontStyle,
        letterSpacing: `${letterSpacing === 0 ? 0 : letterSpacing / 10}px`,
        color,
        textTransform: upperCase ? "uppercase" : "none"
    };

    useEffect(() => {
        let returnedEvent = window.addEventListener(
            "sendStylesEvent",
            (event) => {
                setBlockData(event?.detail);
            }
        );
        return () => returnedEvent;
    }, []);

    const handleTextChange = (e) => {
        setIsTyping(true);
        const newText = e.target.value;
        setEditableText(newText);
    };

    const handleTextPaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        document.execCommand("insertText", false, text);
    };

    useEffect(() => {
        if (isTyping) {
            const submitTimeout = setTimeout(async () => {
                const updatedPageBlocks = [...pageBlocks];
                const blockToUpdate =
                    updatedPageBlocks[blockData?.pageIndex]?.row[
                        blockData?.rowIndex
                    ]?.column[blockData?.columnIndex]?.blocks[
                        blockData?.blockIndex
                    ];

                if (blockToUpdate) {
                    blockToUpdate.data.text = editableText;
                    // Update the state with the modified pageBlocks
                    setPageBlocks(updatedPageBlocks);
                    setIsTyping(false);
                }
            }, 1500);

            return () => clearTimeout(submitTimeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableText]);

    return (
        <Root className="blockWrapper">
            <ContentEditable
                style={customStyles}
                className="editableBlock textBlock"
                innerRef={textRef}
                html={editableText} // innerHTML of the editable div
                disabled={disabled} // use true to disable editing
                onChange={(e) => handleTextChange(e)} // handle innerHTML change
                onPaste={(e) => handleTextPaste(e)} // handle pasting content
                tagName="p" // Use a custom HTML tag (uses a div by default)
                onBlur={() => {
                    textRef.current.style.zIndex = "0";
                    setDisabled(true);
                }}
            />
            {FocusableBlock && (
                <FocusableBlock>
                    {editable && (
                        <Icon
                            onClick={() => handleEdit()}
                            className="editIcon"
                            name="pencil"
                        />
                    )}
                </FocusableBlock>
            )}
        </Root>
    );
};
export default EditableTextBlock;
