import { Root } from "../style";
import { Box } from "@mui/system";
import Icon from "../../../../../UI/Icon";
import { Chip, Collapse } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { usePrinterContext } from "../../../../../../context/printer";
import { CollapseRoot } from "../../../../../UI/CommonStyles";
import { RangeSlider } from "../../../../../UI/CommonComponents";
import ConfirmAction from "../../../../../Modals";
import { cloneDeep, isEqual } from "lodash";
import BlockData from "../../../../../../const/BlockData";
import { saveImage } from "../../../../../../utils/mediaHelper";
import { useAlertContext } from "../../../../../../context/alert";

const imageStylesData = [{ name: "Image Styles", dataId: "image-styles" }];

const ImageStyles = ({ data }) => {
    const {
        menu,
        pageBlocks,
        setPageBlocks,
        setSectionBlock,
        sectionBlock,
        setImageLoading,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray
    } = usePrinterContext();

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex];

    const [expanded, setExpanded] = useState(false);
    const [logo, setLogo] = useState(blockToUpdate?.data?.url);
    const [file, setFile] = useState(null);
    const [deleteImage, setDeleteImage] = useState(false);
    const [imageError, setImageError] = useState("");
    const [activeAlignment, setActiveAlignment] = useState(
        blockToUpdate?.data?.styles?.alignment
    );
    const [imageWidth, setImageWidth] = useState(
        blockToUpdate?.data?.styles?.width
    );
    const [applyAllModal, setApplyAllModal] = useState(false);

    const { BLOCKTYPE } = BlockData();
    const { setAlertState, setAlert } = useAlertContext();

    const dataBlock = data?.blockType
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    const handleImageChange = (e) => {
        if (e.target.files[0]?.size) {
            if (e.target.files[0].size > 5000000) {
                setImageError(
                    "*Your file was too large to upload. The maximum file size is 5 MB."
                );
                setLogo("");
                setFile(null);
                e.target.value = null;
                return;
            }
            if (
                ![
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/svg+xml"
                ].includes(e.target.files[0].type)
            ) {
                setImageError(
                    "*Unsupported file type. Please upload only a JPG, PNG, or SVG file."
                );
                setLogo("");
                setFile(null);
                e.target.value = null;
                return;
            } else {
                setDependencyChanged(true);
                setImageError("");
                setFile(e.target.files[0]);
                setDeleteImage(false);
                const url = URL.createObjectURL(e.target.files[0]);
                setLogo(url);
                e.target.value = null;
            }
        }
    };

    useEffect(() => {
        if (undoRedoState?.state && isEqual(data, sectionBlock.data)) {
            return;
        }

        setLogo(blockToUpdate?.data?.url);
        setImageWidth(blockToUpdate?.data?.styles?.width);
        setActiveAlignment(blockToUpdate?.data?.styles?.alignment);
        setSectionBlock({ data: data, pageBlocks: pageBlocks });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data.pageIndex,
        data.rowIndex,
        data.columnIndex,
        data.blockIndex,
        undoRedoState
    ]);

    const [dependencyChanged, setDependencyChanged] = useState(false);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            width: imageWidth,
            alignment: activeAlignment
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex];
        if (blockToUpdate) {
            blockToUpdate.data.styles = {
                ...blockToUpdate.data.styles,
                ...newStyles
            };

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }

        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageWidth, activeAlignment]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex];

        let successUpload = true,
            logo_url = "";

        const updateData = async () => {
            if (file) {
                setImageLoading(true);
                [successUpload, logo_url] = await saveImage(
                    file,
                    menu?.identifier,
                    "logos"
                );
            } else if (!deleteImage) {
                logo_url = blockToUpdate?.data?.url || "";
            }

            if (deleteImage) {
                logo_url = "";
            }

            if (!successUpload) {
                logo_url = "";
                setAlert({
                    type: "error",
                    message:
                        "There was a problem while trying to save your image, please try again in a few seconds"
                });
                setAlertState(true);
                return;
            }

            if (blockToUpdate) {
                if (data?.blockType === BLOCKTYPE.MENU_IMAGE) {
                    blockToUpdate.data.url = logo_url;
                }

                // Update the state with the modified pageBlocks
                setPageBlocks(updatedPageBlocks);
            }
            setImageLoading(false);

            setSectionBlock({
                pageBlocks: updatedPageBlocks
            });
            setDependencyChanged(false);
        };

        updateData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logo]);

    const handleAlignment = (alignment) => {
        setDependencyChanged(true);
        setActiveAlignment(alignment);
    };

    const handleWidthChange = (event, max) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = parsedValue < max ? parsedValue : max;
        if (typeof newValue === "number") {
            setDependencyChanged(true);
            setImageWidth(newValue);
        }
    };

    const toggleRow = useCallback((index) => {
        setExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }, []);

    const handleApplyAll = () => {
        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.styles;

        if (blockToUpdate) {
            updatedPageBlocks.forEach((pageBlock) => {
                pageBlock.row.forEach((rowBlock) => {
                    rowBlock.column.forEach((colBlock) => {
                        colBlock?.blocks.forEach((block) => {
                            if (block.blockType === data.blockType) {
                                block.data.styles = { ...blockToUpdate };
                            }
                        });
                    });
                });
            });

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
            setApplyAllModal(false);
        }
    };

    const InfoMessage = () => {
        return (
            <p className="infoText">
                This will apply the current <strong>{dataBlock}</strong> to the
                entire menu.
            </p>
        );
    };

    return (
        <Root className="imageStylesWrapper">
            {imageStylesData?.map((block, index) => {
                const isExpanded = expanded[index];
                return (
                    <CollapseRoot
                        key={index}
                        className={`container ${isExpanded ? "active" : ""}`}
                    >
                        <div className="collapseWrapper">
                            <span
                                onClick={() => toggleRow(index)}
                                className={`expand_section ${
                                    isExpanded ? "active" : ""
                                }`}
                            >
                                <Icon name={"arrowRight"} />
                            </span>
                            <Box className="flexBox justifyBetween flex1 alignCenter">
                                <span className="layoutName">{block.name}</span>
                                {isExpanded ? (
                                    <Chip
                                        className="applyAllLabel"
                                        label="Apply to all"
                                        onClick={() => {
                                            setApplyAllModal(true);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                            </Box>
                        </div>
                        <Collapse
                            in={isExpanded}
                            style={{ padding: 0 }}
                            className="collapseData"
                        >
                            <Box className="imageStylesInner collapseContentWrapper">
                                {data?.blockType === BLOCKTYPE.MENU_IMAGE ? (
                                    <Box sx={{ mb: 3 }}>
                                        <div className="imageContainer flexBox justifyCenter alignCenter columnDirection">
                                            <input
                                                type="file"
                                                title=""
                                                className="fileInput"
                                                onChange={handleImageChange}
                                            />

                                            <Icon
                                                name="remove"
                                                className="deleteImage"
                                                style={
                                                    logo
                                                        ? {}
                                                        : {
                                                              display: "none"
                                                          }
                                                }
                                                onClick={() => {
                                                    setLogo("");
                                                    setDeleteImage(true);
                                                    setFile(null);
                                                    setDependencyChanged(true);
                                                }}
                                            />
                                            {logo && (
                                                <img
                                                    className="image"
                                                    src={logo}
                                                    alt="Restaurant logo"
                                                />
                                            )}
                                            {!logo && (
                                                <>
                                                    <Icon name="noImage" />
                                                    <p className="noImageText">
                                                        Upload or Drag Image.
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        <span className="imageError">
                                            {imageError}
                                        </span>
                                    </Box>
                                ) : (
                                    ""
                                )}
                                <RangeSlider
                                    sliderLabel="Image Size"
                                    sliderVal={imageWidth}
                                    handleSliderChange={(event) =>
                                        handleWidthChange(event, 100)
                                    }
                                    min={0}
                                    max={100}
                                    step={1}
                                />
                                <Box className="alignmentStyles flexBox alignCenter justifyBetween">
                                    <Box className="fontLabel mb-0">
                                        Image Alignment
                                    </Box>
                                    <Box className="flexBox alignCenter">
                                        <Icon
                                            id="left-align"
                                            name="leftAlignIcon"
                                            className={`styleOption ${
                                                activeAlignment === "left"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                handleAlignment("left")
                                            }
                                        />
                                        <Icon
                                            id="center-align"
                                            name="centerAlignIcon"
                                            className={`styleOption ${
                                                activeAlignment === "center"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                handleAlignment("center")
                                            }
                                        />
                                        <Icon
                                            id="right-align"
                                            name="rightAlignIcon"
                                            className={`styleOption ${
                                                activeAlignment === "right"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                handleAlignment("right")
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Collapse>
                    </CollapseRoot>
                );
            })}
            <ConfirmAction
                open={applyAllModal}
                button1Text="Apply to All"
                actionConfirm={handleApplyAll}
                actionCancel={() => setApplyAllModal(false)}
                icon="warning"
                dialogText={`Apply changes to all ${dataBlock}`}
                note={<InfoMessage />}
            />
        </Root>
    );
};
export default ImageStyles;
