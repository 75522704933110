import { memo } from "react";
import { Box } from "@mui/system";
import { Root } from "./style";
import Icon from "../../../UI/Icon";
import SectionStyles from "./StylesBlock/SectionStyles";
import DividerStyles from "./StylesBlock/DividerStyles";
import ImageStyles from "./StylesBlock/ImageStyles";
import TextStyles from "./StylesBlock/TextStyles";
import { usePrinterContext } from "../../../../context/printer";
import BlockData, {
    DEFAULT_BACKGROUND,
    DEFAULT_BORDER,
    DEFAULT_PADDING
} from "../../../../const/BlockData";
import { Tooltip } from "../../../UI/CommonComponents";
import ContainerStyle from "./StylesBlock/ContainerStyle";
import ItemsStyles from "./StylesBlock/ItemStyles";
import Button from "../../../Button";
import CustomTooltip from "../../../UI/CustomTooltip/CustomTooltip";
import ConfirmAction from "../../../Modals";

export default memo(function Styles({ blockData }) {
    const { pageBlocks, setPageBlocks, revertItemStyle, setRevertItemStyle } =
        usePrinterContext();

    const { BLOCKTYPE } = BlockData();

    let blockText = blockData?.blockType;
    if (blockText === BLOCKTYPE.MENU_IMAGE) {
        blockText = "Image";
    } else if (blockText === BLOCKTYPE.CUSTOM_TITLE) {
        blockText = "Title";
    } else if (blockText === BLOCKTYPE.CUSTOM_PARAGRAPH) {
        blockText = "Paragraph";
    } else if (blockText === BLOCKTYPE.SECTION_ITEMS) {
        blockText = `${
            pageBlocks[blockData?.pageIndex]?.row[blockData?.rowIndex]?.column[
                blockData?.columnIndex
            ]?.blocks[blockData?.blockIndex]?.data?.items?.displayName
        } Section`;
    } else if (blockText === BLOCKTYPE.COLUMN_CONTAINER) {
        blockText = BLOCKTYPE.COLUMN_CONTAINER;
    } else if (blockText === BLOCKTYPE.ITEM_STYLES) {
        blockText = "Item Styles";
    }

    const updatedPageBlocks = [...pageBlocks];
    const defaultStyles =
        updatedPageBlocks[blockData?.pageIndex]?.row[blockData?.rowIndex]
            ?.column[blockData?.columnIndex]?.blocks[blockData?.blockIndex]
            ?.data?.styles?.item;
    const blockToUpdate =
        updatedPageBlocks[blockData?.pageIndex]?.row[blockData?.rowIndex]
            ?.column[blockData?.columnIndex]?.blocks[blockData?.blockIndex]
            ?.data?.items?.items[blockData?.itemIndex];
    const itemStyleEnabled = blockToUpdate?.styles?.enabled;

    const defaultItemStyle = () => {
        const newStyle = {
            enabled: false,
            background: DEFAULT_BACKGROUND,
            border: DEFAULT_BORDER,
            padding: DEFAULT_PADDING,
            itemLayout: defaultStyles?.itemLayout,
            itemPrice: defaultStyles?.itemPrice,
            itemName: defaultStyles?.itemName,
            itemDescription: defaultStyles?.itemDescription
        };
        blockToUpdate.styles = {
            ...blockToUpdate.style,
            ...newStyle
        };
        setPageBlocks(updatedPageBlocks);

        setRevertItemStyle(false);
    };

    return (
        <Root>
            <Box className="dragItemsTitle">
                <Box className="flexBox alignCenter">
                    {blockText ? (
                        <>
                            Edit{" "}
                            {blockText
                                .split("-")
                                .map((word) =>
                                    word
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                            (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                        )
                                        .join(" ")
                                )
                                .join(" ")}
                        </>
                    ) : (
                        "Menu Styles"
                    )}
                    <Tooltip />
                </Box>
                {blockData?.blockType === BLOCKTYPE.ITEM_STYLES ? (
                    <CustomTooltip
                        title={`${
                            !itemStyleEnabled
                                ? "Using default styles"
                                : "Clear custom styles"
                        }`}
                    >
                        <Box>
                            <Button
                                className={`defaultItemStyle ${
                                    !itemStyleEnabled ? "disabled" : "active"
                                }`}
                                innerText={
                                    !itemStyleEnabled ? "Default" : "Revert"
                                }
                                onClick={() =>
                                    itemStyleEnabled
                                        ? setRevertItemStyle(true)
                                        : ""
                                }
                            />
                        </Box>
                    </CustomTooltip>
                ) : (
                    ""
                )}
            </Box>

            <Box className="stylesBlock">
                {(() => {
                    switch (blockData?.blockType) {
                        case BLOCKTYPE.CUSTOM_PARAGRAPH:
                        case BLOCKTYPE.CUSTOM_TITLE:
                        case BLOCKTYPE.MENU_HEADER:
                        case BLOCKTYPE.MENU_FOOTER:
                        case BLOCKTYPE.MENU_TITLE:
                            return <TextStyles data={blockData} />;
                        case BLOCKTYPE.MENU_IMAGE:
                        case BLOCKTYPE.RESTAURANT_LOGO:
                            return <ImageStyles data={blockData} />;
                        case BLOCKTYPE.DIVIDER:
                            return <DividerStyles data={blockData} />;
                        case BLOCKTYPE.SECTION_ITEMS:
                            return <SectionStyles data={blockData} />;
                        case BLOCKTYPE.ITEM_STYLES:
                            return <ItemsStyles data={blockData} />;
                        case BLOCKTYPE.COLUMN_CONTAINER:
                            return <ContainerStyle data={blockData} />;

                        default:
                            return (
                                <Box className="noBlockStyle">
                                    <Icon name="stylesIcon" />
                                    <p>
                                        Select a content block in your menu to
                                        style.
                                    </p>
                                </Box>
                            );
                    }
                })()}
                <ConfirmAction
                    open={revertItemStyle}
                    actionConfirm={defaultItemStyle}
                    actionCancel={() => setRevertItemStyle(false)}
                    icon="refresh"
                    dialogText="Do you want to clear custom item styles?"
                    note={
                        "This action will clear any custom item styles (border, background, etc.) and revert the item back to the section’s default item styles."
                    }
                />
            </Box>
        </Root>
    );
});
