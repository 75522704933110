import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
const ItemRoot = styled(Box)(() => ({
    "&.ItemBlock": {
        transition: "all 0.3s",
        position: "relative",
        "&:hover": {
            background: "rgba(34,34,34, 0.05)",
            "& .itemSort": {
                opacity: 1
            }
        },
        "&.selectedItem, &.selectedItem:hover": {
            boxShadow: "0px 0px 0px 1px #1890FF",
            background: "#1890FF0D",
            "& .itemSort": {
                opacity: 1
            }
        },
        "&.dragging": {
            background: "#F4F4F4",
            "& .itemSort": {
                opacity: 1
            }
        },
        "&.draftItem": {
            display: "none"
        },
        "& .itemSort": {
            position: "absolute",
            right: 0,
            bottom: 0,
            background: "#52A6F3",
            borderRadius: "4px 0 0 0",
            border: "1px solid #1890FF",
            opacity: 0,
            transition: "opacity 0.3s",
            overflow: "hidden",
            maxHeight: "100%",
            zIndex: 2,
            "& > span": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 20,
                width: 20,
                transition: "background 0.3s",
                "&:hover": {
                    backgroundColor: "#1890FF"
                },
                "&.edit-item": {
                    cursor: "pointer",
                    "& svg": {
                        width: 12,
                        height: 12,
                        "& path": {
                            fill: "#fff"
                        }
                    }
                }
            }
        },
        "& .ItemTitle": {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2px",
            // overflow: "hidden",
            "& .ItemName": {
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "22px",
                marginRight: 10,
                flex: 1,
                "& .indented-text": {
                    textIndent: "-10px",
                    paddingLeft: "10px",
                    whiteSpace: "pre-wrap",
                    display: "block"
                },
                "&.subSectionTitle": {
                    fontSize: 16,
                    lineHeight: "16px",
                    fontWeight: 700
                }
            }
        },
        "& .ItemPrice": {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "22px",
            whiteSpace: "nowrap",
            "&:empty": {
                display: "none !important"
            }
        },
        "& .itemDescription": {
            fontSize: 13,
            fontWeight: 400,
            lineHeight: "20px",
            fontStyle: "italic"
        },
        "& .ItemsNotations": {
            marginTop: "2px",
            width: "100%",
            "& span": {
                fontSize: 10,
                fontWeight: 400,
                lineHeight: 1.4,
                fontStyle: "italic",
                border: "1px solid #000000",
                borderRadius: "20px",
                marginRight: "8px",
                marginBottom: "4px",
                padding: "2px 8px",
                display: "inline-block"
            }
        }
    }
}));

const Root = styled(Box)(() => ({
    width: "100%",
    "&.hasFocus": {
        position: "relative",
        zIndex: 2,
        "& .ItemBlock": {
            "&:hover": {
                background: "rgba(34,34,34, 0.05)",
                "& .itemSort": {
                    opacity: 1
                }
            }
        }
    },
    "& hr": {
        border: "none",
        borderBottom: "2px solid #000000",
        flex: 1
    },
    "& .sectionName": {
        fontSize: "22px",
        lineHeight: "32px",
        fontWeight: 600
    },
    "& .ItemsList": {
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        columnGap: "20px",
        paddingTop: 15
    },
    "& .modifierPrice:empty": {
        display: "none"
    },
    "& .sectionHeader": {
        fontStyle: "italic",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#000000"
    }
}));

export { Root, ItemRoot };
