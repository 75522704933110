import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
const Root = styled(Box)(({ theme }) => ({
    "& .styleOption": {
        border: "1px solid transparent",
        transition: "border 0.3s, boxShadow 0.3s",
        cursor: "pointer",
        borderRadius: "4px",
        display: "flex",
        "& path": {
            transition: "fill 0.3s"
        },
        "&:hover, &.active": {
            borderColor: "#d3d3d3",
            "& path": {
                fill: "#5a5a5a"
            }
        },
        "&.active": {
            borderColor: "#5a5a5a",
            boxShadow: "1px 1px 4px 0px #0000001F"
        },
        "& + .styleOption": {
            marginLeft: 8,
            cursor: "pointer"
        }
    },
    "&.stylingChanges": {
        "& .fontFamilyOption": {
            display: "flex"
        },
        "& .alignmentStyles": {
            marginTop: 25
        },
        "& .fontStylesOption": {
            marginTop: 25
        },
        "& .fontsSelect": {
            flex: "1",
            marginLeft: 12,
            "& .autocompleteBox ~ .autocompleteBox": {
                marginTop: 16
            }
        },
        "& .fontLabel": {
            marginTop: 7
        },

        "& .fontSizeOption": {
            marginTop: 16,
            display: "flex",
            alignItems: "center"
        },
        "& .numberCount": {
            display: "flex",
            alignItems: "center",
            "&.isFocused": {
                "& svg path": {
                    fill: "#cc5803"
                }
            },
            "+ .numberCount": {
                marginLeft: 15
            }
        }
    },
    "&.imageStylesWrapper": {
        "& .imageContainer": {
            marginTop: 4,
            height: 170,
            border: "1px dashed rgba(180, 179, 179, 0.5)",
            borderRadius: 20,
            padding: 32,
            position: "relative",
            textAlign: "center",
            "&:hover": {
                "& svg:nth-of-type(2)": {
                    "& path": {
                        fill: theme.colors.grey[700]
                    }
                },
                "& p": {
                    opacity: 1
                }
            }
        },
        "& .image": {
            maxWidth: "100%",
            height: "100%",
            objectFit: "scale-down"
        },
        "& .deleteImage": {
            position: "absolute",
            top: 16,
            right: 18,
            width: 16,
            cursor: "pointer",
            "& path": {
                fill: theme.colors.grey[500]
            },
            "&:hover": {
                "& path": {
                    fill: theme.colors.grey[700]
                }
            }
        },
        "& .fileInput": {
            opacity: 0,
            position: "absolute",
            top: 0,
            left: 0,
            cursor: "pointer",
            height: "100%",
            width: "100%"
        },
        "& .noImageText": {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "14px",
            marginTop: 8,
            marginBottom: 0,
            opacity: 0.4,
            color: `${theme.colors.grey[900]}a6`
        },
        "& .imageError": {
            color: "#D8000C",
            fontStyle: "italic",
            fontSize: 12,
            marginTop: 8
        },
        "& .imageSizeBox": {
            "& .fontLabel": {
                marginBottom: 10
            }
        },
        "& .alignmentStyles": {
            marginTop: 15
        },
        "& .collapseData": {
            "& .collapseContentWrapper": {
                marginTop: 20
            }
        }
    },
    "&.dividerBlockWrapper": {
        padding: "20px"
    },
    "& .columnContainerBlock": {
        padding: "22px",
        borderBottom: "1px solid #D9D9D9",
        "&.columnCount": {
            "& .MuiFormControlLabel-root": {
                marginLeft: "-5px",
                marginRight: "10px",
                "&:last-of-type": {
                    marginRight: 0
                },
                "& .MuiRadio-root": {
                    paddingRight: "4px",
                    paddingLeft: 0
                }
            }
        },
        "& .blockTitle": {
            marginBottom: 12
        }
    },
    "& .containerPadding": {
        marginBottom: 10,
        "& .numberCount": {
            margin: "0 !important",
            "+ .numberCount": {
                "& .fontTextField fieldset": {
                    // borderLeftWidth: 0
                }
            },
            "&:first-of-type": {
                "& .fontTextField fieldset": {
                    borderRadius: "30px 0 0 30px"
                }
            },
            "&:last-of-type": {
                "& .fontTextField fieldset": {
                    borderRadius: "0 30px 30px 0"
                }
            },
            "& .fontTextField ": {
                margin: "0 !important",
                width: "63px",
                "& fieldset": {
                    borderRadius: 0
                },
                "& input": {
                    padding: "5px 5px 5px 15px !important"
                }
            }
        }
    },
    "& .containerHeight": {
        "& .fontTextField": {
            width: 72,
            margin: "0 15px 0 0"
        }
    }
}));

export { Root };
