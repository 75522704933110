import { CircularProgress } from "@mui/material";
import { Root } from "./styles";
import { Box } from "@mui/system";

export default function LoadingScreen({ printLoading = false }) {
    return (
        <Root className={`container ${printLoading ? "printLoading" : ""}`}>
            <CircularProgress
                className="circularProgress"
                color="primary"
            ></CircularProgress>
            <Box className="loadingText">Preparing your menu...</Box>
        </Root>
    );
}
