import { Box, styled } from "@mui/system";

const Root = styled(Box)(({ theme }) => ({
    "&.imageUpload": {
        "& .imageContainer": {
            marginTop: 4,
            height: 130,
            border: "1px dashed rgba(180, 179, 179, 0.5)",
            borderRadius: 20,
            padding: "20px 32px",
            position: "relative",
            textAlign: "center",
            "&:hover": {
                "& svg:nth-of-type(2)": {
                    "& path": {
                        fill: theme.colors.grey[700]
                    }
                },
                "& p": {
                    opacity: 1
                }
            }
        },
        "& .image": {
            maxWidth: "100%",
            height: "100%",
            objectFit: "scale-down"
        },
        "& .deleteImage": {
            position: "absolute",
            top: 16,
            right: 18,
            width: 16,
            cursor: "pointer",
            "& path": {
                fill: theme.colors.grey[500]
            },
            "&:hover": {
                "& path": {
                    fill: theme.colors.grey[700]
                }
            }
        },
        "& .fileInput": {
            opacity: 0,
            position: "absolute",
            top: 0,
            left: 0,
            cursor: "pointer",
            height: "100%",
            width: "100%"
        },
        "& .noImageText": {
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "14px",
            marginTop: 8,
            marginBottom: 0,
            opacity: 0.4,
            color: `${theme.colors.grey[900]}a6`
        },
        "& .imageError": {
            color: "#D8000C",
            fontStyle: "italic",
            fontSize: 12,
            marginTop: 8
        },
        "& .imageSizeBox": {
            "& .fontLabel": {
                marginBottom: 10
            }
        },
        "& .alignmentStyles": {
            marginTop: 15
        },
        "& .collapseData": {
            "& .collapseContentWrapper": {
                marginTop: 20
            }
        }
    }
}));

export { Root };
