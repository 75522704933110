import Cookie from "js-cookie";

const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3011";

function client(
    endpoint: string,
    { body, customHeaders, ...customConfig }: any = {
        body: false,
        customHeaders: {}
    }
) {
    const config: RequestInit = {
        headers: {
            "Content-Type": "application/json",
            // We only send the cookie in case it exists, otherwise the server responds with 500 instead of 403.
            ...(Cookie.get("sm_c_t") && {
                Authorization: Cookie.get("sm_c_t")
            }),
            ...customHeaders
        },
        ...(body && { body: JSON.stringify(body) }),
        method: body ? "POST" : "GET",
        credentials: "same-origin",
        mode: "cors",
        ...customConfig
    };

    return window
        .fetch(`${baseUrl}/${endpoint}`, config)
        .then(async (response) => {
            const data = await response.json();
            if (response.ok) {
                return data;
            } else {
                if (response.status === 403) {
                    //window.location.href = "/unauthorized";
                    return;
                }
                if (response.status === 404) {
                    window.location.href = "/not-found";
                }
                return Promise.reject(data);
            }
        });
}

export { client };
