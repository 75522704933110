import fetchRequest from "./fetchRequest";

const valid_image_types = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/x-icon",
    "image/webp"
];

export const saveImage = async (image, identifier, recordType) => {
    try {
        const compressedImage = image;

        const {
            response: { signedUrl, assetUrl }
        } = await fetchRequest("/media/upload-url", {
            method: "POST",
            body: JSON.stringify({
                path: `${identifier}/media/${recordType}/${compressedImage.name.replace(
                    new RegExp("[^a-zA-Z.0-9]+", "g"),
                    ""
                )}`,
                blurhash: "printer"
            })
        });

        if (!valid_image_types.includes(compressedImage.type)) {
            return [
                false,
                "Unsupported file format! Valid extension are .jpg, .jpeg, .png, .svg and .ico"
            ];
        }

        const blob = new Blob([compressedImage], {
            type: compressedImage.type
        });

        const base64DataForImage = await blobToBase64(blob);
        const webpRes = await fetchRequest(`/media/upload-url-printer`, {
            method: "PUT",
            body: JSON.stringify({ imageData: base64DataForImage, signedUrl })
        });
        if (webpRes.success) {
            return [true, assetUrl];
        } else {
            throw new Error(
                "Something went wrong when uploading your image, please try again"
            );
        }
    } catch (e) {
        return [false, e.message];
    }
};

// Create a function to convert the Blob to Base64
function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            const base64String = reader.result.split(",")[1];
            resolve(base64String);
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsDataURL(blob);
    });
}
