import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

const Root = styled(Grid)(() => ({
    "& .fixedSideBlock": {
        position: "fixed",
        right: "32px",
        top: "50%",
        transform: "translateY(-50%)",

        zIndex: 1
    },
    "& .roundedBorderBlock": {
        borderRadius: "44px",
        border: "1px solid #D6D6D6",
        padding: "16px 9px",
        background: "#FFFFFF",
        boxShadow: "2px 2px 12px 0px #0000000D",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        "& svg": {
            height: "23px",
            width: "23px",
            cursor: "pointer"
        },
        "& .zoomPercentage": {
            margin: "22px 0",
            fontSize: "13px",
            fontWeight: "600"
        }
    },
    "& .overflowWarning": {
        borderBottom: "1px solid #D9D9D9",
        backgroundColor: "#fff",
        padding: "10px 30px",
        position: "absolute",
        left: "364px",
        right: 0,
        top: "60px",
        zIndex: 1,
        "&.adjustOverFlowBlock": {
            left: "71px"
        },
        "&.preview": {
            transition: "none",
            left: "0px !important"
        },
        "& svg": {
            width: 20,
            "& path": {
                fill: "#D8000C"
            }
        },
        "& p": {
            fontSize: 14,
            marginLeft: 15
        }
    }
}));

export { Root };
