import { Root } from "../style";

const DividerBlock = ({ height, focusableBlock: FocusableBlock }) => {
    return (
        <Root className="blockWrapper">
            <div
                style={{
                    height: height
                }}
            ></div>
            {FocusableBlock && <FocusableBlock></FocusableBlock>}
        </Root>
    );
};
export default DividerBlock;
