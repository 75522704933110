import { Root } from "../style";

const MirrorTextBlock = ({ text, styles }) => {
    // Dynamic styles
    const {
        fontFamily,
        fontWeight,
        fontSize,
        lineHeight,
        textAlign,
        fontStyle,
        letterSpacing,
        // boldFont,
        color,
        upperCase
    } = { ...styles };

    const customStyles = {
        fontFamily,
        fontWeight:
            // boldFont === "bold" ? "bold" :
            fontWeight,
        fontSize: fontSize ? `${fontSize}px` : "",
        lineHeight: lineHeight ? `${lineHeight}px` : "",
        textAlign,
        fontStyle,
        letterSpacing: `${letterSpacing === 0 ? 0 : letterSpacing / 10}px`,
        color,
        textTransform: upperCase ? "uppercase" : "none",
        whiteSpace: "pre-wrap"
    };

    const updatedText = text.replace(/\n/g, "<br>");

    return (
        <Root className="blockWrapper">
            <p
                className="textBlock"
                style={customStyles}
                disabled={true}
                dangerouslySetInnerHTML={{ __html: updatedText }}
            />
        </Root>
    );
};
export default MirrorTextBlock;
