import { generateId } from "../utils/uuid";

const DEFAULT_INDEX = 0;

const EXTRA_PAGE_MARGIN = 10;

const SIDEBAR_TAB_INDEX = {
    LAYOUT: 0,
    CONTENT: 1,
    STYLES: 2
};

const LAYOUT_TAB_INDEX = {
    PAPER_LAYOUT: 0,
    PAGE_LAYOUT: 1
};

const DEFAULT_ROW_COL_SPACE = 40;

const RESET_ROW_COL_STYLES = {
    columnCount: 1,
    rowHeight: 100,
    columnSpace: DEFAULT_ROW_COL_SPACE,
    rowPadding: { top: 10, right: 10, bottom: 10, left: 10 }
};

//Reset PageBlocks
const RESET_ROW_COL = [
    {
        styles: {
            ...RESET_ROW_COL_STYLES
        },
        column: [
            {
                styles: { columnWidth: 100 },
                blocks: [],
                id: generateId()
            }
        ]
    }
];

//Reset Page Properties
const DEFAULT_PAGE_PROPS = {
    marginValue: 30,
    printToEdge: false,
    background: {
        url: "",
        cover: false,
        transparency: 50,
        location: "",
        size: 50,
        color: "#ffffff"
    },
    layout: {
        columnCount: "grid",
        mirrorMode: false,
        gridMode: {
            column: "",
            active: true,
            containerCount: 0
        },
        columnSpace: 40
    },
    border: {
        position: {
            top: false,
            right: false,
            bottom: false,
            left: false
        },
        width: 1,
        color: "#000000",
        style: "solid",
        radius: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    }
};

//Background Position
const POSITIONS = [
    "top left",
    "top center",
    "top right",
    "center left",
    "center center",
    "center right",
    "bottom left",
    "bottom center",
    "bottom right"
];

//Border Styles
const BORDER_STYLES = [
    "dotted",
    "dashed",
    "solid",
    "double",
    "groove",
    "ridge",
    "inset",
    "outset"
];

// Apply All
const APPLY_ALL = ["Section", "Menu"];

export {
    EXTRA_PAGE_MARGIN,
    DEFAULT_ROW_COL_SPACE,
    RESET_ROW_COL_STYLES,
    SIDEBAR_TAB_INDEX,
    DEFAULT_INDEX,
    RESET_ROW_COL,
    LAYOUT_TAB_INDEX,
    DEFAULT_PAGE_PROPS,
    POSITIONS,
    BORDER_STYLES,
    APPLY_ALL
};
