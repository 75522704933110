const Fonts = [
    {
        name: "Montserrat",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        name: "Tangerine",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap"
    },
    {
        name: "Splash",
        linkHtml: "https://fonts.googleapis.com/css2?family=Splash&display=swap"
    },
    {
        name: "Gothic",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap"
    },
    {
        name: "Poppins",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        name: "Fredoka",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap"
    },
    {
        name: "Libre Baskerville",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap"
    },
    {
        name: "Charm",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Charm:wght@400;700&display=swap"
    },
    {
        name: "Limelight",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Limelight&display=swap"
    },
    {
        name: "Battambang",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Battambang:wght@100;300;400;700;900&display=swap"
    },
    {
        name: "Alumni Sans",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Alumni+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        name: "Roboto",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"
    },
    {
        name: "Open Sans",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800;900&display=swap"
    },
    {
        name: "Reenie Beanie",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap"
    },
    {
        name: "Reggae One",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Reggae+One&display=swap"
    },
    {
        name: "Rhodium Libre",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Rhodium+Libre&display=swap"
    },
    {
        name: "Ribeye Marrow",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Ribeye+Marrow&display=swap"
    },
    {
        name: "Righteous",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Righteous&display=swap"
    },
    {
        name: "Risque",
        linkHtml: "https://fonts.googleapis.com/css2?family=Risque&display=swap"
    },
    {
        name: "Road Rage",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Road+Rage&display=swap"
    },
    {
        name: "Playfair Display",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap"
    },
    {
        name: "Raleway",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap&display=swap"
    },
    {
        name: "Ubuntu",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap"
    },
    {
        name: "Noto Serif",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Noto+Serif:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        name: "Alegreya",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;600;700;800;900&display=swap"
    },
    {
        name: "Merriweather",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap"
    },
    {
        name: "Bitter",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Bitter:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        name: "Fira Sans",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        name: "Courier Prime",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap"
    },
    {
        name: "Rye",
        linkHtml: "https://fonts.googleapis.com/css2?family=Rye&display=swap"
    },
    {
        name: "Love Ya Like A Sister",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap"
    },
    {
        name: "Alex Brush",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap"
    },
    {
        name: "Amatic SC",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap"
    },
    {
        name: "Cabin Sketch",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@400;700&display=swap"
    },
    {
        name: "Great Vibes",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap"
    },
    {
        name: "Jolly Lodger",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Jolly+Lodger&display=swap"
    },
    {
        name: "Kaushan Script",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap"
    },
    {
        name: "Monsieur La Doulaise",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap"
    },
    {
        name: "Parisienne",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Parisienne&display=swap"
    },
    {
        name: "Petit Formal Script",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Petit+Formal+Script&display=swap"
    },
    {
        name: "Pinyon Script",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap"
    },
    {
        name: "Rubik Mono One",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap"
    },
    {
        name: "Satisfy",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Satisfy&display=swap"
    },
    {
        name: "Kalam",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap"
    },
    {
        name: "La Belle Aurore",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap"
    },
    {
        name: "Anton",
        linkHtml: "https://fonts.googleapis.com/css2?family=Anton&display=swap"
    },
    {
        name: "Oswald",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap"
    },
    {
        name: "Zilla Slab",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@300;400;500;600;700&display=swap"
    },
    {
        name: "Roboto Slab",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap"
    },
    {
        name: "Cormorant",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;500;600;700&display=swap"
    },
    {
        name: "Metal",
        linkHtml: "https://fonts.googleapis.com/css2?family=Metal&display=swap"
    },
    {
        name: "Diphylleia",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Diphylleia&display=swap"
    },
    {
        name: "Alkatra",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Alkatra:wght@400;500;600;700&display=swap"
    },
    {
        name: "Oleo Script",
        linkHtml:
            "https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap"
    }
];

export { Fonts };
