import { useParams } from "react-router-dom";
import React, { FC } from "react";
import LoadingScreen from "../LoadingScreen";
import { usePrinterContext } from "../../../context/printer";
import { client } from "../../../utils/apiClient";
import MenuAdapter from "../../../adapters/MenuAdapter";
import { PAPER_SIZES } from "../../../const/paper";
import _, { cloneDeep } from "lodash";
import BlockData from "../../../const/BlockData";
import { generateId } from "../../../utils/uuid";

interface Props {
    children: React.ReactNode;
}

// This component sets both the menu and the restaurant in the context
export const SetMenu: FC<Props> = ({ children }) => {
    const { menu: menuId, restaurant: restaurantId } = useParams();

    if (!menuId) {
        throw new Error("menuId param is neccesary to use this component");
    }
    if (!restaurantId) {
        throw new Error(
            "restaurantId param is neccesary to use this component"
        );
    }

    const {
        setMenu,
        setRestaurant,
        layoutState,
        setLayoutState,
        setSectionList,
        setSectionListRes,
        setPageBlocks,
        setPageBlocksArray
    } = usePrinterContext();
    const { columnLayout, pageLayout } = layoutState;
    const [loading, setLoading] = React.useState<boolean>(true);

    const { BLOCKTYPE } = BlockData();

    function updateData(response: any, restaurantData: any) {
        const { templateConfigs } = response;

        templateConfigs?.pages_content?.forEach((page: any) => {
            page?.row?.forEach((rowItem: any) => {
                rowItem?.column?.forEach((col: any) => {
                    col.blocks = col?.blocks?.filter((block: any) => {
                        let matchingSection: any;
                        if (block?.blockType === BLOCKTYPE.SECTION_ITEMS) {
                            matchingSection = response?.sections?.find(
                                (section: any) =>
                                    section._id === block.data.items._id
                            );
                        }
                        if (
                            block.blockType === BLOCKTYPE.MENU_HEADER &&
                            response.headerText.trim() === ""
                        ) {
                            return false; // Remove block if headerText is blank
                        }
                        if (
                            block.blockType === BLOCKTYPE.MENU_FOOTER &&
                            response.footerText.trim() === ""
                        ) {
                            return false; // Remove block if footerText is blank
                        }
                        if (
                            block.blockType === BLOCKTYPE.MENU_TITLE &&
                            response.displayName.trim() === ""
                        ) {
                            return false; // Remove block if displayName is blank
                        }

                        if (
                            block.blockType === BLOCKTYPE.RESTAURANT_LOGO &&
                            restaurantData?.logo_image.trim() === ""
                        ) {
                            return false; // Remove block if logo_image is blank
                        }

                        if (
                            block.blockType === BLOCKTYPE.MENU_HEADER &&
                            block.data.text !== response.headerText
                        ) {
                            block.data.text = response.headerText;
                        } else if (
                            block.blockType === BLOCKTYPE.MENU_FOOTER &&
                            block.data.text !== response.footerText
                        ) {
                            block.data.text = response.footerText;
                        } else if (
                            block.blockType === BLOCKTYPE.MENU_TITLE &&
                            block.data.text !== response.displayName
                        ) {
                            block.data.text = response.displayName;
                        } else if (
                            block.blockType === BLOCKTYPE.SECTION_ITEMS &&
                            matchingSection
                        ) {
                            const areObjectsEqual = _.isEqual(
                                matchingSection,
                                block.data.items
                            );
                            if (!areObjectsEqual) {
                                block.data.items = matchingSection;
                            }
                        } else if (
                            block.blockType === BLOCKTYPE.RESTAURANT_LOGO &&
                            block.data.url !== restaurantData?.logo_image
                        ) {
                            block.data.url = restaurantData?.logo_image;
                        }

                        return true;
                    });
                });
            });
        });

        return response;
    }

    React.useEffect(() => {
        let restaurantData: any;
        const getRestaurant = async () => {
            const response = await client(`restaurant/${restaurantId}`);
            restaurantData = response;
            if (setRestaurant) {
                setRestaurant(response);
            }
        };

        const getMenu = async () => {
            const response = await client(`menu/${restaurantId}/${menuId}`);
            const adapter = new MenuAdapter(response);
            const data = adapter.getFormatedData();
            const updatedResponse = await updateData(data, restaurantData);
            const blocksData = updatedResponse?.templateConfigs?.pages_content;
            const pageLayoutData =
                response?.printer_template?.page_properties?.page_layout;

            setLayoutState((prevState: any) => ({
                ...prevState,
                pageLayout: {
                    ...pageLayout,
                    paperSize:
                        PAPER_SIZES[
                            pageLayoutData?.paper_size as keyof typeof PAPER_SIZES
                        ],
                    activeOrientation: pageLayoutData?.orientation,
                    marginValue: pageLayoutData?.margin
                },
                columnLayout: {
                    ...columnLayout
                }
            }));

            const filteredSections = updatedResponse?.sections?.filter(
                (section: any) => {
                    const sectionId = section?._id;
                    const isSectionUsed = blocksData?.some((page: any) =>
                        page?.row?.some((row: any) =>
                            row?.column?.some((col: any) =>
                                col?.blocks?.some(
                                    (block: any) =>
                                        block?.data?.items?._id === sectionId ||
                                        (block?.data?.items?.items || []).some(
                                            (item: any) =>
                                                item?._id === sectionId
                                        )
                                )
                            )
                        )
                    );

                    return !isSectionUsed;
                }
            );

            setSectionList(filteredSections);
            setSectionListRes(updatedResponse?.sections);
            setPageBlocks(blocksData);
            const clonedBlockedData = cloneDeep(blocksData);
            setPageBlocksArray([clonedBlockedData]);
            if (setMenu) {
                setMenu(updatedResponse);
            }
        };

        Promise.all([getMenu(), getRestaurant()])
            .then(() => {
                // Add ID to the container if not present
                setPageBlocks((prevPageBlocks: any) => {
                    return prevPageBlocks?.map((page: any) => {
                        return {
                            ...page,
                            row: page?.row?.map((row: any) => {
                                return {
                                    ...row,
                                    column: row?.column?.map((col: any) => {
                                        // Check if column ID is empty or undefined
                                        if (!col.id) {
                                            // Assign a static ID
                                            col.id = generateId();
                                        }
                                        return col;
                                    })
                                };
                            })
                        };
                    });
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log("Error", err);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading ? (
        <LoadingScreen></LoadingScreen>
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );
};
