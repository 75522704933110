import { styled } from "@mui/system";
import { FormControl, Tabs } from "@mui/material";

const MyTabs = styled(Tabs)({
    borderBottom: "1px solid #dadada",
    padding: "0 16px",
    minHeight: "38px",
    "& .MuiTabs-flexContainer": {
        width: "100%",
        "& > button": {
            width: "50%"
        }
    },
    "& .labelText": {
        color: "#9e9e9e",
        textTransform: "capitalize",
        fontSize: 14,
        fontWeight: 500,
        transition: "all 300ms linear,opacity 0ms"
    },
    "& .MuiTab-root": {
        transition: "all 300ms linear,opacity 0ms",
        padding: "11px 8px 9px 8px",
        minHeight: "38px",
        borderRadius: "20px 20px 0 0",
        border: "1px solid transparent",
        borderBottomWidth: 0,
        "&.Mui-selected": {
            backgroundColor: "#fff",
            boxShadow: "0px 0px 4px 0px #00000014",
            borderColor: "#dadada",
            color: "#dadada",
            "& .labelText": {
                color: "#222222"
            }
        }
    },
    ".MuiTabs-indicator": {
        display: "none" // Hide the indicator
    }
});

const SelectRoot = styled(FormControl)(({ theme }) => ({
    "& .MuiFormLabel-root": {
        fontSize: 12,
        fontWeight: 500,
        color: "#222",
        transform: "none",
        top: "-8px",
        left: "14px"
    },
    "& .input-select": {
        borderRadius: "50px",
        "& .MuiSelect-select": {
            minHeight: "44px",
            boxSizing: "border-box",
            padding: "10px 30px 10px 20px",
            fontSize: "14px",
            fontWeight: 400,
            color: "#b4b3b3",
            opacity: 1,
            textTransform: "capitalize"
        },
        "& fieldset": {
            borderColor: "#d9d9d9"
        }
    },
    "& .required-star": {
        color: theme.colors.orange[700]
    },
    "& .paperSize-label": {
        backgroundColor: "#fff",
        paddingRight: 3
    }
}));

export { MyTabs, SelectRoot };
