import { styled } from "@mui/system";

const Root = styled("div")(({ theme }) => ({
    padding: "0 0 20px 0",
    "& .dragItemsTitle": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 20px 20px",
        fontWeight: 500,
        fontSize: "16px",
        borderBottom: "1px solid #D9D9D9",
        "& svg": {
            marginLeft: "12px",
            height: 14,
            width: 14
        },
        "& .defaultItemStyle": {
            border: "1px solid #5A5A5A",
            borderRadius: "5px",
            minWidth: "55px",
            padding: "4px 5px",
            height: "auto",
            fontSize: "12px",
            opacity: 1,
            "&:after": { display: "none" },
            "&.active": {
                backgroundColor: "#3a3a3a",
                color: "#fff",
                borderColor: "#3a3a3a"
            }
        }
    },
    "& .collapseData": {
        "& .collapseContentWrapper": {
            marginTop: 30
        }
    },
    "& .autoCompleteInput": {
        "& .MuiAutocomplete-input": {
            padding: "3px 8px !important"
        },
        "& .MuiAutocomplete-inputRoot": {
            padding: "10px 12px 8px 12px !important"
        }
    },
    "& .autoCompleteTextField": {
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "0 !important",
            boxSizing: "border-box",
            height: "100%"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7,
            top: "-8px",
            fontSize: "14px"
        },
        "& .Mui-disabled+.MuiAutocomplete-inputRoot": {
            opacity: 0.4
        },
        "& .MuiOutlinedInput-root": {
            padding: "9px 30px 9px 20px !important"
            // height:'30px',
        }
    },
    "& .autoCompleteSelectRow": {
        borderRadius: "20px !important",
        boxShadow: "2px 2px 16px 0px #0000001A",
        border: `1px solid ${theme.colors.grey[200]}`,
        "& > .MuiAutocomplete-listbox": {
            overflow: "scroll",
            scrollbarWidth: "thin"
        },
        "& li": {
            width: "100%",
            display: "flex !important",
            alignItems: "center !important",
            padding: "4px 16px !important",
            fontSize: 15,
            background: "#fff !important",
            "&:hover": {
                background: `${theme.colors.orange[50]} !important`
            }
        },
        "& li[aria-selected='true']": {
            background: `${theme.colors.orange[50]} !important`,
            color: theme.colors.orange[900]
        },
        "& li.dropdown-selected": {
            background: `${theme.colors.orange[50]} !important`,
            color: `${theme.colors.orange[900]} !important`
        }
    },
    "& .applyAllWrapper": {
        position: "relative",
        zIndex: 1,
        "&.active": {
            "& .applyAllLabel": {
                backgroundColor: "#474747",
                color: "#fff"
            },
            "& .applyOptionWrapper": {
                opacity: "1",
                visibility: "visible"
            }
        },
        "& .applyOptionWrapper": {
            backgroundColor: "#333333",
            borderRadius: "6px",
            color: "#ffffff",
            fontSize: "11px",
            padding: "0 10px",
            position: "absolute",
            top: "101%",
            left: 0,
            right: 0,
            border: "1px solid #727272",
            opacity: "0",
            visibility: "hidden",
            transition: "opacity 0.3s",
            "& .applyOption": {
                padding: "6px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                "& svg": {
                    height: "8px",
                    width: "8px"
                },
                "& + .applyOption": {
                    borderTop: "1px solid #fff"
                }
            }
        }
    },
    "& .applyAllLabel": {
        fontSize: 10,
        height: 20,
        cursor: "pointer",
        fontWeight: 700,
        backgroundColor: "#eaeaea",
        color: "#5a5a5a",
        transition: "background-color 0.3s, color 0.3s",
        "&:hover": {
            backgroundColor: "#474747",
            color: "#fff"
        },
        "& .chipLabel": {
            "& svg": {
                marginLeft: "5px",
                height: "8px",
                width: "8px"
            }
        },
        "& span": {
            paddingLeft: 10,
            paddingRight: 10
        }
    }
}));

export { Root };
