import { styled } from "@mui/material/styles";

const classes = {
    button: `button`,
    primary: `primary`,
    secondary: `secondary`,
    circularProgress: `circularProgress`
};
const Root = styled("button")(({ theme, propsColor }) => ({
    [`&.${classes.button}`]: {
        fontFamily: '"Roboto", sans-serif !important',
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px 24px",
        gap: "8px",
        cursor: "pointer",
        fontWeight: 700,
        "& svg": {
            width: 12,
            height: 12
        },
        "& span": {
            display: "flex"
        },
        minWidth: 107,
        height: 36,
        borderRadius: "30px",
        transition: "all 1s",
        position: "relative",
        transitionTimingFunction: "cubic-bezier(0.19, 1, 0.22, 1)",
        "&:disabled": {
            cursor: "default",
            border: `1px solid #F2F2F2`,
            outlineColor: "#F2F2F2",
            background: "#F2F2F2",
            color: theme.colors.grey[400],
            "&::after": {
                borderColor: "#F2F2F2"
            },
            "& path": {
                fill: theme.colors.grey[400]
            },
            boxShadow: "none"
        }
    },
    [`&.${classes.primary}`]: {
        background:
            propsColor === "orange"
                ? theme.colors.orange[700]
                : theme.colors.grey[600],
        color: "white",
        // outline: "1px solid",
        border: `1px solid ${
            propsColor === "orange"
                ? theme.colors.orange[700]
                : theme.colors.grey[600]
        }`,
        // outlineColor:
        // propsColor === "orange"
        //     ? theme.colors.orange[700]
        //     : theme.colors.grey[600],
        "& path": {
            fill: "white"
        },
        "&::after": {
            content: "''",
            position: "absolute",
            border: `1px solid ${
                propsColor === "orange"
                    ? theme.colors.orange[700]
                    : theme.colors.grey[600]
            }`,
            height: "100%",
            width: "100%",
            borderRadius: "36px",
            transition: "all 1s",
            transitionTimingFunction: "cubic-bezier(0.19, 1, 0.22, 1)",
            padding: "1px"
        },
        "&:hover:not(:disabled)": {
            // the 59 at the end sets opacity to 35% (on its hex format)
            boxShadow: `0px 10px 25px ${
                propsColor === "orange"
                    ? theme.colors.orange[700]
                    : theme.colors.grey[600]
            }59`,
            // outlineColor: "rgba(0, 0, 0, 0)",
            // outlineOffset: "5px"
            "&:after": {
                padding: "5px",
                borderColor: "rgba(0,0,0,0)"
            }
        }
    },
    [`&.${classes.secondary}`]: {
        // outline: "1px solid",
        // outlineColor:
        //     propsColor === "orange"
        //         ? theme.colors.orange[700]
        //         : theme.colors.grey[500],
        border: `1px solid ${
            propsColor === "orange"
                ? theme.colors.orange[700]
                : theme.colors.grey[500]
        }`,
        color:
            propsColor === "orange"
                ? theme.colors.orange[700]
                : theme.colors.grey[900],
        background: "transparent",
        "& path": {
            fill:
                propsColor === "orange"
                    ? theme.colors.orange[700]
                    : theme.colors.grey[500]
        },
        "&::after": {
            content: "''",
            position: "absolute",
            border: `1px solid ${
                propsColor === "orange"
                    ? theme.colors.orange[700]
                    : theme.colors.grey[500]
            }`,
            height: "100%",
            width: "100%",
            borderRadius: "36px",
            transition: "all 1s",
            transitionTimingFunction: "cubic-bezier(0.19, 1, 0.22, 1)",
            padding: "1px"
        },
        "&:hover:not(:disabled)": {
            boxShadow: `0px 10px 25px ${theme.colors.grey[500]}59`,
            // outlineColor: "rgba(0,0,0,0)",
            // outlineOffset: "5px",
            "&:after": {
                padding: "5px",
                borderColor: "rgba(0,0,0,0)"
            }
        }
    },
    [`& .${classes.circularProgress}`]: {
        "& svg": {
            height: "100%",
            width: "100%"
        }
    }
}));

export { Root, classes };
