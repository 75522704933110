import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
        const div = document.createElement("div");
        div.style.position = "absolute";
        div.style.pointerEvents = "none";
        div.style.top = "0";
        div.style.width = "100%";
        div.style.height = "100%";
        self.elt = div;
        document.body.appendChild(div);
        return () => {
            document.body.removeChild(div);
        };
    }, [self]);

    return (render) =>
        (provided, ...args) => {
            const element = render(provided, ...args);
            if (provided.draggableProps.style.position === "fixed") {
                return createPortal(element, self.elt);
            }
            return element;
        };
};

export const draggedStyle = (snapshot, provided, zoomLevel) => {
    const draggableStyle = provided.draggableProps.style;
    const isDragging = snapshot.isDragging;
    const translateString = draggableStyle.transform ?? "";
    const scale = zoomLevel / 10;
    const widthPos = draggableStyle.width / scale;
    const heightPos = draggableStyle.height / scale;
    const leftPos =
        draggableStyle.left - widthPos / 2 + draggableStyle.width / 2;
    const topPos =
        draggableStyle.top - heightPos / 2 + draggableStyle.height * 0.5;

    return {
        draggableStyle,
        isDragging,
        translateString,
        widthPos,
        heightPos,
        leftPos,
        topPos
    };
};
