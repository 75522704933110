import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { cloneDeep, isEqual } from "lodash";
import {
    CheckBox,
    ColorInput,
    FormRadio1,
    RangeSlider
} from "../../../../UI/CommonComponents";
import Icon from "../../../../UI/Icon";
import { usePrinterContext } from "../../../../../context/printer";
import { ImageUpload } from "../../../../UI/ImageUpload";
import { POSITIONS } from "../../../../../const/general";
import { MenuItem, Select } from "@mui/material";
import { SelectRoot } from "../style";

const PaperBackground = ({ pageIndex }) => {
    const {
        pageBlocks,
        setPageBlocks,
        setSectionBlock,
        sectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray
    } = usePrinterContext();

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate = updatedPageBlocks[pageIndex];

    const [checked, setChecked] = useState(
        blockToUpdate?.page_properties?.background?.cover || false
    );
    const [logo, setLogo] = useState(
        blockToUpdate?.page_properties?.background?.url || ""
    );
    const [url, setUrl] = useState(
        blockToUpdate?.page_properties?.background?.url || ""
    );
    const [imageSize, setImageSize] = useState(
        blockToUpdate?.page_properties?.background?.size || 50
    );
    const [transparency, setTransparency] = useState(
        blockToUpdate?.page_properties?.background?.transparency || 50
    );
    const [location, setLocation] = useState(
        blockToUpdate?.page_properties?.background?.location || "center center"
    );
    const [bgColor, setBGcolor] = useState(
        blockToUpdate?.page_properties?.background?.color || "#ffffff"
    );

    useEffect(() => {
        if (undoRedoState?.state && isEqual(pageIndex, sectionBlock.data)) {
            return;
        }
        setLogo(blockToUpdate?.page_properties?.background?.url || "");
        setChecked(blockToUpdate?.page_properties?.background?.cover || false);
        setImageSize(blockToUpdate?.page_properties?.background?.size || 50);
        setBGcolor(
            blockToUpdate?.page_properties?.background?.color || "#ffffff"
        );
        setLocation(
            blockToUpdate?.page_properties?.background?.location ||
                "center center"
        );
        setTransparency(
            blockToUpdate?.page_properties?.background?.transparency || 50
        );
        setSectionBlock({ data: pageIndex, pageBlocks: pageBlocks });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, undoRedoState]);

    const [dependencyChanged, setDependencyChanged] = useState(false);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            url,
            size: imageSize,
            cover: checked,
            transparency,
            location,
            color: bgColor
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate = updatedPageBlocks[pageIndex];

        if (blockToUpdate) {
            blockToUpdate.page_properties.background = {
                ...blockToUpdate.page_properties.background,
                ...newStyles
            };
            setPageBlocks(updatedPageBlocks);
        }
        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, checked, imageSize, transparency, location, bgColor]);

    const handleSizeChange = (event, type, max) => {
        const parsedValue = parseInt(event.target.value);
        const newValue = parsedValue < max ? parsedValue : max;
        if (typeof newValue === "number") {
            setDependencyChanged(true);
            if (type === "transparency") {
                setTransparency(newValue);
            } else if (type === "imageSize") {
                setImageSize(newValue);
            }
        }
    };

    const handleChecked = (event) => {
        const value = event.target.checked;
        setDependencyChanged(true);
        setChecked(value);
    };

    const handleLocChange = (value) => {
        setDependencyChanged(true);
        setLocation(value);
    };

    return (
        <Box className="backgroundWrapper">
            <Box className="fontLabel">Background Image</Box>

            <ImageUpload
                logo={logo}
                setLogo={setLogo}
                setUrl={setUrl}
                setDependencyChanged={setDependencyChanged}
            />
            <Box sx={{ mb: 2 }}>
                <CheckBox
                    label="Covers Background"
                    checked={checked}
                    setChecked={handleChecked}
                />
                <p className="coverInfo">
                    Location & Size controls disabled when cover is on.
                </p>
            </Box>
            <Box
                sx={{
                    mb: 2
                }}
            >
                <RangeSlider
                    sliderLabel="Image Transparency"
                    sliderVal={transparency}
                    handleSliderChange={(event) =>
                        handleSizeChange(event, "transparency", 100)
                    }
                    min={0}
                    max={100}
                    step={1}
                />
            </Box>
            <Box
                sx={{
                    mb: 2
                }}
            >
                <Box className="fontLabel">Image Location</Box>
                <Box className="flexBox alignCenter">
                    <Box className="locationWrapper">
                        <FormRadio1
                            name="number-of-column"
                            radioOption={POSITIONS}
                            setRadioVal={handleLocChange}
                            radioVal={location}
                            setDependencyChanged={setDependencyChanged}
                        />
                    </Box>
                    <Box sx={{ width: 118 }}>
                        <SelectRoot size="small" sx={{ width: "100%" }}>
                            <Select
                                labelId="input-label"
                                id="input-select"
                                value={location}
                                onChange={(e) =>
                                    handleLocChange(e.target.value)
                                }
                                className="input-select"
                                MenuProps={{
                                    classes: {
                                        paper: "customSelectList"
                                    }
                                }}
                            >
                                {POSITIONS?.map((position, index) => (
                                    <MenuItem value={position} key={index}>
                                        {position}
                                    </MenuItem>
                                ))}
                            </Select>
                        </SelectRoot>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    mb: 2,
                    opacity: checked ? 0.45 : "",
                    pointerEvents: checked ? "none" : ""
                }}
            >
                <RangeSlider
                    sliderLabel="Image Size"
                    sliderVal={imageSize}
                    handleSliderChange={(event) =>
                        handleSizeChange(event, "imageSize", 100)
                    }
                    min={0}
                    max={100}
                    step={1}
                />
            </Box>
            <Box className="flexBox alignCenter justifyBetween">
                <Box className="fontLabel mb-0">Background Color</Box>
                <Box className="flexBox alignCenter  colorPickerContainer">
                    {bgColor !== "#ffffff" ? (
                        <Icon
                            onClick={(e) => {
                                setBGcolor("#ffffff");
                                setDependencyChanged(true);
                            }}
                            className="cancelIcon"
                            name="cancelIcon"
                        />
                    ) : (
                        ""
                    )}
                    <ColorInput
                        value={bgColor}
                        setValue={setBGcolor}
                        setDependencyChanged={setDependencyChanged}
                    />
                </Box>
            </Box>
        </Box>
    );
};
export default PaperBackground;
