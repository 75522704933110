import { useState } from "react";
import { Root } from "./style";

export default function Image({ ImgSrc, ImgAlt, ImgStyle }) {
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <Root
            src={ImgSrc}
            alt={ImgAlt + " logo"}
            className={`${"logo"} ${
                imageLoaded ? "imageLoaded" : "imageLoading"
            }`}
            style={ImgStyle || {}}
            onLoad={handleImageLoad}
        ></Root>
    );
}
