import React, { FC, useEffect, useReducer, useState } from "react";
import { User } from "../../interfaces/user";
import { AuthContext } from "./AuthContext";
import { authReducer } from "./authReducer";
import Cookie from "js-cookie";
import { client } from "../../utils/authClient";
import LoadingScreen from "../../components/Printer/LoadingScreen";

interface Props {
    children: React.ReactNode;
}
export interface AuthState {
    isLoggedIn: boolean;
    user?: User;
}

const AUTH_INITIAL_STATE: AuthState = {
    isLoggedIn: false,
    user: undefined
};

export const AuthProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, AUTH_INITIAL_STATE);
    const [loading, setLoading] = useState(true);
    let token = Cookie.get("sm_c_t") as any as string;

    useEffect(() => {
        const refreshSession = async (token: string) => {
            const refreshToken = Cookie.get("sm_r_t");
            if (!token || token === undefined) {
                if (!refreshToken || refreshToken === undefined) {
                    return window.location.replace(
                        `${process.env.REACT_APP_LOGIN}`
                    );
                } else {
                    return (async () => {
                        const response = await client(
                            "user/refresh-token",
                            null,
                            "GET",
                            {
                                Authorization: `${refreshToken}`
                            }
                        );

                        if (response) {
                            const oneHour = new Date(
                                new Date().getTime() + 1 * 60 * 60 * 1000
                            );
                            Cookie.set(
                                "sm_c_t",
                                response.AuthenticationResult?.AccessToken,
                                {
                                    domain: process.env.REACT_APP_COOKIE_DOMAIN,
                                    expires: oneHour
                                }
                            );
                            setLoading(false);
                            return response.AuthenticationResult?.AccessToken;
                        } else {
                            return window.location.replace(
                                `${process.env.REACT_APP_LOGIN}`
                            );
                        }
                    })();
                }
            }
        };
        const checkToken = async (token: string) => {
            try {
                const response = await client("user/get-user", null, "GET", {
                    Authorization: `${token}`
                });

                dispatch({ type: "[Auth] - Login", payload: response });
                setLoading(false);
                return;
            } catch (error) {
                if (error) {
                    //return Cookie.remove("sm_c_t");
                    return window.location.replace(
                        `${process.env.REACT_APP_LOGIN}`
                    );
                }
            }
        };
        (async () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const newToken = await refreshSession(token);
            await checkToken(newToken || token);
        })();
        // eslint-disable-next-line
    }, []);
    if (loading) {
        return <LoadingScreen></LoadingScreen>;
    }
    return (
        <AuthContext.Provider
            value={{
                ...state
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
