import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Icon from "../../../../../../UI/Icon";
import {
    ColorInput,
    FontSelect,
    FontSize,
    LetterSpacing,
    LineHeight
} from "../../../../../../UI/CommonComponents";
import { usePrinterContext } from "../../../../../../../context/printer";
import { Fonts } from "../../../../../../../const/font";
import { cloneDeep, isEqual } from "lodash";
import CustomTooltip from "../../../../../../UI/CustomTooltip/CustomTooltip";

export default function ItemsDescription({ data }) {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray,
        revertItemStyle
    } = usePrinterContext();
    const [dependencyChanged, setDependencyChanged] = useState(false);

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex]?.data?.items?.items[data?.itemIndex];

    const selectedFontName = Fonts.find(
        (font) =>
            font.name === blockToUpdate?.styles?.itemDescription?.fontFamily
    );

    const [itemStyleEnabled, setItemStyleEnabled] = useState(
        blockToUpdate?.styles?.enabled
    );
    const [lineHeight, setLineHeight] = useState(
        blockToUpdate?.styles?.itemDescription?.lineHeight
    );
    const [fontSize, setFontSize] = useState(
        blockToUpdate?.styles?.itemDescription?.fontSize
    );
    const [letterSpacing, setLetterSpacing] = useState(
        blockToUpdate?.styles?.itemDescription?.letterSpacing
    );
    const [upperCaseText, setUpperCaseText] = useState(
        blockToUpdate?.styles?.itemDescription?.upperCase
    );
    const [textColor, setTextColor] = useState(
        blockToUpdate?.styles?.itemDescription?.color
    );
    const [boldFont, setBoldFont] = useState(
        blockToUpdate?.styles?.itemDescription?.boldFont === "bold"
            ? true
            : false
    );
    const [italicFont, setItalicFont] = useState(
        blockToUpdate?.styles?.itemDescription?.fontStyle === "normal"
            ? false
            : true
    );
    const [selectedFont, setSelectedFont] = useState(selectedFontName);
    const [selectedFontWeight, setSelectedFontWeight] = useState(
        blockToUpdate?.styles?.itemDescription?.fontWeight
    );

    useEffect(() => {
        if (
            undoRedoState?.state &&
            isEqual(data, sectionBlock.data) &&
            revertItemStyle
        ) {
            return;
        }

        setItemStyleEnabled(blockToUpdate?.styles?.enabled);
        setLineHeight(blockToUpdate?.styles?.itemDescription?.lineHeight);
        setFontSize(blockToUpdate?.styles?.itemDescription?.fontSize);
        setLetterSpacing(blockToUpdate?.styles?.itemDescription?.letterSpacing);
        setUpperCaseText(blockToUpdate?.styles?.itemDescription?.upperCase);
        setTextColor(blockToUpdate?.styles?.itemDescription?.color);
        setSelectedFont(selectedFontName);
        setSelectedFontWeight(
            blockToUpdate?.styles?.itemDescription?.fontWeight
        );
        setBoldFont(
            blockToUpdate?.styles?.itemDescription?.boldFont === "bold"
                ? true
                : false
        );
        setItalicFont(
            blockToUpdate?.styles?.itemDescription?.fontStyle === "normal"
                ? false
                : true
        );

        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data.pageIndex,
        data.rowIndex,
        data.columnIndex,
        data.blockIndex,
        data.itemIndex,
        undoRedoState,
        revertItemStyle
    ]);

    useEffect(() => {
        if (!itemStyleEnabled && dependencyChanged) {
            setItemStyleEnabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencyChanged]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            lineHeight,
            fontSize,
            color: textColor,
            fontWeight: selectedFontWeight,
            boldFont: boldFont ? "bold" : "normal",
            fontStyle: italicFont ? "italic" : "normal",
            fontFamily: selectedFont?.name,
            letterSpacing,
            upperCase: upperCaseText
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.items?.items[data?.itemIndex];

        if (blockToUpdate) {
            blockToUpdate.styles.itemDescription = {
                ...blockToUpdate.styles.itemDescription,
                ...newStyles
            };

            blockToUpdate.styles.enabled = itemStyleEnabled;

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }

        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        upperCaseText,
        letterSpacing,
        lineHeight,
        fontSize,
        textColor,
        boldFont,
        italicFont,
        selectedFont,
        selectedFontWeight,
        itemStyleEnabled
    ]);

    return (
        <div className="collapseContentWrapper itemDescription">
            <Box className="fontFamilyOption">
                <Box className="fontLabel">Font</Box>
                <Box className="fontsSelect">
                    <FontSelect
                        selectedFont={selectedFont}
                        setSelectedFont={setSelectedFont}
                        selectedFontWeight={selectedFontWeight}
                        setSelectedFontWeight={setSelectedFontWeight}
                        setDependencyChanged={setDependencyChanged}
                    />
                    <Box className="fontSizeOption">
                        <LineHeight
                            setLineHeight={setLineHeight}
                            lineHeight={lineHeight}
                            setDependencyChanged={setDependencyChanged}
                        />
                        <FontSize
                            setFontSize={setFontSize}
                            fontSize={fontSize}
                            setDependencyChanged={setDependencyChanged}
                        />
                    </Box>
                    <LetterSpacing
                        setLetterSpacing={setLetterSpacing}
                        letterSpacing={letterSpacing}
                        setDependencyChanged={setDependencyChanged}
                    />
                </Box>
            </Box>
            <Box className="fontStylesOption flexBox alignCenter justifyBetween">
                <Box className="fontLabel">Text Styles</Box>
                <Box className="flexBox alignCenter">
                    {/* <Icon
                        className={`styleOption ${boldFont ? "active" : ""}`}
                        name="boldIcon"
                        onClick={() => {
                            setBoldFont(!boldFont);
                            setDependencyChanged(true);
                        }}
                    /> */}
                    <CustomTooltip title="Uppercase">
                        <Box
                            className={`styleOption ${
                                upperCaseText ? "active" : ""
                            }`}
                        >
                            <Icon
                                name="allCapsIcon"
                                onClick={() => {
                                    setUpperCaseText(!upperCaseText);
                                    setDependencyChanged(true);
                                }}
                            />
                        </Box>
                    </CustomTooltip>
                    <CustomTooltip title="Italic">
                        <Box
                            className={`styleOption ${
                                italicFont ? "active" : ""
                            }`}
                        >
                            <Icon
                                name="italicIcon"
                                onClick={() => {
                                    setItalicFont(!italicFont);
                                    setDependencyChanged(true);
                                }}
                            />
                        </Box>
                    </CustomTooltip>
                    <CustomTooltip title="Color">
                        <Box sx={{ ml: 1 }}>
                            <ColorInput
                                value={textColor}
                                setValue={setTextColor}
                                setDependencyChanged={setDependencyChanged}
                            />
                        </Box>
                    </CustomTooltip>
                </Box>
            </Box>
        </div>
    );
}
