// Change Percentage Value To Pixel
const percentToPixel = (totalH, pVal) => {
    const pixVal = (pVal * totalH) / 100;
    return pixVal;
};

// Change Pixel Value To Percentage
const pixelToPercent = (totalH, pVal) => {
    const perVal = (pVal * 100) / totalH;
    return perVal;
};

// Calculate New  Space While Adding New Container
const calcNewSpace = (oldVal) => {
    return oldVal * 0.9;
};

// Calculate Remaining Space While Removing Container
const remainingSpace = (rSpace, cSpace) => {
    return (cSpace / (100 - rSpace)) * 100;
};

// Function to adjust available space when Grid Container OR Column is resized
const adjustSpace = (cSpace, ratio, rSpace) => {
    // Distribute the space change proportionally
    return cSpace - ratio * rSpace;
};

const isNormalNumber = (layout) => {
    return /^\d+$/.test(layout);
};

const isBlobUrl = (url) => {
    return url.startsWith("blob:");
};

// To handle left space of overflow warning message
const handleOverRef = (overRef) => {
    if (overRef && overRef?.current) {
        overRef.current.classList.toggle("adjustOverFlowBlock");
        overRef.current.style.transition = "left 0.5s";
        setTimeout(() => {
            overRef.current.style.transition = "";
        }, 550);
    }
};

const inchesToPixels = (inches, dpi = 96) => {
    return inches * dpi;
};

const calculateExtraHeight = (pageEditorWrapRef) => {
    if (pageEditorWrapRef.current) {
        const element = pageEditorWrapRef.current;
        const styles = getComputedStyle(element);

        const marginTop = parseFloat(styles.marginTop);
        const marginBottom = parseFloat(styles.marginBottom);
        const paddingTop = parseFloat(styles.paddingTop);
        const paddingBottom = parseFloat(styles.paddingBottom);

        const totalHeight =
            marginTop + marginBottom + paddingTop + paddingBottom;
        return totalHeight;
    }
};

export {
    calculateExtraHeight,
    inchesToPixels,
    handleOverRef,
    isBlobUrl,
    adjustSpace,
    remainingSpace,
    calcNewSpace,
    pixelToPercent,
    percentToPixel,
    isNormalNumber
};
