import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

const classes = {
    navbar: `navbar`,
    iconCircle: `iconCircle`,
    title: `title`,
    leftBlock: `leftBlock`,
    flexBox: `flexBox`
};
const Root = styled(Grid)((theme) => ({
    [`&.${classes.navbar}`]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0px 1px 10px 0px #0000000F",
        border: `1px solid ${theme.theme.colors.grey[200]}`,
        padding: "11px 20px",
        position: "relative",
        zIndex: 3,
        backgroundColor: "#fff"
    },
    [`& .${classes.iconCircle}`]: {
        cursor: "pointer",
        width: 32,
        height: 32,
        borderRadius: "10px",
        border: `1px solid ${theme.theme.colors.grey[100]}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "all 0.3s",
        "&:hover": {
            background: theme.theme.colors.orange[700],
            borderColor: theme.theme.colors.orange[700],
            "& path": {
                fill: "white"
            }
        }
    },
    [`& .${classes.title}`]: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.theme.colors.grey[600],
        margin: "0 0 0 16px"
    },
    [`& .${classes.flexBox}`]: {
        display: "flex",
        alignItems: "center",
        "& button + button": {
            marginLeft: 22
        }
    }
}));

export { Root, classes };
