import { createTheme } from "@mui/material/styles";
import createPalette from "@mui/material/styles/createPalette";

const mainTheme = createTheme({
    palette: createPalette({
        primary: {
            main: "#CC5803",
            contrastText: "white"
        },
        secondary: {
            main: "#087E8B",
            contrastText: "white"
        }
    }),
    colors: {
        primary: "#CC5803",
        white: "#ffffff",
        black: "#000000",
        orange: {
            900: "#A34602",
            800: "#B84F03",
            700: "#CC5803",
            500: "#E06E1A",
            200: "#FAD3B8",
            100: "#FDECDF",
            50: "#FDF6F2"
        },
        grey: {
            900: "#222222",
            700: "#727272",
            600: "#5A5A5A",
            500: "#9E9E9E",
            400: "#B4B3B3",
            300: "#B4B3B3",
            200: "#D9D9D9",
            100: "#939393",
            50: "#FAFAFA"
        }
    },
    browser: {
        isSafari:
            navigator.userAgent.indexOf("Safari") > -1 &&
            !(navigator.userAgent.indexOf("Chrome") > -1)
    }
});

export default mainTheme;
